import React, {useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import Header from "../../User/Header";
import { Table, Modal, notification, Switch, Icon } from "antd";
import DrawerComentario from "./Drawer/DrawerComentario";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import {ArrowCircleLeft} from "iconsax-react";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Comentario = () => {
    const [ativo, setAtivo] = useState(true);
    const [comentarios, setComentarios] = useState([]);
    const [comentarioId, setComentarioId] = useState(0);
    const [tipo, setTipo] = useState()
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const globalContext = useContext(GlobalContext);

    const listar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/comentario/comentario/listar2",
            params: {
                ativo
            }
        })
            .then(response => {
                let comentarios = [];
                
                response.data.map(comentario => {
                    comentarios.push({
                        id: comentario.id,
                        titulo: comentario.id,
                        data: comentario.dtCriado.replace("T", " "),
                        estabelecimento: comentario.estabelecimento,
                        opcoes: montarMenu(comentario.id, comentario.tipo),
                        comentario: comentario.texto,
                        validado: (comentario.validado ? ("Validado"):("Não validado")),
                        tipo: comentario.tipo
                    });
                });

                setComentarios(comentarios);
                setLoading(false);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar comentários");
                setLoading(false);
            });
    };

    const montarMenu = (id, tipo) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            ativar(id,tipo);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        ativar(id, ativo,tipo);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        excluir(id,tipo);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    const excluir = (id,tipo) => {
        confirm({
            title: "Pretende excluir esta notícia?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "patch",
                    url: `/api/comentario/${ tipo === 'Resposta' ? ('resposta_comentario'):('comentario')}/toggle-excluido`,
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Notícia excluida!");
                        listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a notícia!");
                    });
            }
        });
    };

    const ativar = (id, tipo) => {
        confirm({
            title: ativo ? "Pretende inativar esta foto?" : "Pretende ativar esta foto?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "PATCH",
                    url:  `api/comentario/${ tipo === 'Resposta' ? ('resposta_comentario'):('comentario')}/toggle-ativo`,
                    params: {
                        id,
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Foto inativada!" : "Foto ativada!");
                        listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a foto!" : "Não foi possível ativar a foto!"
                        );
                    });
            }
        });
    };

    const onRowClick = (id, tipo) => {
        setVisible(true);
        setComentarioId(id);
        setTipo(tipo)
    };

    useEffect(() => {
        listar(true);
    }, [ativo]);

    const columns = [
       
        {
            title: "Data/Hora",
            dataIndex: "data"
        },
        {
            title: "Estabelecimento",
            dataIndex: "estabelecimento"
        },
        {
            title: "Comentário",
            dataIndex: "comentario"
        },
        {
            title: "Validado",
            dataIndex: "validado"
        },
        {
            title: "Tipo",
            dataIndex: "tipo"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const columnsMobile = [
        {
            title: "Título",
            dataIndex: "titulo"
        },
        {
            title: "Estabelecimento",
            dataIndex: "estabelecimento"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    return (
        <>
            <Header titulo="Comentarios" />
            <div className="administracao-empresa">
                <div className="container container-body">
                    <div style={{ minHeight: 600, background: "white" }}>
                        <div className="bloco-minha-conta">
                            <div className="bloco-gestor-paginas">
                                <div className="noticias container-body">
                                    <div className="controlos" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <button
                                            className="botao-principal botao-principal-redondo"
                                            title="Adicionar"
                                            style={{ marginTop: 0 }}
                                            onClick={() => {
                                                setVisible(true);
                                                setComentarioId(0);
                                            }}
                                            disabled={!ativo}
                                        >
                                            +
                                        </button>
                                        <div className="filtro">
                                            <Switch
                                                checkedChildren="Ativos"
                                                unCheckedChildren="Inativos"
                                                defaultChecked
                                                onChange={ativo => setAtivo(ativo)}
                                            />
                                            
                                            <Link className='botao-voltar2'
                                                  to='/utilizador'
                                                  onClick={()=>{
                                                      globalContext.atualizarState({
                                                          mostrarOptPaginasPublicas: true
                                                      })
                                                  }}
                                            >
                                                <ArrowCircleLeft size={34}/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="bloco-tabela">
                                        <Table
                                            id="tabela_categorias"
                                            className="tabela-paginas-desktop"
                                            loading={{
                                                spinning: loading,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columns}
                                            dataSource={comentarios}
                                            pagination={false}
                                            rowClassName="clickeble-row"
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => onRowClick(record.id,record.tipo)
                                                };
                                            }}
                                            locale={{ emptyText: "Não existem dados!" }}
                                        />
                                        <Table
                                            id="tabela_categorias"
                                            className="tabela-paginas-mobile"
                                            loading={{
                                                spinning: loading,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columnsMobile}
                                            dataSource={comentarios}
                                            pagination={false}
                                            rowClassName="clickeble-row"
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => onRowClick(record.id,record.tipo)
                                                };
                                            }}
                                            locale={{ emptyText: "Não existem dados!" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DrawerComentario
                ativo={ativo}
                id={comentarioId}
                tipo = {tipo}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => {
                    setVisible(false);
                    listar();
                }}
            />
        </>
    );
};

export default Comentario;
