import React, { Component } from "react";
import axios from "axios";
import { Modal, Select, DatePicker } from "antd";
import moment from "moment";

const { Option } = Select;

class ModalHorarioRelatorioAPC extends Component {
    state = {
        selectedOption3: undefined,
        placeholder: "Selecionar Opção",
        dataEmissao: moment(),
        disciplina: this.props.disciplina,
        listaDisciplinas: [],
        option3Options: []
    };

    componentDidMount() {
        this.getDisciplinas();
    }

    getDisciplinas = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-disciplinas",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    option3Options: response.data,
                    listaDisciplinas: response.data
                });
            })
            .catch(() => {});
    };

    handleChangeDataEmissao = date => {
        this.setState({ dataEmissao: date });
    };

    handleOk = () => {
        const { selectedOption1, selectedOption2, selectedOption3, startDate, endDate, months, calendars } = this.state;
        if (this.state.listaDisciplinas.length > 1) this.setState({ selectedOption3: this.state.disciplina });

        this.props.onSelect(this.state.listaDisciplinas.length > 1 ? selectedOption3 : this.state.disciplina, this.state.dataEmissao);
        this.props.onClose();
        this.setState({
            selectedOption3: undefined
        });
    };

    handleOption3Change = value => {
        const disciplina = this.state.listaDisciplinas.find(x => x.id === value)?.nome;
        if (disciplina !== "Todos") localStorage.setItem("disciplina_nome", `${localStorage.getItem("header_nome")} ${disciplina}`);
        else localStorage.removeItem("disciplina_nome");

        this.setState({
            selectedOption3: value
        });
    };

    render() {
        return (
            <Modal
                title="Selecione a opção"
                visible={this.props.visible}
                onOk={this.handleOk}
                okText={"Visualizar"}
                onCancel={this.props.onClose}
                okButtonProps={{
                    style: {
                        backgroundColor: "rgb(70, 176, 255)",
                        borderColor: "rgb(27, 185, 255)"
                    }
                }}
            >
                <div className="my-modal-wrapper">
                    <div className="select-wrapper">
                        {this.state.listaDisciplinas.length > 1 ? (
                            <>
                                <div className="select-wrapper">
                                    <Select
                                        value={this.state.selectedOption3}
                                        placeholder="Selecionar Opção"
                                        onChange={this.handleOption3Change}
                                        className="custom-select ant-select-selection--single"
                                    >
                                        {this.state.option3Options.map(disciplina => (
                                            <Option key={disciplina.id} value={disciplina.id}>
                                                {disciplina.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="select-wrapper">
                                    <DatePicker
                                        name="dataEmissao"
                                        format="DD-MM-YYYY"
                                        placeholder="Selecionar Data de Emissão"
                                        suffixIcon={false}
                                        clearIcon={false}
                                        //disabled={this.state.selectedOption1 !== "Completo" && !this.state.selectedOption2}
                                        onChange={this.handleChangeDataEmissao}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="select-wrapper">
                                    <DatePicker
                                        name="dataEmissao"
                                        format="DD-MM-YYYY"
                                        placeholder="Selecionar Data de Emissão"
                                        suffixIcon={false}
                                        clearIcon={false}
                                        //disabled={this.state.selectedOption1 !== "Completo" && !this.state.selectedOption2}
                                        onChange={this.handleChangeDataEmissao}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ModalHorarioRelatorioAPC;
