import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link, withRouter, useHistory, useLocation } from "react-router-dom";
import { Icon, Table, Form, Input, notification, Modal, DatePicker, Dropdown, Menu, Select, Button } from "antd";
import axios from "axios";
import moment from "moment";

import ModalFicha from "../../Modal/ModalFicha";
import ModalGerarDocumento from "../../../../Componentes/Modals/GerarDocumento";
import ModalLoading from "../../../../Componentes/Modals/ModalLoading";
import DrawerConceito from "../../Drawer/DrawerConceito";
import ModalViewer from "../../../../Componentes/Modals/ModalViewer";
import DrawerLogs from "../../Drawer/DrawerLogs";
import DrawerDataLimiteNota from "../../../../GerirDetalheTurma/Aproveitamento/Drawer/DrawerDataLimite";
import DrawerDataLimiteAvaliacao from "../../../../GerirDetalheTurma/Aproveitamento/Drawer/DrawerDataLimiteAvaliacao";

import { validarExistemNotas, validarExistemObs } from "../../../../Utilities/Diario/Aproveitamento/validacoes";

import { GlobalContext } from "../../../../GlobalState";
import {useMediaQuery} from "react-responsive";
import {FaCalendarDay} from "react-icons/fa";

const { Option } = Select;
const { TextArea } = Input;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
        classificacoes: []
    };

    UNSAFE_componentWillMount() {
        if (this.props.dataIndex === "nota")
            this.info();
    }

    toggleEdit = () => {
        const { record } = this.props;

        if (record.permissao || record.permissaoAdm) {
            const editing = !this.state.editing;
            this.setState({ editing }, () => {
                if (editing) {
                    this.input.focus();
                }
            });
        }
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    saveSelect = e => {
        const { record, handleSave } = this.props;
        const cor = this.state.classificacoes.find(x => x.nome === e)?.cor;

        handleSave({ ...record, nota: e, cor });
        this.toggleEdit();
    };

    savePicker = e => {
        const { record, handleSave } = this.props;

        handleSave({ ...record, data: moment(e).format("DD/MM/YYYY") });
        this.toggleEdit();
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;

        const { editing } = this.state;

        if (!editing)
            return (<div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={this.toggleEdit}
            >
                {children}
            </div>)

        return (<Form.Item style={{ margin: 0 }}>
            {form.getFieldDecorator(dataIndex, {
                initialValue: dataIndex === "data" ? record[dataIndex] ? moment(record[dataIndex], "DD/MM/YYYY") : moment() : record[dataIndex],
            })(
                dataIndex === "data" ?
                    <DatePicker
                        placeholder="Selecionar"
                        format="DD/MM/YYYY"
                        ref={node => (this.input = node)}
                        open
                        onOpenChange={(status) => !status && this.savePicker()}
                        onChange={this.savePicker}
                    />
                    :
                    dataIndex === "nota" ?
                        <Select placeholder="Selecionar" ref={node => (this.input = node)} onChange={this.saveSelect} onBlur={this.saveSelect} style={{ width: 100 }}>
                            {this.state.classificacoes.map((classificacao, index) => (
                                <Option key={index} value={classificacao.nome}>{classificacao.nome}</Option>
                            ))}
                        </Select>
                        :
                        <TextArea ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} rows={4} style={{ height: 100 }} />
            )}
        </Form.Item>);
    };

    info = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/conceitos-classificacoes",
            params: {
                estruturaId: JSON.parse(localStorage.getItem("aproveitamento")).estrutura
            }
        })
            .then(response => {
                this.setState({
                    classificacoes: response.data
                })
            })
            .catch(error => {
            });
    }

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        );
    }
}

const Detalhe = ({ match }) => {
    const context = useContext(GlobalContext);
    const history = useHistory();
    const location = useLocation();

    const entidadeId = localStorage.getItem('entidade_id');

    const [permissao, setPermissao] = useState([]);
    const [estado, setEstado] = useState("");
    const [texto, setTexto] = useState("");
    const [campoCalculado, setCampoCalculado] = useState(false);
    const [fase, setFase] = useState("");
    const [grupo, setGrupo] = useState("");
    const [avaliacao, setAvaliacao] = useState("");
    const [header, setHeader] = useState({
        epoca: "",
        grupo: "",
        pauta: ""
    });
    const [avaliacoes, setAvalicoes] = useState([]);
    const [conceitos, setConceitos] = useState([]);
    const [selectedConceito, setSelectedConceito] = useState("");
    const [replicarConteudo, setReplicarConteudo] = useState("");
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");
    const [loadingText, setLoadingText] = useState("");
    const [utilizadorId, setUtilizadorId] = useState(0);
    const [notaId, setNotaId] = useState(0);
    const [visible, setVisible] = useState(false);
    const [pautas, setPautas] = useState([]);

    const [idNota, setIdNota] = useState(0);
    const [idAvaliacao, setIdAvaliacao] = useState(0);
    //VIEWER
    const [modalVisible, setModalVisible] = useState(false);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [previousLocation, setPreviousLocation] = useState("");
    //DRAWER LOGS
    const [visibleDrawerLogs, setVisibleDrawerLogs] = useState(false);
    const [visibleDrawerAlterarDataNota, setVisibleDrawerAlterarDataNota] = useState(false)
    const [visibleDrawerAlterarDataAvaliacao, setVisibleDrawerAlterarDataAvaliacao] = useState(false)

    const acesso = context.acessos?.find(x => x.func === 21);
    const acessoApagarRegistos = context.acessos?.find(x => x.func === 25);

    const base64DecodeToLong = (code) => {

        var decodedString = atob(code);

        var decodedNumber = parseInt(decodedString, 10);

        return Number(decodedNumber);
    }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const handleSave = row => {
        const newData = [...avaliacoes];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];

        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        setAvalicoes(newData);

        if (estado !== "")
            setEstado("")
    };

    const handleReplicarConteudo = e => {
        const value = e.target.value;
        setReplicarConteudo(value)
    };

    const handleReplicar = () => {
        let mappedAvaliacoes = avaliacoes.map(registo => {
            return {
                ...registo,
                nota: selectedConceito.split(".")[0],
                cor: selectedConceito.split(".")[1],
                guardadaBD: false
            }
        });

        setAvalicoes(mappedAvaliacoes);
    };

    const handleReplicarConteudoAvaliacao = () => {
        let mappedAvaliacoes = avaliacoes.map(registo => {
            return {
                ...registo,
                observacoes: replicarConteudo,
                guardadaBD: false,
            }
        });

        setAvalicoes(mappedAvaliacoes);
    };

    const guardar = () => {
        if (validarExistemNotas(avaliacoes) || validarExistemObs(avaliacoes) && entidadeId == 3) {
            setSelectedConceito("");

            const temp = avaliacoes.map(avalicao => {
                return {
                    id: avalicao.key,
                    numero: avalicao.numero,
                    nome: avalicao.nome,
                    nota: avalicao.nota,
                    observacoes: avalicao.observacoes
                };
            })

            let item = new FormData()
            item.append("turmaId", localStorage.getItem("codigo_curso"))
            item.append("code", match.params.code)
            item.append("pauta", JSON.stringify(temp))

            axios({
                method: "put",
                url: "/api/turma/guardar-pauta-conceitos",
                data: item
            })
                .then(response => {
                    openNotificationWithIcon("success", "Sucesso", "Resultados guardados.");
                    info();
                    listar();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar os resultados.");
                });
        }
        else {
            if (entidadeId == 3)
                openNotificationWithIcon("error", "Erro", "Não foram adicionadas notas ou observações!");
            else
                openNotificationWithIcon("error", "Erro", "Não foram adicionadas notas!");
        }
    }

    const carregarCodigo = () => {
        axios({
            method: "get",
            url: "/api/turma/carregar-codigo",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                history.push(`${history.location.pathname.replace(`/${match.params.code}`, `/${response.data}`)}`)
            })
            .catch(error => {
            });
    }

    const info = () => {
        axios({
            method: "get",
            url: "/api/turma/info-pauta",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                if (response.data) {
                    if (response.data !== "CAMPO_CALCULADO") {
                        setEstado(response.data.estado);
                        setTexto(response.data.texto);
                        setCampoCalculado(response.data.campoCalculado);
                    }
                    else
                        setCampoCalculado(true);
                }
            })
            .catch(error => {
            });
    }

    const infoProf = () => {
        axios({
            method: "get",
            url: "/api/turma/info-permissao",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setPermissao(response.data)
            })
            .catch(error => {

            });
    }

    const listar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/turma/listar-avaliacao-conceitos",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                var registos = response.data.map(registo => {
                    const optionVisivel = registo.logs;

                    return {
                        key: registo.id,
                        notaId: registo.notaId,
                        numero: registo.numero,
                        nome: registo.nome,
                        data: registo.data ? moment(registo.data).format("DD/MM/YYYY") : null,
                        nota: registo.nota,
                        observacoes: registo.observacoes,
                        cor: registo.cor,
                        permissao: registo.permissao,
                        permissaoAdm: registo.permissaoAdm,
                        logs: registo.logs,
                        guardadaBD: true,
                        opcoes: optionVisivel && (
                            <Dropdown overlay={montarMenu(registo.notaId, registo.logs)} placement="bottomLeft">
                                <Link to="#" className="botao-icon-configs">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>
                        )
                    }
                })
                setAvalicoes(registos);
                setLoading(false);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const carregarListaPautas = () => {
        axios({
            method: "get",
            url: "/api/turma/carregar-lista-pautas",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                setPautas(response.data);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    }

    const trocarPauta = (tipo) => {
        const index = pautas.findIndex(x => x.code === match.params.code);
        let avaliacao = null;
        if (tipo === "ANTERIOR")
            avaliacao = pautas[index - 1];
        else
            avaliacao = pautas[index + 1];

        localStorage.setItem(avaliacao.code, JSON.stringify({ epoca: avaliacao.epoca, grupo: avaliacao.grupo, pauta: avaliacao.pauta }))
        history.push(`/gerir-multisseriado/${match.params.turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/aproveitamento-conceitos-pautas/${avaliacao.code}`);
    }

    const montarMenu = (notaId, logs) => {
        return (
            <Menu>
                {logs && (
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                setNotaId(notaId);
                                setVisibleDrawerLogs(true);
                            }}
                        >
                            Logs
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const exportarPdf = () => {
        setVisibleLoading(true);
        const info = JSON.parse(localStorage.getItem(match.params.code));

        axios({
            method: "get",
            url: "/api/pdf/notas-conceitos",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                code: match.params.code,
                grupoId: info.grupoId
            }
        })
            .then(response => {
                setDocs([{ uri: response.data }]);
                setModalVisible(true);
                setVisibleLoading(false);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleLoading(false);
            });
    }
    
    const exportarExcel = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/excel/exportar-conceitos",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                estruturaId: JSON.parse(localStorage.getItem("aproveitamento")).estrutura,
                code: match.params.code,
                fase,
                grupo: "teste"
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }

    const classificacoes = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/conceitos-classificacoes",
            params: {
                estruturaId: JSON.parse(localStorage.getItem("aproveitamento")).estrutura
            }
        })
            .then(response => {
                setConceitos(response.data);
            })
            .catch(error => { });
    };

    const importarExcel = (ficheiro) => {
        setVisibleLoading(true);
        setLoadingText("A importar registos...");

        let item = new FormData()
        item.append("turmaId", localStorage.getItem("codigo_curso"));
        item.append("code", match.params.code);
        item.append("ficheiro", ficheiro);

        axios({
            method: "put",
            url: "/api/excel/importar",
            data: item
        })
            .then(() => {
                setVisibleLoading(false);
                listar();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                setVisibleLoading(false);
            });
    }

    const validarFormato = formato => {
        var formatos = ["xlsx"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const uploadFoto = event => {
        if (validarFormato(event.target.files[0].name.split(".").pop().toLowerCase())) {
            importarExcel(event.target.files[0])
            document.getElementById("upload-doc").value = "";
        } else {
            document.getElementById("upload-doc").value = "";
            openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
        }
    };

    const apagarNota = notaId => {
        confirm({
            title: "Pretende apagar a nota?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aproveitamento/apagar-nota",
                    params: {
                        notaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Nota apagada.");

                        const registo = avaliacoes.find(x => x.notaId === notaId)
                        const updatedRecord = { ...registo, nota: null };
                        const updatedAvaliacoes = avaliacoes.map(registro => {
                            if (registro.key === updatedRecord.key) {
                                return updatedRecord;
                            }
                            return registro;
                        });
                        setAvalicoes(updatedAvaliacoes);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível apagar a nota.");
                    });
            }
        });
    };

    const apagarObservacao = notaId => {
        confirm({
            title: `Pretende apagar ${entidadeId == 33 ? "o conteúdo avaliativo" : "a observação"}?`,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aproveitamento/apagar-observacoes",
                    params: {
                        notaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", `${entidadeId == 33 ? "Conteúdo avaliativo apagado" : "Observação apagada"}.`);

                        const registo = avaliacoes.find(x => x.notaId === notaId)
                        const updatedRecord = { ...registo, observacoes: null };
                        const updatedAvaliacoes = avaliacoes.map(registro => {
                            if (registro.key === updatedRecord.key) {
                                return updatedRecord;
                            }
                            return registro;
                        });
                        setAvalicoes(updatedAvaliacoes);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", `Não foi possível apagar ${entidadeId == 33 ? "o conteúdo avaliativo" : "a observação"}.`);
                    });
            }
        });
    };

    const apagarCampoNaoGuardado = (notaId,campo, title) => {
        confirm({
            title: [title] ,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                const registo = avaliacoes.find(x => x.key === notaId)
                const updatedRecord = { ...registo, [campo]: null };
                const updatedAvaliacoes = avaliacoes.map(registro => {
                    if (registro.key === updatedRecord.key) {
                        return updatedRecord;
                    }
                    return registro;
                });

                setAvalicoes(updatedAvaliacoes);
            }
        });
    };

    useEffect(() => {
        if (location.pathname !== previousLocation.pathname) {
            carregarCodigo();
            info();
            infoProf();
            listar();
            classificacoes();

            if (!pautas.length)
                carregarListaPautas();

            if (localStorage.getItem(match.params.code)) {
                const info = JSON.parse(localStorage.getItem(match.params.code));
                setHeader(info);
            }

        }

        setPreviousLocation(location);
    }, [context.acessos, match.params.code, location.pathname])

    const components = {
        body: {
            row: EditableFormRow,
            cell: EditableCell,
        },
    };

    const editavel = acesso?.update;//(estado === "" || estado === "GUARDADO" || estado === "SUBMETIDO") && !campoCalculado && acesso?.update;

    const columns2 = [
        ...(isTabletOrMobile
            ? [
                {
                    title: "Nome e Nº",
                    dataIndex: "nome",
                    render: (text, record) => (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span>{record.nome}</span>
                            <span style={{ fontSize: "12px", color: "#888" }}>Nº: {record.numero}</span>
                        </div>
                    ),
                },
            ]
            : [
                {
                    title: "Nome",
                    dataIndex: "nome",
                },
                {
                    title: "Nº",
                    dataIndex: "numero",
                },
            ]),
        {
            title: "Nota",
            dataIndex: "nota",
            editable: editavel,
            render: (text, record) => (
                <span className="grupo-inserir-nota">
                    {isTabletOrMobile && (<span style={{fontWeight:"700",  width:"100px"}}>Notas</span>)}
                    
                    {text ? (
                        <span className={`tags-aproveitamento ${record.cor}`}>{text}</span>
                    ) : (
                            <span className={`campo-inserir-nota ${!(record.permissao || record.permissaoAdm) ? "campo-inserir-nota-bloqueado" : ""}`}>
                            <strong></strong>
                        </span>
                    )}
                    {acessoApagarRegistos && record.nota && editavel && (record.permissao || record.permissaoAdm) && (
                        <Link
                            to="#"
                            className="botao-icon-inativar"
                            onClick={event => {
                                event.stopPropagation();
                                if (!record.guardadaBD) {
                                    apagarCampoNaoGuardado(record.key, 'nota', "Pretende apagar a nota?")
                                }
                                else {
                                    apagarNota(record.notaId);
                                }
                            }}
                        >
                            <Icon type="close" />
                        </Link>
                    )}
                </span>
            )
        },
        {
            title: entidadeId == 33 ? "Conteúdo avaliativo" : "Observações",
            dataIndex: "observacoes",
            editable: editavel,
            render: (text, record) => (
                <span className="grupo-inserir-nota">
                    {isTabletOrMobile && (<span style={{fontWeight:"700",  width:"100px"}}>Observações</span>)}
                    <span className={`campo-inserir-nota ${!(record.permissao || record.permissaoAdm) ? "campo-inserir-nota-bloqueado" : ""}`}>
                        <strong>{text ? (text.length > 20 ? `${text.substring(0, 20)}...` : text) : ""}</strong>
                     </span>

                    {acessoApagarRegistos && record.observacoes && editavel && (record.permissao || record.permissaoAdm) && (
                        <Link
                            to="#"
                            className="botao-icon-inativar"
                            onClick={event => {
                                event.stopPropagation();
                                if (!record.guardadaBD) {
                                    apagarCampoNaoGuardado(record.key, 'observacoes', `Pretende apagar ${entidadeId == 33 ? "o conteúdo avaliativo" : "a observação"}?`)
                                } else {
                                    apagarObservacao(record.notaId);
                                }
                            }}
                        >
                            <Icon type="close"/>
                        </Link>
                    )}
                </span>

            )
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const columns = columns2.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <div className="container container-body">
            <div className="turma-resultados">
                <div className="controlos controlos-fundo" style={{ justifyContent: "flex-end" }}>
                    <div className="bloco-direita">
                        <Link className="botao-icon-detalhe botao-maior" to="#" title="Visualizar"
                              onClick={exportarPdf}>
                            <Icon type="eye"/>
                        </Link>
                        {(!campoCalculado && acesso?.update) && (
                            <Link
                                className={`botao-icon-print botao-maior ${estado === "PUBLICADO" || estado === "VALIDADO" ? "botao-disabled" : ""}`}
                                disabled={estado === "VALIDADO"} to="#" title="Importar"
                                onClick={() => document.getElementById("upload-doc").click()}>
                                <Icon type="upload"/>
                            </Link>)}
                        <Link className="botao-icon-download botao-maior" to="#" title="Exportar"
                              onClick={exportarExcel}>
                            <Icon type="download"/>
                        </Link>
                        {/*{acessoAlterarData && (*/}
                        {/*    <button className="botao-icon-calendario" onClick={() => {*/}
                        {/*        setVisibleDrawerAlterarDataAvaliacao(true);*/}
                        {/*    }}>*/}
                        {/*        <FaCalendarDay style={{ color: "white" }} />*/}
                        {/*    </button>)}*/}
                        <Link
                            to={`/gerir-multisseriado/${match.params.turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/aproveitamento`}
                            className="botao-secundario botao-secundario-redondo"
                            style={{marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center"}}
                        >
                            <Icon type="arrow-left"/>
                        </Link>
                    </div>
                </div>
                <div className="bloco">
                    <div className="info" style={{display: "block"}}>
                        <div className="info-header-conceitos">
                            <div style={{fontSize: 20}}>{header?.epoca} - {header?.grupo}</div>
                            <div className="titulo-item">
                                {(pautas.findIndex(x => x.code === match.params.code) > 0 || !pautas.length) &&
                                    <Link className="botao-icon-clip" to="#" title="Anterior" onClick={() => trocarPauta("ANTERIOR")}>
                                        <Icon type="left" />
                                    </Link>}
                                <p>{header?.pauta}</p>
                                {(pautas.findIndex(x => x.code === match.params.code) < pautas.length - 1 || !pautas.length) &&
                                    <Link className="botao-icon-clip" to="#" title="Proximo" onClick={() => trocarPauta("PROXIMO")}>
                                        <Icon type="right" />
                                    </Link>}
                            </div>
                            <p className="estado" dangerouslySetInnerHTML={{ __html: texto }}></p>
                        </div>
                    </div>
                    <div>
                        <div style={{display: "flex", alignItems: "center", gap: 10, marginBottom: 10}}>
                            <Select
                                className="notas-replicar-select"
                                style={{width: "140px"}}
                                value={selectedConceito}
                                allowClear={true}
                                onChange={value => setSelectedConceito(value)}
                            >
                                {conceitos.map(conceito => (
                                    <Option key={conceito.nome} value={conceito.nome + "." + conceito.cor}>
                                        {conceito.nome}
                                    </Option>
                                ))}
                            </Select>
                            <Button
                                disabled={selectedConceito === ""}
                                className="botao-principal"
                                style={{fontSize: 12, border: "none", color: "white"}}
                                onClick={() => handleReplicar()}
                            >
                                Replicar Nota
                            </Button>
                        </div>

                        <div style={{display: "flex", alignItems: "center", gap: 10, marginBottom: 10}}>
                            <Input
                                value={replicarConteudo}
                                onChange={e => handleReplicarConteudo(e)}
                                style={{ width: "135px" }}
                            />
                            <Button
                                disabled={replicarConteudo === ""}
                                className="botao-principal"
                                style={{fontSize: 12, border: "none", color: "white"}}
                                onClick={e => handleReplicarConteudoAvaliacao(e)}
                            >
                                {entidadeId === "33" ? "Replicar Conteúdo avaliativo" : "Replicar Observações"}
                            </Button>
                        </div>
                    </div>
                    <Table
                        id="disciplinas"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading"/>
                                </div>
                            )
                        }}
                        rowClassName={() => 'editable-row'}
                        components={components}
                        columns={columns}
                        showHeader={!isTabletOrMobile}
                        dataSource={avaliacoes}
                        pagination={false}
                        locale={{ emptyText: "Não existem dados!" }}
                        className="tabela-disciplinas"
                    />
                    {avaliacoes.some(av => av.permissao || av.permissaoAdm) &&
                        <div className="bloco-controls">
                            <Link className="botao-principal" to="#" onClick={guardar}>
                                Salvar
                            </Link>
                        </div>}
                </div>
            </div>
            <ModalFicha
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
                atualizar={() => { setVisibleModal(false); info(); }}
            />
            <ModalGerarDocumento
                visibleExportar={visibleExport}
                ficheiroExportar={exportFile}
                btnDescarregar={buttonDownload}
                onClose={() => setVisibleExport(false)}
            />
            <ModalLoading
                visible={visibleLoading}
                text={loadingText}
            />
            <input
                type="file"
                id="upload-doc"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={uploadFoto}
                style={{ display: "none" }}
            />
            <DrawerConceito
                notaId={notaId}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => { setVisible(false); listar(); }}
            />
            <DrawerDataLimiteNota
                Id={notaId}
                utilizadorId={utilizadorId}
                avaliacaoId={base64DecodeToLong(match.params.code)}
                visible={visibleDrawerAlterarDataNota}
                onClose={() => setVisibleDrawerAlterarDataNota(false)}
            />
            <DrawerDataLimiteAvaliacao
                Id={base64DecodeToLong(match.params.code)}
                code={match.params.code}
                visible={visibleDrawerAlterarDataAvaliacao}
                onClose={() => setVisibleDrawerAlterarDataAvaliacao(false)}
            />
            <DrawerLogs
                notaId={notaId}
                visible={visibleDrawerLogs}
                onClose={() => setVisibleDrawerLogs(false)}
            />
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </div>
    );
}

export default withRouter(Detalhe);
