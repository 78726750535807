import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon } from "antd";
import { GlobalContext } from "../../../../GlobalState";
import axios from "axios";

const Tabela = ({ match, search, activated, setActivated }) => {
    const globalContext = useContext(GlobalContext);
    const [disciplinas, setDisciplinas] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    });

    const cols = [
        {
            title: "Edição",
            dataIndex: "edicao"
        },
        {
            title: "Turma",
            dataIndex: "turma"
        },
        {
            title: "Coordenadores",
            dataIndex: "coordenadores"
        }
    ];

    const colsSub = [
        {
            title: "Disciplina",
            dataIndex: "disciplina"
        },
        {
            title: "Carga Horária (H)",
            dataIndex: "carga"
        },
        {
            title: "Aulas Criadas",
            dataIndex: "criadas"
        },
        {
            title: "Aulas Dadas",
            dataIndex: "dadas"
        },
        {
            title: "Coordenadores / Professores",
            dataIndex: "coordenadores"
        }
    ];

    const total = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-total-turmas-professor",
            params: {
                code: match.params.code,
                nome: search,
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`escola${match.params.code}`]?.id,
                categoriaId: globalContext[`categoria${match.params.code}`]?.id
            }
        })
            .then(response => {
                setPagination({ ...pagination, total: response.data });
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const carregar = (currentPage) => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-turmas-professor",
            params: {
                code: match.params.code,
                nome: search,
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`escola${match.params.code}`]?.id,
                categoriaId: globalContext[`categoria${match.params.code}`]?.id,
                pageSize: pagination.pageSize,
                page: currentPage,
                gestao: true
            }
        })
            .then(response => {
                var lista = response.data.map(turma => {
                    return {
                        key: turma.id,
                        edicao: turma.edicao,
                        turma: (
                            <span>
                                <span style={{ display: "block", color: "#16817a", fontSize: 12 }}>{turma.estabelecimento}</span>
                                <span>
                                    {turma.codigo} - {turma.nome}
                                </span>
                            </span>
                        ),
                        nome: turma.nome,
                        coordenadores: turma.coordenadores,
                        disciplinas: turma.disciplinas
                    };
                });
                setDisciplinas(lista);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const carregarDisciplinas = disciplinas => {
        var lista = disciplinas.map(disciplina => {
            return {
                key: disciplina.id,
                edicao: disciplina.edicao,
                disciplina: `${disciplina.codigo} - ${disciplina.nome}`,
                nome: disciplina.nome,
                carga: disciplina.aulas,
                criadas: disciplina.criadas,
                dadas: disciplina.dadas,
                coordenadores: disciplina.coordenadores,
                acesso: disciplina.acesso
            };
        });

        return lista;
    };

    const onRowClick = (cursoNome, cursoId) => {
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem(
            "opcao_voltar",
            JSON.stringify({
                link_voltar: `/gestao-utilizadores-detalhe/${match.params.code}`
            })
        );

        const nomeCurso = cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase();
        setRedirectLink(`/gerir-detalhe-turma/${nomeCurso}/visao-geral`);
        setRedirect(true);
    };

    const onSubRowClick = (cursoNome, cursoId) => {
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem(
            "opcao_voltar",
            JSON.stringify({
                link_voltar: `/gestao-utilizadores-detalhe/${match.params.code}`
            })
        );

        const nomeCurso = cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase();
        setRedirectLink(`/gerir-detalhe-turma/${nomeCurso}/visao-geral-disciplina`);
        setRedirect(true);
    };

    const handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = pagination;
        _pagination.current = pagination.current;

        setPagination(_pagination);
    };

    useEffect(() => {
        if (
            (globalContext[`edicao${match.params.code}`] &&
                globalContext[`escola${match.params.code}`] &&
                globalContext[`categoria${match.params.code}`])
        ) {
            total();
            carregar(1);
            setActivated(false);
            setPagination({
                pageSize: 10,
                current: 1,
                total: 0
            })
        }
    }, [
        globalContext[`edicao${match.params.code}`],
        globalContext[`escola${match.params.code}`],
        globalContext[`categoria${match.params.code}`],
        activated
    ]);

    useEffect(() => {
        if (pagination.total > 0 && !loading)
            carregar(pagination.current);
    }, [pagination]);

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <Table
            className="tabela-user-disciplinas"
            columns={cols}
            pagination={pagination}
            dataSource={disciplinas}
            onChange={handleChangeTable}
            rowClassName="clickeble-row"
            onRow={(record, index) => {
                return {
                    onClick: () => onRowClick(record.nome, record.key)
                };
            }}
            expandedRowRender={(record, index) => (
                <Table
                    id="disciplinas"
                    columns={colsSub}
                    dataSource={carregarDisciplinas(record.disciplinas)}
                    pagination={false}
                    locale={{ emptyText: "Não existem dados!" }}
                    rowClassName="clickeble-row"
                    onRow={(record2, index2) => {
                        return {
                            onClick: () => onSubRowClick(record2.nome, record2.key)
                        };
                    }}
                />
            )}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
        />
    );
};

export default withRouter(Tabela);
