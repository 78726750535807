import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon } from "antd";
import { GlobalContext } from "../../../../GlobalState";
import axios from "axios";
import moment from "moment";
import {Weight} from "iconsax-react";

const Tabela = ({ match, search, activated, setActivated, handleGuardarExpirados }) => {
    const globalContext = useContext(GlobalContext);
    const [disciplinas, setDisciplinas] = useState([]);
    const [aExpirar, setAExpirar] = useState([])
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    });
    
    const acesso = globalContext.acessos?.find(x => x.func === 13);

    const cols = [
        {
            title: "Edição",
            dataIndex: "edicao"
        },
        {
            title: "Turma",
            dataIndex: "turma"
        },
        {
            title: "Coordenadores",
            dataIndex: "coordenadores"
        },
        {
            title: "",
            dataIndex: "opcoes",
            className: "td-50"
        }
    ];

    const colsMobile = [
        {
            title: "Turma",
            dataIndex: "turma"
        },
       
        // {
        //     title: "Aulas Dadas",
        //     dataIndex: "aulas"
        // },
        // {
        //     title: "Assiduidade",
        //     dataIndex: "assiduidade"
        // },
        // {
        //     title: "Aproveitamento",
        //     dataIndex: "aproveitamento"
        // },
        // {
        //     title: "Resultado",
        //     dataIndex: "resultado"
        // }
    ];

    const colsSub = [
        {
            title: "Disciplina",
            dataIndex: "disciplina"
        },
        {
            title: "Carga Horária (H)",
            dataIndex: "carga"
        },
        {
            title: "Aulas Criadas",
            dataIndex: "criadas"
        },
        {
            title: "Aulas Dadas",
            dataIndex: "dadas"
        },
        {
            title: "Coordenadores / Professores",
            dataIndex: "coordenadores"
        }
    ];

    useEffect(() => {
        if(aExpirar.length > 0){
            handleGuardarExpirados(aExpirar)
        }
    }, [aExpirar]);
    
    const colsSubMobile = [
        {
            title: "Disciplina",
            dataIndex: "disciplina"
        },
       
        {
            title: "Coordenadores / Professores",
            dataIndex: "coordenadores"
        }
    ];

    const total = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-total-turmas-professor",
            params: {
                nome: search,
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`escola${match.params.code}`]?.id,
                categoriaId: globalContext[`categoria${match.params.code}`]?.id
            }
        })
            .then(response => {
                setPagination({ ...pagination, total: response.data });
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const carregar = (currentPage) => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-turmas-professor",
            params: {
                nome: search,
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`escola${match.params.code}`]?.id,
                categoriaId: globalContext[`categoria${match.params.code}`]?.id,
                pageSize: pagination.pageSize,
                page: currentPage
            }
        })
            .then(response => {
               

                var listar = response.data.map(turma => {
                    return {
                        edicao: turma.edicao,
                        estabelecimento: turma.estabelecimento,
                        nome: turma.nome,
                        turma: (
                            <span>
                                <span style={{ display: "block", fontSize: 12 }}>{turma.estabelecimento}</span>
                                <span>
                                    {turma.codigo} - {turma.nome}
                                </span>
                                {(turma.multisseriado != null) && (
                                    <div style={{ display: "flex", gap: 5 }}>
                                        {turma.multisseriado != null && (
                                            <span className="tag-multisseriado" title="Multisseriado">{turma.multisseriado?.nome}</span>)}
                                    </div>
                                )}
                            </span>
                        ),
                        coordenador: turma.coordenadores,
                        ordem: turma.periodoFinalizado,
                        periodoFinalizado: (() => {
                            if ([0, 1, 2].includes(turma.periodoFinalizado)) {
                                return "Finaliza a: " + moment(turma.diaFinaliza).format("DD/MM/YYYY 23:59");
                            } else {
                                return null;
                            }
                        })()
                    };
                });
                
                setAExpirar(listar.filter(x=> x.periodoFinalizado !== null))
                var lista = response.data.map(turma => {
                    return {
                        key: turma.id,
                        edicao: turma.edicao,
                        turma: (
                            <span>
                                <span style={{ display: "block", fontSize: 12 }}>{turma.estabelecimento}</span>
                                <span style={{fontWeight:'bold'}}>
                                    {turma.codigo} - {turma.nome}
                                </span>
                                {(turma.multisseriado != null) && (
                                    <div style={{ display: "flex", gap: 5 }}>
                                        {turma.multisseriado != null && (
                                            <span className="tag-multisseriado" title="Multisseriado">{turma.multisseriado?.nome}</span>)}
                                    </div>
                                )}
                            </span>
                        ),
                        estabelecimento: turma.estabelecimento,
                        nome: turma.nome,
                        carga: turma.aulas,
                        servico: turma.servico,
                        coordenadores: turma.coordenadores,
                        multisseriado: turma.multisseriado,
                        disciplinas: turma.disciplinas,
                        opcoes: acesso && montarMenu(turma)
                    };
                });
                setDisciplinas(lista);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const carregarDisciplinas = disciplinas => {
        var lista = disciplinas.map(disciplina => {
            return {
                key: disciplina.id,
                edicao: disciplina.edicao,
                disciplina: `${disciplina.codigo} - ${disciplina.nome}`,
                nome: disciplina.nome,
                carga: disciplina.aulas,
                criadas: disciplina.criadas,
                dadas: disciplina.dadas,
                coordenadores: disciplina.coordenadores,
                acesso: disciplina.acesso
            };
        });

        return lista;
    };

    const montarMenu = turma => {
        return (
            <Link
                to={`/${turma.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-turma-inscricoes`}
                className="botao-icon-adicionar"
                title="Matrículas"
                onClick={event => {
                    event.stopPropagation();
                    localStorage.setItem("cod_curso", turma.codigo ? turma.codigo : "");
                    localStorage.setItem("nome_curso", turma.nome);
                    localStorage.setItem("codigo_curso", turma.id);
                    localStorage.setItem("escola_id", turma.organismo);
                    localStorage.setItem("return_link", match.url);
                    localStorage.removeItem("nome_curso_pai");
                }}
            >
                <i className="fas fa-user-friends" />
            </Link>
        );
    };

    const onRowClick = (cursoNome, cursoId, multisseriado,estabelecimento) => {
        // Obter as turmas acessadas anteriormente
        const turmasAnteriores = JSON.parse(localStorage.getItem("ultimas_turmas")) || [];

        const nomeCurso = cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase();

        // Atualizar a lista de turmas mantendo apenas os dois itens mais recentes
        const novasTurmas = [{ cursoNome, cursoId, estabelecimento, nomeCurso }].concat(
            turmasAnteriores.filter(turma => turma.cursoId !== cursoId)
        ).slice(0, 2);

        // Armazenar a lista atualizada no localStorage
        localStorage.setItem("ultimas_turmas", JSON.stringify(novasTurmas));
        
        localStorage.setItem("codigo_curso", cursoId);
        
        localStorage.setItem(
            "opcao_voltar",
            JSON.stringify({
                link_voltar: "/utilizador-turmas"
            })
        );

       
        setRedirectLink(`/${multisseriado ? "gerir-multisseriado" : "gerir-detalhe-turma"}/${nomeCurso}/visao-geral`);
        setRedirect(true);
    };

    const onSubRowClick = (cursoNome, cursoId, multisseriado,estabelecimento) => {
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem(
            "opcao_voltar",
            JSON.stringify({
                link_voltar: "/utilizador-detalhe"
            })
        );

        const nomeCurso = cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase();
        setRedirectLink(`/${multisseriado ? "gerir-multisseriado" : "gerir-detalhe-turma"}/${nomeCurso}/visao-geral-disciplina`);
        setRedirect(true);
    };

    const handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = pagination;
        _pagination.current = pagination.current;

        setPagination(_pagination);
    };

    useEffect(() => {
        if (
            (globalContext[`edicao${match.params.code}`] &&
                globalContext[`escola${match.params.code}`] &&
                globalContext[`categoria${match.params.code}`])
        ) {
            total();
            carregar(1);
            setActivated(false);
            setPagination({
                pageSize: 10,
                current: 1,
                total: 0
            })
        }
    }, [
        globalContext[`edicao${match.params.code}`],
        globalContext[`escola${match.params.code}`],
        globalContext[`categoria${match.params.code}`],
        activated
    ]);

    useEffect(() => {
        if (pagination.total > 0 && !loading)
            carregar(pagination.current);
    }, [pagination]);

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <div className='bloco-tabela-generica'>
            <div className='tabela-desktop'>
                <Table
                    className="tabela-user-disciplinas"
                    columns={cols}
                    pagination={pagination}
                    dataSource={disciplinas}
                    onChange={handleChangeTable}
                    rowClassName="clickeble-row"
                    onRow={(record, index) => {
                        return {
                            onClick: () => onRowClick(record.nome, record.key, record.multisseriado, record.estabelecimento)
                        };
                    }}
                    expandedRowRender={(record, index) => (
                        <Table
                            id="disciplinas"
                            columns={colsSub}
                            dataSource={carregarDisciplinas(record.disciplinas)}
                            pagination={false}
                            locale={{emptyText: "Não existem dados!"}}
                            rowClassName={(record2, index2) => (record2.acesso ? "clickeble-row" : "disabled-row")}
                            onRow={(record2, index2) => {
                                return {
                                    onClick: record2.acesso ? () => onSubRowClick(record2.nome, record2.key, record.multisseriado) : () => {
                                    }
                                };
                            }}
                        />
                    )}
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading"/>
                            </div>
                        )
                    }}
                />

            </div>
            <div className='tabela-mobile'>
                <Table
                    className="tabela-user-disciplinas"
                    columns={colsMobile}
                    pagination={false}
                    dataSource={disciplinas}
                    rowClassName="clickeble-row"
                    onRow={(record, index) => {
                        return {
                            onClick: () => onRowClick(record.nome, record.key)
                        };
                    }}
                    expandedRowRender={(record, index) => (
                        <Table
                            id="disciplinas"
                            columns={colsSubMobile}
                            dataSource={carregarDisciplinas(record.disciplinas)}
                            pagination={false}
                            locale={{emptyText: "Não existem dados!"}}
                            rowClassName={(record2, index2) => (record2.acesso ? "clickeble-row" : "disabled-row")}
                            onRow={(record2, index2) => {
                                return {
                                    onClick: record2.acesso ? () => onSubRowClick(record2.nome, record2.key, record.multisseriado) : () => {
                                    }
                                };
                            }}
                        />
                    )}
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading"/>
                            </div>
                        )
                    }}

                />
            </div>

        </div>

    );
};

export default withRouter(Tabela);
