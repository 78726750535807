import React, { useState, useEffect } from "react";
import { Redirect, withRouter } from 'react-router-dom';
import { Table } from 'antd';
import axios from "axios";
import TabelaDetalhe from "../../Assiduidade/TabelaDetalhe/TabelaDetalhe";

const Tabela = ({ match }) => {
    const [estados, setEstados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const cols = [
        {
            title: "Data",
            dataIndex: "data"
        },
        {
            title: "Turma",
            dataIndex: "turma"
        },
        {
            title: "Estado",
            dataIndex: "estado"
        },
        {
            title: "Motivo",
            dataIndex: "motivo"
        }
    ];

    const colsMobile = [
        // {
        //     title: "Data",
        //     dataIndex: "data"
        // },
        {
            title: "Turma",
            dataIndex: "turma",
            render: (text,record)=>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <span> {record.data} </span>
                    <span> {record.turma} </span>
                </div>
        }
    ];

    const carregarEstados = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-estados-utilizador",
            params: {
                code: match.params.code
            }
        })
            .then(response => {
                setEstados(response.data)
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    useEffect(() => {
        carregarEstados();
    }, [])

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <div className='desktop-block'>
                <Table
                    className="tabela-documentos tabela-aproveitamento"
                    columns={cols}
                    pagination={false}
                    dataSource={estados}
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading"/>
                            </div>
                        )
                    }}
                />
            </div>

            <div className='mobile'>
                <Table
                    className="tabela-documentos tabela-aproveitamento"
                    columns={colsMobile}
                    pagination={false}
                    dataSource={estados}
                    expandedRowRender={record =>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <span>Estado: {record.estado}</span>
                            <span>Motivo: {record.motivo}</span>
                        </div>
                    }
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading"/>
                            </div>
                        )
                    }}
                />
            </div>
        </>


    );
}

export default withRouter(Tabela);