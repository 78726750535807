import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, notification, Icon, Drawer, Menu, Modal, Dropdown, Select, Table, Switch } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";
import DrawerEdificio from "./DrawerEdificio";
import { GlobalContext } from "../../../GlobalState";
import {ArrowCircleLeft} from "iconsax-react";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Edificio extends Component {
    static contextType = GlobalContext;
    state = {
        //FILTROS
        ativo: true,
        escolas: [],
        escolaSelecionada: undefined,
        //TABELA
        edificios: [],
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER
        visible: false,
        escolaId: 0,
        //LOADING
        loading: false,
        windowWidth: window.innerWidth
    };

    UNSAFE_componentWillMount() {
        this.listar();
        this.listarEscolas();
    }

    handleWindowResize = () => {
        this.setState({
            windowWidth: window.innerWidth
        });
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
    }

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo,
                escolaSelecionada: undefined
            },
            () => {
                this.listar();
                this.listarEscolas();
            }
        );
    };

    handleChangeSelect = escolaSelecionada => {
        this.setState(
            {
                escolaSelecionada
            },
            () => this.listar()
        );
    };

    listar = () => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-organismos/listar-edificios",
                    params: {
                        escolaId: this.state.escolaSelecionada,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        let edificios = [];
                        response.data.map(edificio => {
                            edificios.push({
                                key: edificio.id,
                                id: edificio.id,
                                escola: edificio.escola,
                                edificio: edificio.edificio,
                                nr_salas: edificio.nrSalas,
                                lotacao: edificio.lotacao,
                                opcoes: this.montarMenu(edificio)
                            });
                        });

                        this.setState({
                            edificios,
                            loading: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        this.setState({
                            loading: false
                        });
                    });
            }
        );
    };

    listarEscolas = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos-filtro",
            params: {
                ativo: this.state.ativo,
                pagina: "EDIFICIOS"
            }
        })
            .then(response => {
                this.setState({
                    escolas: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    excluir = edificioId => {
        confirm({
            title: "Pretende remover este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-organismos/excluir-edificio",
                    params: {
                        edificioId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Registo removido!");
                        var edificios = this.state.edificios.filter(x => x.id !== edificioId);
                        this.setState({
                            edificios
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível remover este registo!");
                    });
            }
        });
    };

    ativo = edificioId => {
        confirm({
            title: this.state.ativo ? "Pretende inativar este Edifício?" : "Pretende ativar este Edifício?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-organismos/ativar-edificio",
                    params: {
                        edificioId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Edifício inativado!" : "Edifício ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar o Edifício!" : "Não foi possível ativar o Edifício!"
                        );
                    });
            }
        });
    };

    montarMenu = edificio => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativo(edificio.id);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativo(edificio.id);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluir(edificio.id);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    onRowClick = edificioId => {
        this.setState({
            edificioId,
            visible: true
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            //FILTROS
            ativo,
            escolas,
            escolaSelecionada,
            //TABELA
            edificios,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER
            visible,
            edificioId,
            //LOADING
            loading,
            windowWidth
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const columns = [
            {
                title: "Estabelecimento",
                dataIndex: "escola",
                className: "td-150"
            },
            {
                title: "Edifício",
                dataIndex: "edificio",
                className: "td-150"
            },
            {
                title: "Nr Salas",
                dataIndex: "nr_salas",
                width: 100
            },
            {
                title: "Lotação",
                dataIndex: "lotacao",
                width: 100
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50 td-text-right"
            }
        ];

        const columnsMobile = [
            // {
            //     title: "Estabelecimento",
            //     dataIndex: "escola",
            //     className: "td-150"
            // },
            {
                title: "Edifício",
                dataIndex: "edificio",
                className: "td-150",

                render: (text, record) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{fontWeight:"bold"}}>{record.edificio}</span>
                        <span>Nr Salas: {record.nr_salas}</span>
                        <span>Lotação: {record.lotacao}</span>
                    </div>
                ),
            },
            // {
            //     title: "Nr Salas",
            //     dataIndex: "nr_salas",
            //     width: 100
            // },
            // {
            //     title: "Lotação",
            //     dataIndex: "lotacao",
            //     width: 100
            // },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50 td-text-right"
            }
        ];

        return (
            <div className="bloco-minha-conta">
                <div className="controlos">
                    <div className="controlos-space-between"
                          style={{display: 'flex', justifyContent:'space-between'}}
                    >
                        <div className="filtros-controlos" 
                             // style={{display:"flex", alignItems:"center", gap:'10px'}}
                        >
                            <button
                                className="botao-principal botao-principal-redondo"
                                title="Adicionar"
                                onClick={() => this.setState({ edificioId: 0, visible: true })}
                                disabled={!this.state.ativo}
                            >
                                +
                            </button>
                            {windowWidth >= 668 ? (
                                <Select 
                                        placeholder="Selecionar Estabelecimento" 
                                        value={escolaSelecionada} 
                                        allowClear={true} 
                                        onChange={this.handleChangeSelect}
                                        style={{ width: '200px', paddingLeft:'10px' }} // Altere o valor para o tamanho desejado

                                >
                                    
                                    
                                    {escolas.map(escola => (
                                        <Option key={escola.id} value={escola.id}>
                                            {escola.nome}
                                        </Option>
                                    ))}
                                </Select>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Switch
                                checkedChildren="Ativos"
                                unCheckedChildren="Inativos"
                                defaultChecked
                                checked={ativo}
                                onChange={this.handleChangeSwitch}
                            />
                            <div className='esconder '>
                                <div className='hidden-desktop'>
                                    <div style={{display: "flex", justifyContent: "end"}}>

                                        <Link className='botao-voltar2'
                                              to='/utilizador'
                                              onClick={() => {
                                                  this.context.atualizarState({
                                                      mostrarOptPaginasPublicas: true
                                                  })
                                              }}
                                        >
                                            <ArrowCircleLeft size={34}/>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    {windowWidth < 668 ? (
                        <div className="controlos-space-between" style={{marginTop: 10}}>
                            <div className="filtros-controlos-mobile">
                                <Select
                                    placeholder="Selecionar Estabelecimento"
                                    allowClear={true}
                                    onChange={this.handleChangeSelect}
                                    style={{width: "100%"}}
                                >
                                    {escolas.map(escola => (
                                        <Option key={escola.id} value={escola.id}>
                                            {escola.nome}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="box-ficheiros box-ficheiros-desktop">
                    <Table
                        id="tabela-cursos"
                        className="tabela-cursos-desktop"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        columns={columns}
                        dataSource={edificios}
                        pagination={false}
                        rowClassName="clickeble-row"
                        onRow={(record, index) => {
                            return {
                                onClick: () => this.onRowClick(record.id)
                            };
                        }}
                        locale={{ emptyText: "Não existem dados!" }}
                    />
                </div>
                <div className="box-ficheiros box-ficheiros-mobile">
                    <Table
                        id="tabela-cursos"
                        className="tabela-cursos-mobile"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        columns={columnsMobile}
                        dataSource={edificios}
                        pagination={false}
                        rowClassName="clickeble-row"
                        onRow={(record, index) => {
                            return {
                                onClick: () => this.onRowClick(record.id)
                            };
                        }}
                        locale={{ emptyText: "Não existem dados!" }}
                    />
                </div>
                <DrawerEdificio
                    ativo={this.state.ativo}
                    edificioId={edificioId}
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    atualizar={() => {
                        this.setState({ visible: false });
                        this.listar();
                    }}
                />
            </div>
        );
    }
}

const FormEdificio = Form.create({ name: "form-edificio" })(Edificio);

export default FormEdificio;
