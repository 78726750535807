import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Switch, Table, Icon, Modal, notification } from "antd";
import DrawerPagina from "./DrawerPagina";
import SubGroup from "./SubGroup/SubGroup";
import Artigos from "./Artigos/Artigos";
import axios from "axios";
import {ArrowCircleLeft} from "iconsax-react";
import {GlobalContext} from "../../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Paginas extends Component {
    static contextType = GlobalContext;
    
    state = {
        paginas: [],
        paginasMobile: [],
        ativo: true,
        id: "",
        idSubgrupo: "",
        tituloSubgrupos: "",
        ordem: 1,
        //LOADING
        loading_table: false,
        //DRAWER
        visible: false,
        //SUBGRUPOS
        subgruposVisivel: false,
        //INFORMACOES
        paginaInformacoesVisivel: false
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA CATEGORIA NA TABELA
    montarMenu = (id) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-enviar"
                        title="Subgrupos"
                        onClick={event => { event.stopPropagation(); this.carregarSubgrupos(id) }}
                    >
                        <Icon type="profile" />
                    </Link>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => { event.stopPropagation(); this.ativar(id, this.state.ativo) }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => { event.stopPropagation(); this.ativar(id, this.state.ativo) }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => { event.stopPropagation(); this.excluir(id) }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    adicionar = () => {
        this.setState({
            id: "",
            visible: true
        })
    }

    editar = id => {
        this.setState({
            id,
            visible: true
        })
    }

    excluir = id => {
        confirm({
            title: "Pretende excluir este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/empresa/excluir-faq-grupo",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo excluido!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    ativar = (id, ativo) => {
        confirm({
            title: ativo ? "Pretende avançar com esta operação de inativação?" : "Pretende avançar com esta operação de ativação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/empresa/ativar-faq-grupo",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Operação efetuada com sucesso!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o registo!" : "Não foi possível ativar o registo!"
                        );
                    });
            }
        });
    };

    carregarSubgrupos = (id) => {
        var pagina = this.state.paginas.find(x => x.id === id)

        this.setState({
            id,
            tituloSubgrupos: pagina.titulo,
            subgruposVisivel: true
        })
    }

    voltar = () => {
        this.setState({
            id: "",
            subgruposVisivel: false
        })
    }

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/empresa/listar-faq-grupos",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var paginas = [];
                        var paginasMobile = [];

                        response.data.map((pagina, index) => {
                            paginas.push({
                                key: index,
                                id: pagina.id,
                                titulo: pagina.titulo,
                                ordem: pagina.ordem,
                                opcoes: this.montarMenu(pagina.id)
                            });
                            paginasMobile.push({
                                key: index,
                                id: pagina.id,
                                titulo: <div className="bloco-pagina">
                                    <div className="info-pagina">
                                        <span className="titulo-pagina">{pagina.titulo}</span>
                                        <span className="ordem-pagina"><strong>Ordem:</strong> {pagina.ordem}</span>
                                    </div>
                                </div>,
                                ordem: pagina.ordem,
                                opcoes: this.montarMenu(pagina.id)
                            });
                        });

                        this.setState({
                            paginas,
                            paginasMobile,
                            ordem: (response.data.length + 1),
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    atualizarRegisto = () => {
        this.setState({
            visible: false
        })
        this.listar();
    }

    onRowClick = (id) => {
        this.editar(id)
    }

    render() {
        const {
            paginas,
            paginasMobile,
            id,
            tituloSubgrupos,
            ordem,
            //LOADING
            loading_table,
            //DRAWER
            visible,
            //SUBGRUPOS
            subgruposVisivel,
        } = this.state;

        const columns = [
            {
                title: "Grupo",
                dataIndex: "titulo"
            },
            {
                title: "Ordem",
                dataIndex: "ordem"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const columnsMobile = [
            {
                title: "Grupo",
                dataIndex: "titulo"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (subgruposVisivel)
            return (
                <SubGroup
                    id={id}
                    titulo={tituloSubgrupos}
                    voltar={this.voltar}
                />
            )

        return (
            <div className="bloco-minha-conta">
                <div className="bloco-gestor-paginas">
                    <div className="controlos">
                        <button
                            className="botao-principal botao-principal-redondo"
                            title="Adicionar"
                            onClick={this.adicionar}
                            disabled={!this.state.ativo}
                        >
                            +
                        </button>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Switch
                                checkedChildren="Ativos"
                                unCheckedChildren="Inativos"
                                defaultChecked
                                onChange={this.handleChangeSwitch}
                            />
                            <Link className='botao-voltar2'
                                  to='/utilizador'
                                  onClick={()=>{
                                      this.context.atualizarState({
                                          mostrarOptPaginasPublicas: true
                                      })
                                  }}
                            >
                                <ArrowCircleLeft size={34}/>
                            </Link>
                        </div>
                        
                    </div>
                    <div className="bloco-tabela">
                        <Table
                            id="tabela_categorias"
                            className="tabela-paginas-desktop"
                            loading={{
                                spinning: loading_table,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columns}
                            dataSource={paginas}
                            pagination={false}
                            rowClassName="clickeble-row"
                            onRow={(record, index) => {
                                return {
                                    onClick: () => this.onRowClick(record.id)
                                };
                            }}
                            locale={{ emptyText: "Não existem dados!" }}
                        />
                        <Table
                            id="tabela_categorias"
                            className="tabela-paginas-mobile"
                            loading={{
                                spinning: loading_table,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columnsMobile}
                            dataSource={paginasMobile}
                            pagination={false}
                            rowClassName="clickeble-row"
                            onRow={(record, index) => {
                                return {
                                    onClick: () => this.onRowClick(record.id)
                                };
                            }}
                            locale={{ emptyText: "Não existem dados!" }}
                        />
                    </div>
                </div>
                <DrawerPagina
                    ativo={this.state.ativo}
                    id={id}
                    ordem={ordem}
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    atualizarRegisto={this.atualizarRegisto}
                />
            </div>
        );
    }
}

export default Paginas;
