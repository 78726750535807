import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Form, Select, notification, Modal, Dropdown, Menu, Switch } from "antd";
import Header from "../../../User/Header";
import noimage from "../../../images/noimage.png";
import axios from "axios";
import moment from "moment";

import BotaoProcurar from "../../../Geral/_Aux/BotaoProcurar";
import DrawerImportar from "./DrawerImportar/DrawerImportar";
import DrawerInscricao from "./DrawerInscricao/DrawerInscricao";
import DrawerDetalhe from "../_Aux/DrawerDetalheInscricao";
import ModalGerarDocumento from "../../../Componentes/Modals/GerarDocumento";

import { GlobalContext } from "../../../GlobalState";

import ProfileIcon from "../../../UserDetalhe/Componentes/IconProfile";

const confirm = Modal.confirm;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Inscricoes extends Component {
    static contextType = GlobalContext;
    state = {
        ativo: true,
        //TABELA
        loading_table: true,
        alunos: [],
        ativo: true,
        inscritos: false,
        sorter: {
            order: "ascend",
            field: "nome"
        },
        //INFO PRINCIPAL
        cursoid: 0,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER DETALHE INSCRIÇÃO
        inscricaoId: 0,
        drawer_detalhe_visible: false,
        //DRAWER SEARCH INSCRICAO
        drawer_inscricao_visible: false,
        //DRAWER IMPORTAR
        drawer_importar_visible: false,
        //EXPORT
        visibleExport: false,
        buttonDownload: false,
        exportFile: ""
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    //LISTA TODOS OS ALUNOS DA ENTIDADE
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-curso-inscricoes/listar-inscricoes-cursos-disciplinas",
                    params: {
                        cursoid: localStorage.getItem("codigo_curso"),
                        pesquisa: this.state.pesquisa,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var alunos = [];

                        response.data.map((aluno, index) => {
                            alunos.push({
                                key: aluno.inscricaoId,
                                nome: (
                                    <>
                                        <div className="imagem-curso">
                                            {aluno.foto ? (
                                                <img src={aluno.foto} />
                                            ) : (
                                                aluno.nome_completo !== '' && (
                                                    <ProfileIcon name={aluno.nome_completo} />
                                                )
                                            )}
                                        </div>
                                        <div className="nome-colaborador">
                                            <span className="titulo-curso">{aluno.nome_completo}</span>
                                        </div>
                                    </>
                                ),
                                email: aluno.email,
                                nr_aluno: <div className="info-numero-estado">
                                    <span className="info-numero">{aluno.nr_aluno}</span>
                                    {aluno.pendente ?
                                        <span className="info-estado">Registo Pendente</span>
                                        : null}
                                </div>,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(aluno.id, aluno.inscricaoId, aluno.cursoId, aluno.ativo)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ),
                                cursos: aluno.cursos
                            });
                        });

                        this.setState({
                            alunos,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO/LINHA
    montarMenu = (utilizadorId, inscricaoId, cursoId, ativo) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ inscricaoId, drawer_detalhe_visible: true })}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.anularInscricao(inscricaoId)}>
                        Anular
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.inativarInscricao(inscricaoId, ativo)}>
                        Inativar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={event => { event.stopPropagation(); this.exportApoio(cursoId, utilizadorId, "exportar-ficha-aluno") }}>
                        Fichas Individuais dos Alunos
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={event => { event.stopPropagation(); this.exportApoio(cursoId, utilizadorId, "exportar-transferencia") }}>
                        Transferências
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={event => { event.stopPropagation(); this.exportApoio(cursoId, utilizadorId, "exportar-transferencia-provisoria") }}>
                        Transferências Provisórias
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={event => { event.stopPropagation(); this.exportApoio(cursoId, utilizadorId, "exportar-declaracao-matricula") }}>
                        Declarações de Matrícula
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={event => { event.stopPropagation(); this.exportApoio(cursoId, utilizadorId, "exportar-atestado-escolaridade") }}>
                        Atestados de Escolaridade
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    exportApoio = (cursoId, utilizadorId, tipo) => {
        this.setState({
            visibleExport: true,
            buttonDownload: true
        }, () => {
            axios({
                method: "get",
                url: `/api/concelho-classe/${tipo}`,
                params: {
                    cursoId,
                    utilizadorId
                },
                timeout: 60 * 30 * 1000
            })
                .then(response => {
                    this.setState({
                        buttonDownload: false,
                        exportFile: response.data
                    })
                })
                .catch(error => {
                    if (error.response.status === 400)
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                    else if (error.response?.data != "")
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    else
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");

                    this.setState({
                        visibleExport: false,
                        buttonDownload: false
                    })
                });
        })
    }

    atualizarListagem = () => {
        this.listar();
    };

    anularInscricao = id => {
        confirm({
            title: "Pretende anular esta inscrição?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-curso-inscricoes/remover-inscricao",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        var alunos = this.state.alunos.filter(x => x.key !== id);

                        this.setState({
                            alunos
                        });

                        openNotificationWithIcon("success", "Sucesso", "Inscrição anulada!");
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível anular a inscrição");
                    });
            }
        });
    };

    inativarInscricao = (id, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta inscrição?" : "Pretende ativar esta inscrição?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-curso-inscricoes/ativar-inscricao",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Inscrição inativada!" : "Inscrição ativada!");
                        var alunos = this.state.alunos.filter(x => x.key !== id);

                        this.setState({
                            alunos
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar a inscrição!" : "Não foi possível ativar a inscrição!"
                        );
                    });
            }
        });
    };

    closeDrawer = () => {
        this.setState({
            drawer_inscricao_visible: false,
        })
        this.listar();
    }

    render() {
        const {
            //TABELA
            loading_table,
            alunos,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER DETALHE INSCRIÇÃO
            inscricaoId,
            drawer_detalhe_visible,
            //DRAWER FORM INSCRICAO
            drawer_inscricao_visible,
            //DRAWER IMPORTAR
            drawer_importar_visible,
            //EXPORT
            visibleExport,
            buttonDownload,
            exportFile
        } = this.state;

        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-nome"
            },
            {
                title: "Código",
                dataIndex: "nr_aluno"
            },
            {
                title: "E-mail",
                dataIndex: "email"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-nome"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-opcoes"
            }
        ];

        const expandedRowRender = record => {
            const columns = [
                {
                    title: "Edição",
                    dataIndex: "edicao",
                    key: "edicao",
                },
                {
                    title: "Curso",
                    dataIndex: "curso",
                    key: "curso",
                    className: "td-curso"
                },
                {
                    title: "Categoria",
                    dataIndex: "categoria",
                    key: "categoria",
                },
                {
                    title: "Periodo",
                    dataIndex: "periodo",
                    key: "periodo",
                },
            ];

            const lista = record.cursos.map(curso => {
                return ({
                    key: curso.id,
                    id: curso.id,
                    edicao: (
                        <div>
                            <span>{curso.edicao}</span>
                            {curso.ano || curso.turma ?
                                <span style={{ display: "block" }}>{`${(curso.ano ? curso.ano : "")} ${(curso.turma ? curso.turma : "")}`}</span>
                                : null}
                        </div>
                    ),
                    curso: (
                        <>
                            <div className="imagem-curso">
                                <img src={curso.imagem ? curso.imagem : noimage} />
                            </div>
                            <div className="nome-curso">
                                <span className="titulo-curso" title={curso.nome}>
                                    {curso.codigo} - {curso.nome}
                                </span>
                            </div>
                        </>
                    ),
                    categoria: curso.categoria,
                    periodo: (
                        <div>
                            <span>{
                                curso.dt_fim ?
                                    `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                                    :
                                    moment(curso.dt_inicio).format("DD/MM/YYYY")
                            }</span>
                            {curso.fechado ?
                                <div className="tag-fechado">Fechado ({curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}</div>
                                : ""}
                        </div>
                    )
                })
            })

            return (
                <Table columns={columns} dataSource={lista} pagination={false} locale={{ emptyText: "Não existem dados!" }} />
            );
        };

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Inscrições" />
                <div className="container container-body">
                    <div className="bloco-cursos bloco-cursos-inscricoes">
                        <div className="bloco-tabela">
                            <div className="bloco-flex" style={{ marginBottom: 20 }}>
                                <div>
                                    <h3 className="tabela_titulo">
                                        {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                    </h3>
                                    <span className="escola-nome">
                                        {localStorage.getItem("escola_nome")}
                                    </span>
                                </div>
                                <div className="filtros">
                                    <Switch
                                        checkedChildren="Ativos"
                                        unCheckedChildren="Inativos"
                                        defaultChecked
                                        checked={this.state.ativo}
                                        onChange={this.handleChangeSwitch}
                                    />
                                </div>
                            </div>
                            <div className="filtros filtros-inscricoes">
                                <div className="bloco-esquerda">
                                    <BotaoProcurar
                                        placeholder="Coloque o nome a pesquisar..."
                                        tem_placeholder
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                </div>
                                <div className="bloco-direita" style={{ display: "flex", gap: 20 }}>
                                    <button className="botao-principal" onClick={() => this.setState({ drawer_inscricao_visible: true })}>
                                        Inscrever
                                    </button>
                                    <button
                                        className="botao-secundario"
                                        onClick={() => this.setState({ drawer_importar_visible: true })}
                                    >
                                        Importar
                                    </button>
                                    <Link
                                        to={this.context.escolas_entidade ? "/gestao-cursos-organismos" : "/gestao-cursos"}
                                        className="botao-principal"
                                        onClick={() => { localStorage.removeItem("escola_nome"); localStorage.removeItem("escola_id"); }}
                                    >
                                        Voltar
                                    </Link>
                                </div>
                            </div>
                            <Table
                                id="tabela-inscricoes"
                                className="tabela-inscricoes-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={alunos}
                                expandedRowRender={expandedRowRender}
                                pagination={false}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                            <Table
                                id="tabela-inscricoes"
                                className="tabela-inscricoes-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={alunos}
                                pagination={false}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerDetalhe
                    visible={drawer_detalhe_visible}
                    onClose={() => this.setState({ drawer_detalhe_visible: false })}
                    onCloseUpdate={() => { this.setState({ drawer_detalhe_visible: false }); this.listar(); }}
                    inscricaoId={inscricaoId}
                />
                <DrawerInscricao
                    visible={drawer_inscricao_visible}
                    onClose={this.closeDrawer}
                />
                <DrawerImportar
                    visible={drawer_importar_visible}
                    onClose={() => this.setState({ drawer_importar_visible: false })}
                    onConfirm={() => { this.setState({ drawer_importar_visible: false }); this.listar(); }}
                />
                <ModalGerarDocumento
                    visibleExportar={visibleExport}
                    ficheiroExportar={exportFile}
                    btnDescarregar={buttonDownload}
                    onClose={() => this.setState({ visibleExport: false })}
                />
            </>
        );
    }
}
export default Inscricoes;
