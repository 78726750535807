import React from "react";

import {
    ArrowCircleLeft,
   
} from 'iconsax-react';


export default function NomeNumeroUsuario({ nome, numero, mostraBotao,onClick }) {
    return (
        <div className="dados-user">
            <div>
                <div className="nome">
                    <span>{nome}</span>
                </div>
                <div className="numero">
                    <span>#{numero}</span>
                </div>
            </div>

            {mostraBotao &&
                <div>
                    <button className='botao-voltar2' 
                            onClick={(e) => {
                                if (onClick) {
                                    e.preventDefault();
                                    onClick(e);
                                }
                            }}
                    >
                        <ArrowCircleLeft size='34'/>
                    </button>
                </div>
            }

        </div>
    );
}
