import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Icon,
    Timeline,
    notification,
    Modal
} from "antd";
import axios from "axios";
import moment from "moment";

import DrawerEditarHistorico from "./EditarHistorico";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Historico = ({ inscricaoId, visible, acesso, onClose, atualizar }) => {
    const [listaHistorico, setListaHistorico] = useState([]);
    const [registoId, setRegistoId] = useState();
    const [registoData, setRegistoData] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [dadosAtualizados, setDadosAtualizados] = useState(false);
    const [loading, setLoading] = useState(false);

    const carregarHistorico = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/gestao-turma-matriculas/carregar-historico",
            params: {
                inscricaoId
            }
        })
            .then(response => {
                setListaHistorico(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const abrirDrawerEditar = (id, data) => {
        setRegistoId(id)
        setRegistoData(data)
        setDrawerVisible(true)
    }

    const apagarRegisto = (registoId) => {
        confirm({
            title: "Pretende excluir este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-turma-matriculas/apagar-historico",
                    params: {
                        registoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo excluido!");
                        carregarHistorico();
                        setDadosAtualizados(true)
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o registo");
                    });
            }
        });
    }

    const afterVisibleChange = aberto => {
        if (aberto) {
            carregarHistorico();
            setDadosAtualizados(false);
        }
    }

    return (
        <>
            <Drawer
                className="drawer-add-cursos drawer-logs"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-info">
                    <h1>Histórico de Estados</h1>
                    <div className="bloco-timeline">
                        {loading && (<div className="loading-data-table">
                            <div className="loading" />
                        </div>)}
                        <Timeline>
                            {listaHistorico.map((historico, index) =>
                                acesso?.update ?
                                    (<Timeline.Item
                                        className="timeline-item-clickable"
                                        dot={<Icon type="edit" className="timeline-icon" />}
                                        onClick={() => abrirDrawerEditar(historico.id, historico.data)}
                                    >
                                        {historico.titulo} - {moment(historico.data).format("DD/MM/YYYY")}
                                        {(index === listaHistorico.length - 1 && index > 0) && (
                                            <Link to="#" title="Excluir" className="botao-icon-excluir" onClick={(event) => { event.stopPropagation(); apagarRegisto(historico.id) }}>
                                                <Icon type="delete" />
                                            </Link>
                                        )}
                                    </Timeline.Item>)
                                    :
                                    (<Timeline.Item
                                        className="timeline-item"
                                        dot={<Icon type="edit" className="timeline-icon" />}
                                    >
                                        {historico.titulo} - {moment(historico.data).format("DD/MM/YYYY")}
                                    </Timeline.Item>)
                            )}
                        </Timeline>
                    </div>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={() => dadosAtualizados ? atualizar() : onClose()}>
                        Voltar
                    </button>
                </div>
            </Drawer>
            <DrawerEditarHistorico
                visible={drawerVisible}
                registoId={registoId}
                dataRegisto={registoData}
                onClose={() => setDrawerVisible(false)}
                atualizar={() => {
                    setDrawerVisible(false)
                    setDadosAtualizados(true)
                    carregarHistorico();
                }}
            />
        </>
    );
}

export default Historico;