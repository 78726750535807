import React, {useEffect} from "react";
import { Icon, Modal,Spin, List } from "antd";

const GerarDocumento = ({ ficheiroExportar, visibleExportar, btnDescarregar, onClose,turmasMultisseriado }) => {
    return (
        <Modal
            visible={visibleExportar}
            okText={
                <a href={ficheiroExportar} download onClick={onClose} >
                    Descarregar
                </a>
            }
            onCancel={onClose}
            cancelText="Cancelar"
            okButtonProps={{disabled: btnDescarregar,
                            className: "custom-disabled-btn",
                            }}
            maskClosable={false}
            className="exportar-csv"
        >
            <div className="exportar-csv-bloco">
                <List
                    dataSource={turmasMultisseriado}
                    renderItem={turma => (
                        <List.Item>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%"
                            }}>
                                <span>{turma.nome}</span>
                                {turma.concluido ? (
                                    <Icon type="check-circle" style={{color: "#84bd00", fontSize: 24}}/>
                                ) : (
                                    <Icon type="loading" style={{color: "#b8b8b8", fontSize: 24}}/>
                                )}
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        </Modal>
    )
}

export default GerarDocumento;