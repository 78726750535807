import React, {useContext, useEffect, useState} from "react";
import { Dropdown, Menu, Icon } from "antd";
import axios from "axios";
import {GlobalContext} from "../../GlobalState";

//icons
import escola from '../../images/icons/escola.png'

export default function DropdownEstabelecimentos({ onEstabelecimentoChange, estabelecimentos }) {
    // const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState(null);
    const globalContext = useContext(GlobalContext);
    
    // Função para alterar o estabelecimento selecionado
    const onChangeEstabelecimento = (estabelecimento) => {
        localStorage.setItem("escola_selecionada", JSON.stringify(estabelecimento));
        setEstabelecimentoSelecionado(estabelecimento);
        onEstabelecimentoChange(estabelecimento); // Notifica o pai
    };
    

    const menuEstabelecimentos = (
        <Menu className="navbar-opcao-escolas">
            {estabelecimentos.map((estabelecimento) => (
                <Menu.Item
                    key={estabelecimento.id}
                    title={estabelecimento.nome}
                    onClick={() => onChangeEstabelecimento(estabelecimento)}
                >
                    {estabelecimento.abreviatura}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className="dropdown-escolas">
            {estabelecimentos.length > 1 ? (
                <Dropdown overlay={menuEstabelecimentos} trigger={["click"]}>
                    <span
                        className="drop-escola"
                        onClick={(e) => e.preventDefault()}
                    >
                        <div className="icon-escola">
                            <img src={escola}  alt="escola" />
                        </div>
                        <span className="escola">Escola:</span>
                        <span className="estabelecimento">
                            { globalContext.estabelecimento?.abreviatura}
                        </span>
                        <Icon type="down" style={{ color: "#2D8D87" }} />
                    </span>
                </Dropdown>
            ) : (
                <span onClick={(e) => e.preventDefault()} className="drop-escola not-allow">
                    <div className="icon-escola">
                        <img src={escola} alt="escola" />
                    </div>
                    <span className="escola">Escola:</span>
                    <span className="estabelecimento">
                        {globalContext.estabelecimento?.abreviatura}
                    </span>
                    <Icon type="down" style={{ color: "#2D8D87" }} />
                </span>
            )}
        </div>
    );
}
