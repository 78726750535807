import React, { Component } from "react";
import axios from "axios";
import { Modal, Select, Form, DatePicker } from "antd";
import moment from "moment";

const { Option } = Select;

class ModalHorarioRelatorio extends Component {
    state = {
        selectedOption3: undefined,
        dataEmissao: moment(),
        placeholder: "Selecionar Opção",
        disciplina: this.props.disciplina,
        listaDisciplinas: [],
        calendars: [],
        startDate: "",
        endDate: "",
        option3Options: []
    };

    componentDidMount() {
        this.getCalendario();
        this.getDisciplinas();
    }

    getCalendario = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-calendarios",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                const datas = this.ordenarDatas(response.data);
                let startDate = "";
                let endDate = "";

                if (datas.length > 0) {
                    startDate = datas[0];
                    endDate = datas[datas.length - 1];
                }

                this.setState({
                    calendars: response.data,
                    startDate,
                    endDate
                });
            })
            .catch(() => { });
    };

    getDisciplinas = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-disciplinas",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    option3Options: response.data,
                    listaDisciplinas: response.data
                });
            })
            .catch(() => { });
    };

    ordenarDatas = listaDeDatas => {
        let datasObjeto = listaDeDatas.reduce((arr, data) => {
            arr.push(new Date(data.dt_inicio), new Date(data.dt_termino));
            return arr;
        }, []);

        datasObjeto.sort((a, b) => a - b);

        return datasObjeto;
    };

    handleOk = () => {
        const { selectedOption3 } = this.state;

        if (this.state.listaDisciplinas.length > 1) this.setState({ selectedOption3: this.state.disciplina });

        this.props.onSelect(
            this.state.listaDisciplinas.length > 1 ? selectedOption3 : this.state.disciplina,
            this.state.dataEmissao
        );
        this.props.onClose();
        this.setState({
            selectedOption3: undefined
        });
    };

    handleOption3Change = value => {
        const disciplina = this.state.listaDisciplinas.find(x => x.id === value)?.nome;
        if (disciplina !== "Todos")
            localStorage.setItem("disciplina_nome", `${localStorage.getItem("header_nome")} ${disciplina}`);
        else
            localStorage.removeItem("disciplina_nome");

        this.setState({
            selectedOption3: value
        });
    };

    handleChangeDataEmissao = date => {
        this.setState({ dataEmissao: date });
    };

    render() {
        return (
            <Modal
                title="Selecione a opção"
                visible={this.props.visible}
                onOk={this.handleOk}
                okText={"Visualizar"}
                onCancel={() => {
                    this.props.onClose()
                    this.setState({
                        selectedOption3: undefined,
                        dataEmissao: moment()
                    })
                }}
                okButtonProps={{
                    style: {
                        backgroundColor: "rgb(70, 176, 255)",
                        borderColor: "rgb(27, 185, 255)"
                    }
                }}
            >
                <div className="my-modal-wrapper">
                    {this.state.listaDisciplinas.length > 1 ? (
                        <>
                            <div className="select-wrapper">
                                <Select
                                    value={this.state.selectedOption3}
                                    placeholder="Selecionar Opção"
                                    onChange={this.handleOption3Change}
                                    className="custom-select ant-select-selection--single"
                                >
                                    {this.state.option3Options.map(disciplina => (
                                        <Option key={disciplina.id} value={disciplina.id}>
                                            {disciplina.nome}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="select-wrapper">
                                <DatePicker
                                    name="dataEmissao"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar Data de Emissão"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={this.handleChangeDataEmissao}
                                    value={this.state.dataEmissao}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="select-wrapper">
                                <DatePicker
                                    name="dataEmissao"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar Data de Emissão"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={this.handleChangeDataEmissao}
                                    value={this.state.dataEmissao}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        );
    }
}

export default ModalHorarioRelatorio;