import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Switch, Modal } from "antd";
import Header from "../../User/Header";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import {ArrowCircleLeft} from "iconsax-react";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Turnos extends Component {
    static contextType = GlobalContext;
    state = {
        turnos: [],
        ativo: true,
        //DRAWER
        id: 0,
        nome: "",
        visible: false,
        iconLoading: false,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA EDIÇÃO NA TABELA
    montarMenu = (id, ativo) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => { event.stopPropagation(); this.ativar(id, ativo) }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => { event.stopPropagation(); this.ativar(id, ativo) }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => { event.stopPropagation(); this.excluir(id) }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    //CARREGA TODAS AS EDIÇÕES DA ENTIDADE PARA A TABELA
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/categoria-noticia/listar",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var turnos = response.data.map((turno, index) => {
                            return {
                                key: index,
                                id: turno.id,
                                nome: turno.nome,
                                opcoes: this.montarMenu(turno.id, turno.ativo)
                            }
                        });

                        this.setState({
                            turnos,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    carregar = id => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/categoria-noticia/carregar",
            params: {
                id
            }
        })
            .then(response => {
                this.setState({
                    id: response.data.id,
                    nome: response.data.nome,
                    ativo: response.data.ativo,
                    visible: true
                });
            })
            .catch(() => {});
    };

    //CRIA UMA NOVA EDIÇÃO
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);

                axios({
                    method: "post",
                    url: "/api/categoria-noticia/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Categoria adicionada!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A EDIÇÕES
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.id);
                item.append("nome", this.state.nome);

                axios({
                    method: "put",
                    url: "/api/categoria-noticia/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Categoria alterada!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //EXCLUI A EDIÇÃO
    excluir = id => {
      
            this.props.form.resetFields();
            axios({
                method: "get",
                url: "/api/categoria-noticia/existe-noticia",
                params: {
                    id
                }
            })
                .then(response => {
                    // console.log(response.data)
                    if(!response.data){
                        confirm({
                            title: "Pretende excluir este categoria?",
                            okText: "Confirmar",
                            okType: "Cancelar",
                            onOk: () => {
                                axios({
                                    method: "put",
                                    url: "/api/categoria-noticia/excluir",
                                    params: {
                                        id
                                    }
                                })
                                    .then(() => {
                                        openNotificationWithIcon("success", "Sucesso", "categoria excluida!");
                                        this.listar();
                                    })
                                    .catch(error => {
                                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                                    });
                            }
                        });
                    }else{
                        openNotificationWithIcon("error", "Erro", "Existem noticias com esta categoria");
                    }
                })
                .catch(() => {});
            
    };

    //ATIVA E INATIVA A EDIÇÃO
    ativar = (id, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta categoria?" : "Pretende ativar esta categoria?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/categoria-noticia/ativar",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Categoria inativada!" : "Categoria ativada!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a categoria!" : "Não foi possível ativar a categoria!"
                        );
                    });
            }
        });
    };

    //ABRE O DRAWER VAZIO PARA CRIAR UMA NOVA EDIÇÃO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            id: 0,
            nome: "",
            visible: true
        });
    };

    //FECHA O DRAWER SEM GUARDAR A EDIÇÃO
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    onRowClick = (id) => {
        this.carregar(id);
    }

    render() {
        const {
            turnos,
            ativo,
            id,
            nome,
            visible,
            iconLoading,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Castegorias Notícias" />
                <div className="container container-body">
                    <div className="bloco-categorias">
                        <div className="bloco-tabela">
                            <div className="controlos" style={{ justifyContent: "space-between",  display: "flex" }}>
                                <div className="bloco-pesquisa">
                                    <button
                                        className="botao-principal botao-principal-redondo"
                                        title="Adicionar"
                                        onClick={this.showDrawer}
                                        disabled={!this.state.ativo}
                                    >
                                        +
                                    </button>
                                </div>
                                <div  className='filtro'>
                                    <Switch
                                        checkedChildren="Ativos"
                                        unCheckedChildren="Inativos"
                                        defaultChecked
                                        onChange={this.handleChangeSwitch}
                                    />

                                    <Link className='botao-voltar2'
                                          to='/utilizador'
                                          onClick={()=>{
                                              this.context.atualizarState({
                                                  mostrarOptPaginasPublicas: true
                                              })
                                          }}
                                    >
                                        <ArrowCircleLeft size={34}/>
                                    </Link>
                                </div>
                                
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={turnos}
                                pagination={false}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    width={720}
                    onClose={this.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" disabled={!ativo} onChange={this.handleChange} />)}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {ativo && (
                            <button className="botao-principal" onClick={id ? this.alterar : this.criar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Salvar
                            </button>
                        )}
                    </div>
                </Drawer>
            </div>
        );
    }
}
const FormTurnos = Form.create({ name: "form-turnos" })(Turnos);

export default FormTurnos;
