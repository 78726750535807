import React from "react";
import {Link} from "react-router-dom";


export default function BotaoOpcao({imagem, nome, estilo,link, onClick}){
    return(
        <Link 
            to={link}
            className='botao-opcoes-perfil'
            onClick={(e) => {
                if (onClick) {
                    //e.preventDefault(); 
                    onClick(e); 
                }
            }}
        >
            <div className= {estilo}>
                <img src={imagem} alt={nome}/>
            </div>
            <div className='nome'>
                <span>{nome}</span>
            </div>

        </Link>
    )
}