import React, { Component } from "react";
import { Button, Icon, notification, Modal, Select } from "antd";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ExcelTabelaConteudos extends Component {
    state = {
        disciplinaId: localStorage.getItem("vista") === "TURMA" ? null : localStorage.getItem("codigo_curso"),
        //FILTROS
        disciplinas: [],
        disciplinasId: [],
        isSelected: false,
        opcao: "",
        num: 1,
        total: 0,
        semPeriodos: false,
        periodos: [],
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        mes: "",
        ano: "",
        titulo: "",
        aulas: [],
        //LOADING
        iconLoading: false,
        //TITULOS
        tituloConteudos: "",
        tituloHabilidades: "",
        tituloMetodologia: ""
    };

    componentDidMount() {
        if (localStorage.getItem("vista") === "TURMA") this.listarDisciplinas();

        this.total();
        this.titulosDetalhe();
    }

    selectDiscipline = opcao => {
        this.setState({ isSelected: true });
        this.handleSelect(opcao);
    };

    handleSelect = opcao => {
        let disciplinaId = opcao;
        if (opcao === "Todos") {
            this.setState(
                {
                    todos: opcao === "Todos"
                },
                () => this.listar(true)
            );
        }

        if (disciplinaId) {
            this.props.setDisciplinaId(disciplinaId);
            this.setState(
                {
                    disciplinaId
                },
                () => this.listar(true)
            );
        } else {
            this.setState({
                aulas: []
            });
        }
    };

    total = () => {
        //this.setState({
        //    iconLoading: true
        //}, () => {
        axios({
            method: "get",
            url: "/api/aulas/total-periodos-conteudos",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState(
                        {
                            total: response.data.length,
                            periodos: response.data,
                            titulo: response.data[0].titulo,
                            dt_inicio: response.data[0].dt_inicio,
                            dt_fim: response.data[0].dt_fim
                        },
                        () => {
                            if (!localStorage.getItem("vista")) this.listar();

                            this.props.carregarPeriodoId(response.data[0].id);
                        }
                    );
                } else {
                    this.setState({
                        semPeriodos: true
                        //iconLoading: false
                    });
                    openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                }
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
        //})
    };

    listar = () => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/listar-conteudos",
                    params: {
                        id: this.state.disciplinaId == "Todos" ? localStorage.getItem("codigo_curso") : this.state.disciplinaId,
                        dt_inicio: this.state.dt_inicio,
                        dt_fim: this.state.dt_fim
                    }
                })
                    .then(response => {
                        this.setState({
                            aulas: response.data,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                    });
            }
        );
    };

    listarDisciplinas = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-turma-disciplinas",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    disciplinas: response.data,
                    iconLoading: false,
                    disciplinasId: response.data.map(e => e.id)
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as disciplinas!");
            });
    };

    titulosDetalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/titulos-conteudo",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    tituloConteudos: response.data.conteudos,
                    tituloHabilidades: response.data.habilidades,
                    tituloMetodologia: response.data.metodologias
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    anteriorProximo = num => {
        let periodo = this.state.periodos[num - 1];

        this.setState(
            {
                num,
                titulo: periodo.titulo,
                dt_inicio: periodo.dt_inicio,
                dt_fim: periodo.dt_fim
            },
            () => {
                this.listar();
                this.props.carregarPeriodoId(periodo.id);
            }
        );
    };

    proximaDisciplina = disciplinaId => {
        const disciplinas = this.state.disciplinas.map(e => e.id);

        let nextValue = disciplinas[disciplinas.indexOf(disciplinaId) + 1];

        this.handleSelect(nextValue);
        this.anteriorProximo(1);
    };

    anteriorDisciplina = disciplinaId => {
        const disciplinas = this.state.disciplinas.map(e => e.id);

        let periodosLength = this.state.periodos.length;

        let previousValue = disciplinas[disciplinas.indexOf(disciplinaId) - 1];

        this.handleSelect(previousValue);
        this.anteriorProximo(periodosLength);
    };

    render() {
        const {
            disciplinaId,
            //FILTROS
            disciplinas,
            opcao,
            isSelected,
            disciplinasId,
            num,
            semPeriodos,
            periodos,
            //PERIODO
            titulo,
            aulas,
            //LOADING
            iconLoading,
            //TITULOS
            tituloConteudos,
            tituloHabilidades,
            tituloMetodologia
        } = this.state;

        return (
            <>
                <div>
                    <div className="bloco-controlos-frequencia">
                        <div className="filters">
                            <div>
                                {localStorage.getItem("vista") === "TURMA" && (
                                    <Select
                                        className="filter"
                                        placeholder="Selecionar Disciplina"
                                        name="disciplinas"
                                        allowClear={true}
                                        onChange={this.selectDiscipline}
                                        disabled={disciplinas.length === 0}
                                    >
                                        <Option value="Todos">Todos</Option>
                                        {disciplinas.map(disciplina => (
                                            <Option key={disciplina.id} value={disciplina.id}>
                                                {disciplina.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </div>
                            {!semPeriodos && (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {isSelected === false && typeof disciplinaId === "number" ? (
                                        <>
                                            <Button
                                                className="botao-periodo botao-periodo-anterior"
                                                disabled={
                                                    num === 1
                                                }
                                                style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                                onClick={() => {
                                                    this.anteriorProximo(num - 1);
                                                }}
                                            >
                                                <Icon type="left" />
                                            </Button>
                                            <span>{titulo}</span>
                                            <Button
                                                className="botao-periodo botao-periodo-proximo"
                                                disabled={
                                                    num === periodos.length || localStorage.getItem("vista") === "TURMA" && !disciplinaId
                                                }
                                                style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                                onClick={() => {
                                                    this.anteriorProximo(num + 1);
                                                }}
                                            >
                                                <Icon type="right" />
                                            </Button>{" "}
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                className="botao-periodo botao-periodo-anterior"
                                                disabled={num === 1}
                                                style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                                onClick={() => this.anteriorProximo(num - 1)}
                                            >
                                                <Icon type="left" />
                                            </Button>
                                            <span>{titulo}</span>
                                            <Button
                                                className="botao-periodo botao-periodo-proximo"
                                                disabled={
                                                    num === periodos.length || localStorage.getItem("vista") === "TURMA" && !disciplinaId /*|| (localStorage.getItem("vista") === "TURMA" && !disciplinaId)*/
                                                }
                                                style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                                onClick={() => this.anteriorProximo(num + 1)}
                                            >
                                                <Icon type="right" />
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                            <div style={{ textAlign: "end" }}>
                                Sumário e/ou Frequência por preencher:{" "}
                                <strong>{aulas.filter(x => x.estado === "POR_REALIZAR").length}</strong>
                            </div>
                        </div>
                    </div>
                    {aulas.map((aula, index) => (
                        <div key={index} className="bloco-diario-observacoes">
                            <div
                                className="observacao-data-aula"
                                style={
                                    aula.cor === "white"
                                        ? {
                                              background: aula.cor,
                                              border: "1px solid black",
                                              color: "black"
                                          }
                                        : {
                                              background: aula.cor,
                                              color: aula.cor === "#3d464d" ? "white" : ""
                                          }
                                }
                            >
                                {aula.curso && <span>{aula.curso}</span>}
                                <span>
                                    {aula.titulo?.trim() && (
                                        <>
                                            {aula.titulo} <i className="fas fa-circle separador-data-hora"></i>{" "}
                                        </>
                                    )}
                                    {moment(aula.dt_inicio).format("DD/MM/YYYY")} <i className="fas fa-circle separador-data-hora"></i>{" "}
                                    {aula.hr_inicio} - {aula.hr_termino}
                                </span>
                            </div>
                            <div style={{ padding: "10px 20px 20px" }}>
                                {!aula.conteudo && !aula.conceitos && !aula.metodologia && <p>Sem dados...</p>}
                                {aula.conteudo && (
                                    <div>
                                        <label style={{ borderBottom: "1px solid #a6c8d5", paddingBottom: 3, fontWeight: "600" }}>
                                            {/*Objeto de Conhecimento/Conteúdo*/}
                                            {tituloConteudos}
                                        </label>
                                        <p style={{ marginTop: 10 }}>{aula.conteudo}</p>
                                    </div>
                                )}
                                {aula.conceitos && (
                                    <div>
                                        <label style={{ borderBottom: "1px solid #a6c8d5", paddingBottom: 3, fontWeight: "600" }}>
                                            {/*Habilidades / Competências*/}
                                            {tituloHabilidades}
                                        </label>
                                        {aula.conceitos.split(";").map(conceito => (
                                            <p style={{ marginTop: 10 }}>{conceito}</p>
                                        ))}
                                    </div>
                                )}
                                {aula.metodologia && (
                                    <div>
                                        <label style={{ borderBottom: "1px solid #a6c8d5", paddingBottom: 3, fontWeight: "600" }}>
                                            {/*DESDP/Metodologia*/}
                                            {tituloMetodologia}
                                        </label>
                                        <p style={{ marginTop: 10 }}>{aula.metodologia}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {aulas.length === 0 && <span>Sem dados...</span>}
                </div>
                <Modal visible={iconLoading} maskClosable={false} className="modal-loading" footer={null} closable={false}>
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ExcelTabelaConteudos;

/*

 <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Button className='botao-periodo botao-periodo-anterior' disabled={num === 1 || localStorage.getItem("vista") === "TURMA" && !disciplinaId} style={{ color: "rgba(0, 0, 0, 0.8)" }} onClick={() => this.anteriorProximo(num - 1)}>
                                        <Icon type="left" />
                                    </Button>
                                    <span>{titulo}</span>
                                    <Button className='botao-periodo botao-periodo-proximo' disabled={num === periodos.length || localStorage.getItem("vista") === "TURMA" && !disciplinaId} style={{ color: "rgba(0, 0, 0, 0.8)" }} onClick={() => this.anteriorProximo(num + 1)}>
                                        <Icon type="right" />
                                    </Button>
                                </div>

*/
