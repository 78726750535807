import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Switch, Menu, Modal, Tooltip } from "antd";
import Header from "../../../User/Header";
import noimage from "../../../images/noimage.png";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import { GlobalContext } from "../../../GlobalState";
import {ArrowCircleLeft} from "iconsax-react";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Edicoes extends Component {
    static contextType = GlobalContext;
    state = {
        edicoes: [],
        ativo: true,
        //DRAWER
        edicaoId: 0,
        nome: "",
        visible: false,
        iconLoading: false,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA EDIÇÃO NA TABELA
    montarMenu = (edicaoId, ativo) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativar(edicaoId, ativo);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativar(edicaoId, ativo);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluir(edicaoId);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    //CARREGA TODAS AS EDIÇÕES DA ENTIDADE PARA A TABELA
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-turmas-edicoes/listar-edicoes",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var edicoes = [];

                        response.data.map((edicao, index) => {
                            edicoes.push({
                                key: index,
                                id: edicao.id,
                                edicao: edicao.nome,
                                opcoes: this.montarMenu(edicao.id, edicao.ativo)
                            });
                        });

                        this.setState({
                            edicoes,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    carregarEdicao = edicaoId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-turmas-edicoes/carregar-edicao",
            params: {
                edicaoId
            }
        })
            .then(response => {
                this.setState({
                    edicaoId: response.data.id,
                    nome: response.data.nome,
                    ativo: response.data.ativo,
                    visible: true
                });
            })
            .catch(() => {});
    };

    //CRIA UMA NOVA EDIÇÃO
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);

                axios({
                    method: "post",
                    url: "/api/gestao-turmas-edicoes/adicionar-edicao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionada nova edição!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A EDIÇÕES
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.edicaoId);
                item.append("nome", this.state.nome);

                axios({
                    method: "put",
                    url: "/api/gestao-turmas-edicoes/alterar-edicao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Edição alterada!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //EXCLUI A EDIÇÃO
    excluir = edicaoId => {
        confirm({
            title: "Pretende excluir esta Edição?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turmas-edicoes/excluir-edicao",
                    params: {
                        edicaoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Edição excluida!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    //ATIVA E INATIVA A EDIÇÃO
    ativar = (edicaoId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta edição?" : "Pretende ativar esta edição?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turmas-edicoes/ativar-edicao",
                    params: {
                        edicaoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Edição inativada!" : "Edição ativada!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a edição!" : "Não foi possível ativar a edição!"
                        );
                    });
            }
        });
    };

    //ABRE O DRAWER VAZIO PARA CRIAR UMA NOVA EDIÇÃO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            edicaoId: 0,
            nome: "",
            visible: true
        });
    };

    //FECHA O DRAWER SEM GUARDAR A EDIÇÃO
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    onRowClick = edicaoId => {
        this.carregarEdicao(edicaoId);
    };

    render() {
        const {
            ativo,
            edicaoId,
            nome,
            visible,
            iconLoading,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const columns = [
            {
                title: "Edição",
                dataIndex: "edicao"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Edições" />
                <div className="container container-body">
                    <div className="bloco-categorias">
                        <div className="bloco-tabela">
                            <div className="controlos" style={{ display: "flex", justifyContent: "space-between" }}>
                                <button
                                    className="botao-principal botao-principal-redondo"
                                    title="Adicionar"
                                    style={{ marginTop: 0 }}
                                    onClick={this.showDrawer}
                                    disabled={!this.state.ativo}
                                >
                                    +
                                </button>
                                <div className="filtros" style={{ paddingTop: 8 }}>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <Switch
                                            checkedChildren="Ativos"
                                            unCheckedChildren="Inativos"
                                            defaultChecked
                                            onChange={this.handleChangeSwitch}
                                        />
    
                                        <Link className='botao-voltar2'
                                              to='/utilizador'
                                              onClick={()=>{
                                                  this.context.atualizarState({
                                                      mostrarOptConfiguracoes: true
                                                  })
                                              }}
                                        >
                                            <ArrowCircleLeft size={34}/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={this.state.edicoes}
                                pagination={false}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    width={720}
                    onClose={this.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" disabled={!ativo} onChange={this.handleChange} />)}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {ativo && (
                            <button
                                className="botao-principal"
                                onClick={edicaoId ? this.alterar : this.criar}
                                disabled={iconLoading}
                                type="primary"
                            >
                                {iconLoading ? <Icon type="loading" /> : null}
                                Salvar
                            </button>
                        )}
                    </div>
                </Drawer>
            </div>
        );
    }
}
const FormEdicoes = Form.create({ name: "form-edicoes" })(Edicoes);

export default FormEdicoes;
