import { Badge, Collapse, Dropdown, Icon, Menu, Modal } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import logo from "../images/logos/stepforma-01.png";
import noimage from "../images/noimage.png";
import DrawerCarrinho from "./_Aux/DrawerCarrinho";

import sair from  "../images/icons/sair.png";

const { Panel } = Collapse;
class Navbar extends Component {
    static contextType = GlobalContext;

    state = {
        //ESTABELECIMENTOS E PERFIS
        estabelecimentos: [],
        perfis: [],
        entidade_id: 0,
        entidade_imagem_propria: false,
        entidade_logo: "",
        entidade_nome: "StepForma",
        entidade_nome_abrev: "",
        entidade_afiliado: false,
        entidade_visivel: false,
        entidade_url: "",
        entidade_site: "",
        entidade_chave: "",
        stepmeet_gestao: "",
        entidade_escolas: false,
        categorias: [],
        cursos_stepforma: false,
        acessos: [],
        code: "",
        nome: "",
        foto: "",
        nr_tickets: 0,
        nr_mensagens: 0,
        //FORMADOR
        formador: false,
        tem_licenca: false,
        //MOBILE
        mobile: false,
        //DRAWER CHAT
        mostrarChat: false,
        //DRAWER CARRINHO
        visibleCart: false,
        //LOADING
        iconLoading: false,
        //MENU MOBILE
        menuAdministracaoMobileEstado: [],
        menuGestaoMobileEstado: [],
        menuInstrutorMobileEstado: [],
        menuUtilizadorMobileEstado: ["1"]
        
    };
    

    componentDidMount() {
        if (this.context.carrinhoProdutos) {
            this.setState({
                visibleCart: true
            });
        }

        if (localStorage.getItem("escola_selecionada")) {
            const estabelecimento = JSON.parse(localStorage.getItem("escola_selecionada"));
            this.context.atualizarState({ estabelecimento });
        }

        if (localStorage.getItem("perfil_selecionado")) {
            const perfil = JSON.parse(localStorage.getItem("perfil_selecionado"));
            this.context.atualizarState({ perfil });
            this.carregarAcessos(perfil.id)
        }

        this.carregaDadosEntidade();
    }

    componentDidUpdate() {
        if (this.context.atualizarEntidade) {
            if (localStorage.getItem("escola_selecionada")) {
                const estabelecimento = JSON.parse(localStorage.getItem("escola_selecionada"));
                this.context.atualizarState({ estabelecimento });
            }

            if (localStorage.getItem("perfil_selecionado")) {
                const perfil = JSON.parse(localStorage.getItem("perfil_selecionado"));
                this.context.atualizarState({ perfil });
            }

            this.carregaDadosEntidade();
            this.context.atualizarState({ atualizarEntidade: false });
        }
    }

    onVisibleChangeEntidade = visible => {
        if (visible) {
            this.carregarTicketsPorLer();
        }
    };

    decodeHTML = moeda => {
        var map = { gt: ">" /* , … */ };
        return moeda.replace(/&(#(?:x[0-9a-f]+|\d+)|[a-z]+);?/gi, ($0, $1) => {
            if ($1[0] === "#") {
                return String.fromCharCode($1[1].toLowerCase() === "x" ? parseInt($1.substr(2), 16) : parseInt($1.substr(1), 10));
            } else {
                return map.hasOwnProperty($1) ? map[$1] : $0;
            }
        });
    };

    carregaDadosEntidade = () => {
        axios({
            method: "get",
            url: "/api/navbar/dados-entidade"
        })
            .then(response => {
                this.context.atualizarState({
                    id_entidade: response.data.id,
                    nome_entidade: response.data.nome,
                    entidade_abreviatura: response.data.abreviatura,
                    responsaveis_entidade: response.data.responsaveis,
                    formacao_entidade: response.data.formacao,
                    projeto_entidade: response.data.projeto,
                    organizacional_entidade: response.data.organizacional,
                    visivel_entidade: response.data.visivel_entidade,
                    albumId_entidade: response.data.albumId,
                    chave_entidade: response.data.chave_integracao,
                    escolas_entidade: response.data.escolas,
                    login2_entidade: response.data.login2,
                    disciplinas_entidade: response.data.disciplinas,
                    conf_total_participantes: response.data.totalParticipantes,
                    conf_total_recorders: response.data.totalRecorders,
                    monetizacao_entidade: response.data.monetizacao_entidade,
                    monetizacao_stepforma: response.data.monetizacao_stepforma,
                    vendaCursos: response.data.vendaCursos,
                    vendaCertificados: response.data.vendaCertificados,
                    valorMensal: response.data.valorMensal,
                    subscricao_entidade: response.data.subscricao,
                    moeda_entidade: this.decodeHTML(response.data.moeda),
                    gamificacao_entidade: response.data.gamificacao,
                    gamificacao_stepforma: response.data.gamificacao_stepforma,
                    conteudos_animados_entidade: response.data.conteudos_animados,
                    stepmeet_gestao: response.data.stepmeet_gestao,
                    whatsapp_entidade: response.data.whatsapp,
                    reclamacoes_entidade: response.data.reclamacoes,
                    diario_entidade: response.data.diario,
                    diario_stepforma: response.data.diario_stepforma,
                    modulo_formacao_stepforma: response.data.modulo_formacao_stepforma,
                    modulo_formacao: response.data.modulo_formacao,
                    conversas_stepforma: response.data.conversas_stepforma,
                    conversas: response.data.conversas,
                    calendario_stepforma: response.data.calendario_stepforma,
                    calendario: response.data.calendario,
                    relatorios: response.data.relatorios,
                    estatisticas: response.data.estatisticas,
                    eventos: response.data.eventos,
                    stepmeets: response.data.stepmeets,
                    stebinars: response.data.stebinars,
                    newsletter: response.data.newsletter,
                    stepforms: response.data.stepforms
                });

                this.setState({
                    entidade_id: response.data.id,
                    entidade_imagem_propria: response.data.imagem_propria,
                    entidade_logo: response.data.logo,
                    entidade_nome: response.data.nome,
                    entidade_nome_abrev: response.data.nome_abrev,
                    entidade_visivel: response.data.visivel_entidade,
                    entidade_url: response.data.url,
                    entidade_site: response.data.site,
                    entidade_chave: response.data.chave_integracao,
                    stepmeet_gestao: response.data.stepmeet_gestao,
                    entidade_escolas: response.data.escolas
                });

                localStorage.setItem("entidade_id", response.data.id);

                if (response.data.imagem_propria)
                    this.context.atualizarState({
                        favicon_entidade: response.data.logo,
                        title_entidade: response.data.nome
                    });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarAcessos = (perfilId) => {
        axios
            .get("/api/permissoes/carregar-acessos", { params: { perfilId } })
            .then((response) => {
                this.context.atualizarState({
                    acessos: response.data
                });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };
    
    logOut = () => {
        this.setState({
            mobile: false,
            iconLoading: true
        });

        axios({
            method: "post",
            url: "/api/autenticacao/logout"
        })
            .then(response => {
                localStorage.removeItem("accessToken");
                delete axios.defaults.headers.common.Authorization;

                localStorage.removeItem("participanteNaMeet");
                this.context.atualizarState({
                    favicon_entidade: "",
                    title_entidade: "",
                    utilizadorCarregado: false
                });

                if (
                    !this.state.entidade_chave ||
                    localStorage.getItem("redirecionado") !== "true" ||
                    (this.state.entidade_chave && localStorage.getItem("redirecionado") === "true" && !this.state.entidade_site)
                ) {
                    localStorage.removeItem("redirecionado");

                    this.setState({
                        redirect: true,
                        redirectLink: this.state.entidade_visivel ? `/${response.data}` : "/"
                    });
                } else {
                    localStorage.removeItem("redirecionado");
                    window.open(this.state.entidade_site, "_self");
                }
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    iconLoading: false
                });
            });
    };

    //DRAWER CHAT
    fecharChat = () => {
        this.setState({ mostrarChat: false });
    };

    voltarCarrinho = () => {
        this.setState(
            {
                visibleCart: false
            },
            () => {
                this.context.atualizarState({
                    carrinho: []
                });
            }
        );
    };

    render() {
        const {
            //ESTABELECIMENTOS E PERFIS
            estabelecimentos,
            perfis,
            entidade_imagem_propria,
            entidade_logo,
            entidade_nome,
            entidade_afiliado,
            entidade_escolas,
            stepmeet_gestao,
            foto,
            nome,
            nr_tickets,
            acessos,
            //DRAWER CARRINHO
            visibleCart,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            iconLoading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;
        
        const montarLogoLink = perfilId => {
            if (!perfilId) perfilId = this.context.perfil?.id;

            if (perfilId == 1)
                //SUPORTE
                return "/utilizador";
            else if (perfilId == 2)
                //GESTOR
                return "/utilizador"; // "/gestao-utilizadores-todos";
            else if (perfilId == 3)
                //ADMINISTRATIVO
                return "/utilizador"; //"/relatorios";
            else if (perfilId == 4)
                //DIRETOR
                return "/utilizador";
            //ALUNO, PROFESSOR E COORDENADOR
            else return "/utilizador";
        };

        
        const currentPath = window.location.pathname;

        return (
            <div className="navbar-log-in">
                <div className="container container-v2">
                    <Link to={montarLogoLink()} className="brand" title={entidade_nome}>
                        <img src={entidade_imagem_propria ? (entidade_logo ? entidade_logo : noimage) : logo} alt="Logo"
                             className="logo"/>
                    </Link>
                    <div className='sessao-botoes' >
                        {(currentPath !== '/utilizador' || this.context.mostrarOptPaginasPublicas || this.context.mostrarOptConfiguracoes) &&
                            <Link
                                className="nav-link botao-inicio"
                                to='/utilizador'
                                
                                onClick={()=>{
                                    this.context.atualizarState({
                                        mostrarOptConfiguracoes: false,
                                        mostrarOptPaginasPublicas: false
                                    })
                                }
                                }
                            >
                                
                                    Início
                                
                            </Link>
                        }
                        
                        <button
                            className="nav-link botao-principal botao-sair"
                            onClick={this.logOut}
                            style={{fontSize: "18px", paddingTop: 5, paddingBottom: 5, textAlign: "center"}}
                        >
                        <span>
                            <img src={sair} alt='sair'/>
                        </span>
                        <span style={{textTransform:'none'}}>
                            Sair
                        </span>
                        </button>
                    </div>

                    
                </div>
                <DrawerCarrinho
                    visibleCart={visibleCart}
                    onClose={() => this.setState({visibleCart: false})}
                    voltarCarrinho={this.voltarCarrinho}
                />
                <Modal visible={iconLoading} maskClosable={false} className="modal-loading" footer={null}
                       closable={false}>
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading"/>
                        </p>
                        <p className="texto">A sair...</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Navbar;
