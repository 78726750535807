import React from "react";

const ProfileIcon = ({ name, size = 50, backgroundColor = "#4caf50", textColor = "#ffffff" }) => {
    // Gera as iniciais do primeiro e último nome
    const nameParts = name.trim().split(" ");
    const initials =
        nameParts.length > 1
            ? `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`.toUpperCase() // Primeiro e último nome
            : nameParts[0][0].toUpperCase(); // Caso só haja um nome

    return (
        <div className='iniciais-nome'>
            <div
            >
                {initials}
            </div>
        </div>

    );
};

export default ProfileIcon;
