import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification } from "antd";
import UserInfo from "../../UserInfo/UserInfo";
import Tabela from "./Tabela";
import ModalLoading from "../../../../Componentes/Modals/ModalLoading";
import ModalViewer from "../../../../Componentes/Modals/ModalViewer";
import { GlobalContext } from "../../../../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Educacao = ({ menuOptionSelected, match }) => {
    const globalContext = useContext(GlobalContext);
    const [search, setSearch] = useState("");
    const [activated, setActivated] = useState(true);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [docs, setDocs] = useState([]);

    const gerarDoc = () => {
        setVisibleLoading(true);
        
        axios({
            method: "get",
            url: "/api/pdf/dados-gerais-professor",
            params: {
                code: match.params.code,
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`escola${match.params.code}`]?.id
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true);
                setDocs([{ uri: response.data }]);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                setVisibleLoading(false);
            });
    };

    useEffect(() => {
        setSearch("");
    }, []);

    const handleChangeSearch = value => {
        setSearch(value);
    };

    const resetSearch = () => {
        setActivated(true);
        setSearch("");
    };

    return (
        <>
            <div className="page-container">
                <UserInfo
                    educacaoProfessor
                    search={search}
                    handleChangeSearch={handleChangeSearch}
                    resetSearch={resetSearch}
                    setActivated={setActivated}
                />
                <div className="user-info-detail">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{ margin: "20px 20px 10px 20px" }}>
                                Turmas
                            </h1>
                        </div>
                        <span className="page-controls">
                            <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar" onClick={gerarDoc}>
                                <Icon type="eye" />
                            </Link>
                        </span>
                    </div>
                    <div className="block-table">
                        <Tabela search={search} activated={activated} setActivated={setActivated} />
                    </div>
                </div>
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => setVisibleViewer(false)} />
            </div>
        </>
    );
};

export default withRouter(Educacao);
