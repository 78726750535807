import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { Icon, Form, Input, Select, DatePicker, Tooltip, notification } from 'antd';
import UserInfo from "../UserInfo/UserInfo";
import noimage from "../../../images/noimage.png";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";

import { cancelLink } from "../../../Utilities/cancelRedirect"

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DadosGerais = ({ menuOptionSelected, form }) => {
    const globalContext = useContext(GlobalContext);
    const [formData, setFormData] = useState({
        //FOTO UTILIZADOR
        foto: "",
        preview: null,
        //DADOS GERAIS
        nome_completo: "",
        emailnotif: "",
        contacto: "",
        descricao: "",
        habilitacao: "",
        paginaPessoal: "",
        dt_nascimento: null,
        naturalidade: "",
        sexo: "",
        estadoCivil: "",
        cc: "",
        dt_ccvalidade: null,
        nif: "",
        cep: "",
        localidade: "",
        morada: "",
        pais: "",
        //FILIAÇÃO
        maeResponsavel: "",
        maeProfissao: "",
        maeEmail: "",
        maeContacto: "",
        paiResponsavel: "",
        paiProfissao: "",
        paiEmail: "",
        paiContacto: "",
        //RESPONSAVEL
        utilizarDados: undefined,
        responsavelNome: "",
        responsavelEmail: "",
        responsavelContacto: ""
    })
    const [validacao, setValidacao] = useState({
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: ""
    });
    const [permissao, setPermissao] = useState(false);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState(false);

    const { Option } = Select;
    const { TextArea } = Input;
    const { getFieldDecorator } = form;

    const carregarDadosPerfil = () => {
        axios({
            method: "get",
            url: "/api/minha-conta"
        })
            .then(response => {
                globalContext.atualizarState({
                    nr_utilizador: response.data.nr_aluno,
                    dt_adesao_utilizador: response.data.dt_criado,
                    login2: response.data.login2,
                    email: response.data.email,
                    nome_apelido: response.data.nome_apelido,
                    organismo: response.data.organismo,
                    medalhasCurso: response.data.medalhasCurso,
                    medalhasTarefa: response.data.medalhasTarefa
                });

                setFormData({
                    //FOTO UTILIZADOR
                    foto: response.data.foto,
                    preview: response.data.foto,
                    //DADOS GERAIS
                    nome_completo: response.data.nome_completo,
                    emailnotif: response.data.emailnotif,
                    contacto: response.data.contacto ? response.data.contacto : "",
                    descricao: response.data.descricao ? response.data.descricao : "",
                    habilitacao: response.data.habilitacao ? response.data.habilitacao : "",
                    paginaPessoal: response.data.paginaPessoal ? response.data.paginaPessoal : "",
                    dt_nascimento: response.data.dt_nascimento ? moment(response.data.dt_nascimento) : null,
                    naturalidade: response.data.naturalidade ? response.data.naturalidade : "",
                    sexo: response.data.sexo ? response.data.sexo : undefined,
                    estadoCivil: response.data.estado_civil ? response.data.estado_civil : undefined,
                    cc: response.data.cc ? response.data.cc : "",
                    dt_ccvalidade: response.data.dt_ccvalidade ? moment(response.data.dt_ccvalidade) : null,
                    nif: response.data.nif ? response.data.nif : "",
                    cep: response.data.cep ? response.data.cep : "",
                    localidade: response.data.localidade ? response.data.localidade : "",
                    morada: response.data.morada ? response.data.morada : "",
                    pais: response.data.pais ? response.data.pais : "",
                    //FILIAÇÃO
                    paiResponsavel: response.data.responsavel_1 ? response.data.responsavel_1 : "",
                    paiProfissao: response.data.responsavel_profissao_1 ? response.data.responsavel_profissao_1 : "",
                    paiEmail: response.data.responsavel_email_1 ? response.data.responsavel_email_1 : "",
                    paiContacto: response.data.responsavel_contacto_1 ? response.data.responsavel_contacto_1 : "",
                    paiCpf: response.data.responsavel_cpf_1 ? response.data.responsavel_cpf_1 : "",
                    maeResponsavel: response.data.responsavel_2 ? response.data.responsavel_2 : "",
                    maeProfissao: response.data.responsavel_profissao_2 ? response.data.responsavel_profissao_2 : "",
                    maeEmail: response.data.responsavel_email_2 ? response.data.responsavel_email_2 : "",
                    maeContacto: response.data.responsavel_contacto_2 ? response.data.responsavel_contacto_2 : "",
                    maeCpf: response.data.responsavel_cpf_2 ? response.data.responsavel_cpf_2 : "",
                    //RESPONSAVEL
                    utilizarDados: response.data.utilizar_dados ? response.data.utilizar_dados : undefined,
                    responsavelNome: response.data.responsavel_nome ? response.data.responsavel_nome : "",
                    responsavelEmail: response.data.responsavel_email ? response.data.responsavel_email : "",
                    responsavelContacto: response.data.responsavel_contacto ? response.data.responsavel_contacto : ""
                });
                setPermissao(response.data.pode_alterar_dados_pessoais);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const atualizarDados = event => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err && validacao.totalBytes <= 100 * 1024 && validacao.formatoValido) {
                setLoading(true);

                var item = new FormData();
                item.append("foto", formData.foto);
                item.append("nome_completo", formData.nome_completo);
                item.append("emailnotif", formData.emailnotif);
                item.append("contacto", formData.contacto);
                item.append("descricao", formData.descricao);
                item.append("habilitacao", formData.habilitacao);
                item.append("paginaPessoal", formData.paginaPessoal);
                if (formData.dt_nascimento)
                    item.append("dt_nascimento", formData.dt_nascimento.format("YYYY/MM/DD"));
                item.append("naturalidade", formData.naturalidade);
                if (formData.sexo)
                    item.append("sexo", formData.sexo);
                if (formData.estadoCivil)
                    item.append("estado_civil", formData.estadoCivil);
                item.append("cc", formData.cc);
                if (formData.dt_ccvalidade)
                    item.append("dt_ccvalidade", formData.dt_ccvalidade.format("YYYY/MM/DD"));
                item.append("nif", formData.nif);
                item.append("cep", formData.cep);
                item.append("localidade", formData.localidade);
                item.append("morada", formData.morada);
                item.append("pais", formData.pais);
                //FILIAÇÃO
                item.append("responsavel_1", formData.paiResponsavel);
                item.append("responsavel_profissao_1", formData.paiProfissao);
                item.append("responsavel_email_1", formData.paiEmail);
                item.append("responsavel_contacto_1", formData.paiContacto);
                item.append("responsavel_cpf_1", formData.paiCpf);
                item.append("responsavel_2", formData.maeResponsavel);
                item.append("responsavel_profissao_2", formData.maeProfissao);
                item.append("responsavel_email_2", formData.maeEmail);
                item.append("responsavel_contacto_2", formData.maeContacto);
                item.append("responsavel_cpf_2", formData.maeCpf);
                //RESPONSAVEL
                if (formData.utilizarDados)
                    item.append("utilizar_dados", formData.utilizarDados);
                item.append("responsavel_nome", formData.responsavelNome);
                item.append("responsavel_email", formData.responsavelEmail);
                item.append("responsavel_contacto", formData.responsavelContacto);

                axios({
                    method: "put",
                    url: "/api/minha-conta",
                    data: item
                })
                    .then(response => {
                        globalContext.atualizarState({
                            foto_utilizador: response.data.foto,
                            nome_utilizador: response.data.nome
                        });
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        });
    };

    const validarLink = (rule, value, callback) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$', 'i');
        if (!pattern.test(value) && value)
            callback("Introduza uma página válida")

        callback();
    }

    const disabledDate = current => {
        return current && current > moment().endOf("day");
    };

    const uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024)
            if (validarFormatoImagem(event.target.files[0].name.split(".").pop().toLowerCase())) {
                setFormData({ ...formData, foto: event.target.files[0], preview: URL.createObjectURL(event.target.files[0]) })
                setValidacao({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                })
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
            }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a ficheiro.");
        }
    };

    const validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    const removerFoto = () => {
        document.getElementById("input-foto").value = "";
        setFormData({ ...formData, foto: "", preview: "" })
    };

    const redirectUser = () => {
        const redirectLink = cancelLink(globalContext.perfil?.id);

        setRedirectLink(redirectLink);
        setRedirect(true);
    }

    useEffect(() => {
        carregarDadosPerfil();
    }, [])

    if (redirect)
        return <Redirect to={redirectLink} />;

    return (
        <div className='details-page' style={{marginTop:'80px'}}>
            <div className="page-container">
                <UserInfo />
                <div className="user-info-detail ajustar-ecra-centro">
                    <div className="personal-info">
                        <div className="img-block">
                            <Form>
                                <Form.Item validateStatus={validacao.validateStatus} help={validacao.help}>
                                    <div className="container-img">
                                        <img src={formData.preview ? formData.preview : noimage} />
                                    </div>
                                    <input
                                        type="file"
                                        id="input-foto"
                                        accept="image/*"
                                        onChange={uploadFoto}
                                        style={{ display: "none" }}
                                    />
                                    {permissao &&
                                        <>
                                            {!formData.preview ? (
                                                <>
                                                    <Link to="#" className="link-principal-border" onClick={ativarUploadFoto}>
                                                        <span>Adicionar</span>
                                                    </Link>
                                                    <Tooltip
                                                        className="info-icon"
                                                        title={
                                                            <>
                                                                <p>Tamanho máximo: 100KB</p>
                                                                <p>Tamanho recomendado: 200x200px</p>
                                                                <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                            </>
                                                        }
                                                    >
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <>
                                                    <Link
                                                        to="#"
                                                        className="link-principal-border"
                                                        onClick={ativarUploadFoto}
                                                    >
                                                        <span>Alterar</span>
                                                    </Link>
                                                    <Tooltip
                                                        className="info-icon"
                                                        title={
                                                            <>
                                                                <p>Tamanho máximo: 100KB</p>
                                                                <p>Tamanho recomendado: 200x200px</p>
                                                                <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                            </>
                                                        }
                                                    >
                                                        <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                                    </Tooltip>
                                                    <Link
                                                        to="#"
                                                        className="link-principal-border"
                                                        onClick={removerFoto}
                                                    >
                                                        <span>Remover</span>
                                                    </Link>
                                                </>
                                            )}
                                        </>
                                    }
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="bloco-info">
                            <Form className="minha-conta">
                                <div className="bloco">
                                    <Form.Item label="Nome Completo">
                                        {getFieldDecorator("nome-completo", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: formData.nome_completo
                                        })(<Input name="nome_completo" disabled={!permissao} onChange={(e) => setFormData({ ...formData, nome_completo: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                E-mail
                                                <Tooltip className="info-icon" className="info-icon info-icon2" title="E-mail usado para as notificações...">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        }
                                        className="input-50"
                                    >
                                        {getFieldDecorator("email", {
                                            rules: [
                                                {
                                                    type: "email",
                                                    message: "Introduza um endereço de e-mail válido"
                                                },
                                                {
                                                    required: false
                                                }
                                            ],
                                            initialValue: formData.emailnotif
                                        })(<Input name="emailnotif" enabled="true" disabled={!permissao} onChange={(e) => setFormData({ ...formData, emailnotif: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Telefone / Celular">
                                        {getFieldDecorator("contacto", {
                                            initialValue: formData.contacto
                                        })(<Input name="contacto" className="input-25" disabled={!permissao} onChange={(e) => setFormData({ ...formData, contacto: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                Breve Descrição
                                                <Tooltip className="info-icon info-icon2" title="Resumo sucinto sobre a minha pessoa...">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        {getFieldDecorator("descricao", {
                                            initialValue: formData.descricao
                                        })(
                                            <TextArea
                                                name="descricao"
                                                onChange={(e) => setFormData({ ...formData, descricao: e.target.value })}
                                                rows={4}
                                                style={{ height: 100 }}
                                                disabled={!permissao}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Habilitações">
                                        {getFieldDecorator("habilitacao", {
                                            initialValue: formData.habilitacao
                                        })(
                                            <TextArea
                                                name="habilitacao"
                                                onChange={(e) => setFormData({ ...formData, habilitacao: e.target.value })}
                                                rows={3}
                                                style={{ height: 100 }}
                                                disabled={!permissao}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Página Web pessoal">
                                        {getFieldDecorator("paginaPessoal", {
                                            rules: [
                                                {
                                                    validator: validarLink
                                                }
                                            ],
                                            initialValue: formData.paginaPessoal
                                        })(<Input name="paginaPessoal" disabled={!permissao} onChange={(e) => setFormData({ ...formData, paginaPessoal: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Data de nascimento">
                                        {getFieldDecorator("dt_nascimento", {
                                            initialValue: formData.dt_nascimento
                                        })(
                                            <DatePicker
                                                className="input-25"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dt_nascimento"
                                                onChange={(dt_nascimento) => setFormData({ ...formData, dt_nascimento })}
                                                disabledDate={disabledDate}
                                                disabled={!permissao}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Naturalidade">
                                        {getFieldDecorator("naturalidade", {
                                            initialValue: formData.naturalidade
                                        })(<Input name="naturalidade" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, naturalidade: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Sexo">
                                        {getFieldDecorator("sexo", {
                                            initialValue: formData.sexo
                                        })(
                                            <Select
                                                className="input-25"
                                                placeholder="Selecionar"
                                                name="sexo"
                                                allowClear={true}
                                                onChange={(sexo) => setFormData({ ...formData, sexo })}
                                                disabled={!permissao}
                                            >
                                                <Option value="MASCULINO">Masculino</Option>
                                                <Option value="FEMININO">Feminino</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Estado Civil">
                                        {getFieldDecorator("estadoCivil", {
                                            initialValue: formData.estadoCivil
                                        })(
                                            <Select
                                                className="input-50"
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={(estadoCivil) => setFormData({ ...formData, estadoCivil })}
                                                disabled={!permissao}
                                            >
                                                <Option key="Solteiro(a)">Solteiro(a)</Option>
                                                <Option key="Casado(a)">Casado(a)</Option>
                                                <Option key="Viúvo(a)">Viúvo(a)</Option>
                                                <Option key="Separado(a) Judicialmente">Separado(a) Judicialmente</Option>
                                                <Option key="Divorciado(a)">Divorciado(a)</Option>
                                                <Option key="Outro">Outro</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                Identificação / RG
                                                <Tooltip className="info-icon" title="Documento de Identificação, Passaporte, ...">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        {getFieldDecorator("cc", {
                                            initialValue: formData.cc
                                        })(<Input name="cc" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, cc: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                Validade
                                                <Tooltip className="info-icon" title="Validade do documento de identificação">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        {getFieldDecorator("dt_ccvalidade", {
                                            initialValue: formData.dt_ccvalidade
                                        })(
                                            <DatePicker
                                                className="input-25"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dt_ccvalidade"
                                                onChange={(dt_ccvalidade) => setFormData({ ...formData, dt_ccvalidade })}
                                                disabled={!permissao}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Dados Fiscais / CPF">
                                        {getFieldDecorator("nif", {
                                            initialValue: formData.nif,
                                            rules: [
                                                {
                                                    pattern: /^[0-9]*$/,
                                                    message: "CPF deve conter apenas numeros"
                                                },
                                                {
                                                    len: 11,
                                                    message: "CPF deve conter 11 caractereres"
                                                }
                                            ]
                                        })(
                                            <Input
                                                name="nif"
                                                disabled={!permissao}
                                                className="input-50"
                                                onChange={e => setFormData({ ...formData, nif: e.target.value })}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Código Postal / CEP">
                                        {getFieldDecorator("cep", {
                                            initialValue: formData.cep
                                        })(<Input name="cep" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, cep: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Localidade / Estado">
                                        {getFieldDecorator("localidade", {
                                            initialValue: formData.localidade
                                        })(<Input name="localidade" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, localidade: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Endereço">
                                        {getFieldDecorator("morada", {
                                            initialValue: formData.morada
                                        })(<TextArea name="morada" disabled={!permissao} onChange={(e) => setFormData({ ...formData, morada: e.target.value })} rows={4} style={{ height: 100 }} />)}
                                    </Form.Item>
                                    <Form.Item label="País">
                                        {getFieldDecorator("pais", {
                                            initialValue: formData.pais
                                        })(
                                            <Select
                                                className="input-25 selecionar-pais"
                                                showSearch
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={(pais) => setFormData({ ...formData, pais })}
                                                disabled={!permissao}
                                            >
                                                <Option value="África do Sul">África do Sul</Option>
                                                <Option value="Albânia">Albânia</Option>
                                                <Option value="Alemanha">Alemanha</Option>
                                                <Option value="Andorra">Andorra</Option>
                                                <Option value="Angola">Angola</Option>
                                                <Option value="Anguilla">Anguilla</Option>
                                                <Option value="Antigua">Antigua</Option>
                                                <Option value="Arábia Saudita">Arábia Saudita</Option>
                                                <Option value="Argentina">Argentina</Option>
                                                <Option value="Armênia">Armênia</Option>
                                                <Option value="Aruba">Aruba</Option>
                                                <Option value="Austrália">Austrália</Option>
                                                <Option value="Áustria">Áustria</Option>
                                                <Option value="Azerbaijão">Azerbaijão</Option>
                                                <Option value="Bahamas">Bahamas</Option>
                                                <Option value="Bahrein">Bahrein</Option>
                                                <Option value="Bangladesh">Bangladesh</Option>
                                                <Option value="Barbados">Barbados</Option>
                                                <Option value="Bélgica">Bélgica</Option>
                                                <Option value="Benin">Benin</Option>
                                                <Option value="Bermudas">Bermudas</Option>
                                                <Option value="Botsuana">Botsuana</Option>
                                                <Option value="Brasil" selected>
                                                    Brasil
                                                </Option>
                                                <Option value="Brunei">Brunei</Option>
                                                <Option value="Bulgária">Bulgária</Option>
                                                <Option value="Burkina Fasso">Burkina Fasso</Option>
                                                <Option value="botão">botão</Option>
                                                <Option value="Cabo Verde">Cabo Verde</Option>
                                                <Option value="Camarões">Camarões</Option>
                                                <Option value="Camboja">Camboja</Option>
                                                <Option value="Canadá">Canadá</Option>
                                                <Option value="Cazaquistão">Cazaquistão</Option>
                                                <Option value="Chade">Chade</Option>
                                                <Option value="Chile">Chile</Option>
                                                <Option value="China">China</Option>
                                                <Option value="Cidade do Vaticano">Cidade do Vaticano</Option>
                                                <Option value="Colômbia">Colômbia</Option>
                                                <Option value="Congo">Congo</Option>
                                                <Option value="Coréia do Sul">Coréia do Sul</Option>
                                                <Option value="Costa do Marfim">Costa do Marfim</Option>
                                                <Option value="Costa Rica">Costa Rica</Option>
                                                <Option value="Croácia">Croácia</Option>
                                                <Option value="Dinamarca">Dinamarca</Option>
                                                <Option value="Djibuti">Djibuti</Option>
                                                <Option value="Dominica">Dominica</Option>
                                                <Option value="EUA">EUA</Option>
                                                <Option value="Egito">Egito</Option>
                                                <Option value="El Salvador">El Salvador</Option>
                                                <Option value="Emirados Árabes">Emirados Árabes</Option>
                                                <Option value="Equador">Equador</Option>
                                                <Option value="Eritréia">Eritréia</Option>
                                                <Option value="Escócia">Escócia</Option>
                                                <Option value="Eslováquia">Eslováquia</Option>
                                                <Option value="Eslovênia">Eslovênia</Option>
                                                <Option value="Espanha">Espanha</Option>
                                                <Option value="Estônia">Estônia</Option>
                                                <Option value="Etiópia">Etiópia</Option>
                                                <Option value="Fiji">Fiji</Option>
                                                <Option value="Filipinas">Filipinas</Option>
                                                <Option value="Finlândia">Finlândia</Option>
                                                <Option value="França">França</Option>
                                                <Option value="Gabão">Gabão</Option>
                                                <Option value="Gâmbia">Gâmbia</Option>
                                                <Option value="Gana">Gana</Option>
                                                <Option value="Geórgia">Geórgia</Option>
                                                <Option value="Gibraltar">Gibraltar</Option>
                                                <Option value="Granada">Granada</Option>
                                                <Option value="Grécia">Grécia</Option>
                                                <Option value="Guadalupe">Guadalupe</Option>
                                                <Option value="Guam">Guam</Option>
                                                <Option value="Guatemala">Guatemala</Option>
                                                <Option value="Guiana">Guiana</Option>
                                                <Option value="Guiana Francesa">Guiana Francesa</Option>
                                                <Option value="Guiné-bissau">Guiné-bissau</Option>
                                                <Option value="Haiti">Haiti</Option>
                                                <Option value="Holanda">Holanda</Option>
                                                <Option value="Honduras">Honduras</Option>
                                                <Option value="Hong Kong">Hong Kong</Option>
                                                <Option value="Hungria">Hungria</Option>
                                                <Option value="Iêmen">Iêmen</Option>
                                                <Option value="Ilhas Cayman">Ilhas Cayman</Option>
                                                <Option value="Ilhas Cook">Ilhas Cook</Option>
                                                <Option value="Ilhas Curaçao">Ilhas Curaçao</Option>
                                                <Option value="Ilhas Marshall">Ilhas Marshall</Option>
                                                <Option value="Ilhas Turks & Caicos">Ilhas Turks & Caicos</Option>
                                                <Option value="Ilhas Virgens (brit.)">Ilhas Virgens (brit.)</Option>
                                                <Option value="Ilhas Virgens(amer.)">Ilhas Virgens(amer.)</Option>
                                                <Option value="Ilhas Wallis e Futuna">Ilhas Wallis e Futuna</Option>
                                                <Option value="Índia">Índia</Option>
                                                <Option value="Indonésia">Indonésia</Option>
                                                <Option value="Inglaterra">Inglaterra</Option>
                                                <Option value="Irlanda">Irlanda</Option>
                                                <Option value="Islândia">Islândia</Option>
                                                <Option value="Israel">Israel</Option>
                                                <Option value="Itália">Itália</Option>
                                                <Option value="Jamaica">Jamaica</Option>
                                                <Option value="Japão">Japão</Option>
                                                <Option value="Jordânia">Jordânia</Option>
                                                <Option value="Kuwait">Kuwait</Option>
                                                <Option value="Latvia">Latvia</Option>
                                                <Option value="Líbano">Líbano</Option>
                                                <Option value="Liechtenstein">Liechtenstein</Option>
                                                <Option value="Lituânia">Lituânia</Option>
                                                <Option value="Luxemburgo">Luxemburgo</Option>
                                                <Option value="Macau">Macau</Option>
                                                <Option value="Macedônia">Macedônia</Option>
                                                <Option value="Madagascar">Madagascar</Option>
                                                <Option value="Malásia">Malásia</Option>
                                                <Option value="Malaui">Malaui</Option>
                                                <Option value="Mali">Mali</Option>
                                                <Option value="Malta">Malta</Option>
                                                <Option value="Marrocos">Marrocos</Option>
                                                <Option value="Martinica">Martinica</Option>
                                                <Option value="Mauritânia">Mauritânia</Option>
                                                <Option value="Mauritius">Mauritius</Option>
                                                <Option value="México">México</Option>
                                                <Option value="Moldova">Moldova</Option>
                                                <Option value="Mônaco">Mônaco</Option>
                                                <Option value="Montserrat">Montserrat</Option>
                                                <Option value="Nepal">Nepal</Option>
                                                <Option value="Nicarágua">Nicarágua</Option>
                                                <Option value="Niger">Niger</Option>
                                                <Option value="Nigéria">Nigéria</Option>
                                                <Option value="Noruega">Noruega</Option>
                                                <Option value="Nova Caledônia">Nova Caledônia</Option>
                                                <Option value="Nova Zelândia">Nova Zelândia</Option>
                                                <Option value="Omã">Omã</Option>
                                                <Option value="Palau">Palau</Option>
                                                <Option value="Panamá">Panamá</Option>
                                                <Option value="Papua-nova Guiné">Papua-nova Guiné</Option>
                                                <Option value="Paquistão">Paquistão</Option>
                                                <Option value="Peru">Peru</Option>
                                                <Option value="Polinésia Francesa">Polinésia Francesa</Option>
                                                <Option value="Polônia">Polônia</Option>
                                                <Option value="Porto Rico">Porto Rico</Option>
                                                <Option value="Portugal">Portugal</Option>
                                                <Option value="Qatar">Qatar</Option>
                                                <Option value="Quênia">Quênia</Option>
                                                <Option value="Rep. Dominicana">Rep. Dominicana</Option>
                                                <Option value="Rep. Tcheca">Rep. Tcheca</Option>
                                                <Option value="Reunion">Reunion</Option>
                                                <Option value="Romênia">Romênia</Option>
                                                <Option value="Ruanda">Ruanda</Option>
                                                <Option value="Rússia">Rússia</Option>
                                                <Option value="Saipan">Saipan</Option>
                                                <Option value="Samoa Americana">Samoa Americana</Option>
                                                <Option value="Senegal">Senegal</Option>
                                                <Option value="Serra Leone">Serra Leone</Option>
                                                <Option value="Seychelles">Seychelles</Option>
                                                <Option value="Singapura">Singapura</Option>
                                                <Option value="Síria">Síria</Option>
                                                <Option value="Sri Lanka">Sri Lanka</Option>
                                                <Option value="St. Kitts & Nevis">St. Kitts & Nevis</Option>
                                                <Option value="St. Lúcia">St. Lúcia</Option>
                                                <Option value="St. Vincent">St. Vincent</Option>
                                                <Option value="Sudão">Sudão</Option>
                                                <Option value="Suécia">Suécia</Option>
                                                <Option value="Suiça">Suiça</Option>
                                                <Option value="Suriname">Suriname</Option>
                                                <Option value="Tailândia">Tailândia</Option>
                                                <Option value="Taiwan">Taiwan</Option>
                                                <Option value="Tanzânia">Tanzânia</Option>
                                                <Option value="Togo">Togo</Option>
                                                <Option value="Trinidad & Tobago">Trinidad & Tobago</Option>
                                                <Option value="Tunísia">Tunísia</Option>
                                                <Option value="Turquia">Turquia</Option>
                                                <Option value="Ucrânia">Ucrânia</Option>
                                                <Option value="Uganda">Uganda</Option>
                                                <Option value="Uruguai">Uruguai</Option>
                                                <Option value="Venezuela">Venezuela</Option>
                                                <Option value="Vietnã">Vietnã</Option>
                                                <Option value="Zaire">Zaire</Option>
                                                <Option value="Zâmbia">Zâmbia</Option>
                                                <Option value="Zimbábue">Zimbábue</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="bloco">
                                    <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Filiação</h3>
                                    <Form.Item label="Nome da Mãe">
                                        {getFieldDecorator("maeResponsavel", {
                                            initialValue: formData.maeResponsavel
                                        })(<Input name="maeResponsavel" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, maeResponsavel: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Dados Fiscais / CPF da Mãe">
                                        {getFieldDecorator("maeCpf", {
                                            initialValue: formData.maeCpf,
                                            rules: [
                                                {
                                                    pattern: /^[0-9]*$/,
                                                    message: "CPF deve conter apenas numeros"
                                                },
                                                {
                                                    len: 11,
                                                    message: "CPF deve conter 11 caractereres"
                                                }
                                            ]
                                        })(
                                            <Input
                                                name="maeContacto"
                                                className="input-50"
                                                disabled={!permissao}
                                                onChange={e => setFormData({ ...formData, maeCpf: e.target.value })}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Profissão da Mãe">
                                        {getFieldDecorator("maeProfissao", {
                                            initialValue: formData.maeProfissao
                                        })(<Input name="maeProfissao" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, maeProfissao: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="E-mail da Mãe">
                                        {getFieldDecorator("maeEmail", {
                                            rules: [
                                                {
                                                    type: "email",
                                                    message: "Introduza um endereço de e-mail válido"
                                                }
                                            ],
                                            initialValue: formData.maeEmail
                                        })(<Input name="maeEmail" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, maeEmail: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Telefone / Celular da Mãe">
                                        {getFieldDecorator("maeContacto", {
                                            initialValue: formData.maeContacto
                                        })(<Input name="maeContacto" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, maeContacto: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Nome do Pai">
                                        {getFieldDecorator("paiResponsavel", {
                                            initialValue: formData.paiResponsavel
                                        })(<Input name="paiResponsavel" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, paiResponsavel: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Dados Fiscais / CPF do Pai">
                                        {getFieldDecorator("paiCpf", {
                                            initialValue: formData.paiCpf,
                                            rules: [
                                                {
                                                    pattern: /^[0-9]*$/,
                                                    message: "CPF deve conter apenas numeros"
                                                },
                                                {
                                                    len: 11,
                                                    message: "CPF deve conter 11 caractereres"
                                                }
                                            ]
                                        })(
                                            <Input
                                                name="maeContacto"
                                                className="input-50"
                                                disabled={!permissao}
                                                onChange={e => setFormData({ ...formData, paiCpf: e.target.value })}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Profissão do Pai">
                                        {getFieldDecorator("paiProfissao", {
                                            initialValue: formData.paiProfissao
                                        })(<Input name="paiProfissao" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, paiProfissao: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="E-mail do Pai">
                                        {getFieldDecorator("paiEmail", {
                                            rules: [
                                                {
                                                    type: "email",
                                                    message: "Introduza um endereço de e-mail válido"
                                                }
                                            ],
                                            initialValue: formData.paiEmail
                                        })(<Input name="paiEmail" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, paiEmail: e.target.value })} />)}
                                    </Form.Item>
                                    <Form.Item label="Telefone / Celular do Pai">
                                        {getFieldDecorator("paiContacto", {
                                            initialValue: formData.paiContacto
                                        })(<Input name="paiContacto" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, paiContacto: e.target.value })} />)}
                                    </Form.Item>
                                </div>
                                {globalContext.responsaveis_entidade &&
                                    <div className="bloco">
                                        <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                            Responsável
                                            <Tooltip className="info-icon" title="Através do e-mail poderá ser contatado pela gestão ou professores dos cursos deste usuário">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </h3>
                                        <Form.Item label="Utilizar dados do Pai ou da Mãe">
                                            {getFieldDecorator("utilizarDados", {
                                                initialValue: formData.utilizarDados
                                            })(
                                                <Select
                                                    className="input-25"
                                                    placeholder="Selecionar"
                                                    name="utilizarDados"
                                                    allowClear={true}
                                                    onChange={(utilizarDados) => setFormData({ ...formData, utilizarDados })}
                                                    disabled={!permissao}
                                                >
                                                    <Option value="PAI">Pai</Option>
                                                    <Option value="MAE">Mãe</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                        {formData.utilizarDados ?
                                            <>
                                                <Form.Item label="Nome">
                                                    {getFieldDecorator("responsavelNome", {
                                                        initialValue: formData.utilizarDados === "PAI" ? formData.paiResponsavel : formData.maeResponsavel
                                                    })(<Input name="responsavelNome" className="input-50" disabled />)}
                                                </Form.Item>
                                                <Form.Item label="E-mail">
                                                    {getFieldDecorator("responsavelEmail", {
                                                        initialValue: formData.utilizarDados === "PAI" ? formData.paiEmail : formData.maeEmail
                                                    })(<Input name="responsavelEmail" className="input-50" disabled />)}
                                                </Form.Item>
                                                <Form.Item label="Telefone / Celular">
                                                    {getFieldDecorator("responsavelContacto", {
                                                        initialValue: formData.utilizarDados === "PAI" ? formData.paiContacto : formData.maeContacto
                                                    })(<Input name="responsavelContacto" className="input-50" disabled />)}
                                                </Form.Item>
                                            </>
                                            :
                                            <>
                                                <Form.Item label="Nome">
                                                    {getFieldDecorator("responsavelNome", {
                                                        initialValue: formData.responsavelNome
                                                    })(<Input name="responsavelNome" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, responsavelNome: e.target.value })} />)}
                                                </Form.Item>
                                                <Form.Item label="E-mail">
                                                    {getFieldDecorator("responsavelEmail", {
                                                        rules: [
                                                            {
                                                                type: "email",
                                                                message: "Introduza um endereço de e-mail válido"
                                                            }
                                                        ],
                                                        initialValue: formData.responsavelEmail
                                                    })(<Input name="responsavelEmail" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, responsavelEmail: e.target.value })} />)}
                                                </Form.Item>
                                                <Form.Item label="Telefone / Celular">
                                                    {getFieldDecorator("responsavelContacto", {
                                                        initialValue: formData.responsavelContacto
                                                    })(<Input name="responsavelContacto" className="input-50" disabled={!permissao} onChange={(e) => setFormData({ ...formData, responsavelContacto: e.target.value })} />)}
                                                </Form.Item>
                                            </>}
                                    </div>}
                                {permissao &&
                                    <>
                                        <button
                                            className="botao-principal"
                                            style={{ padding: "10px 30px" }}
                                            onClick={atualizarDados}
                                            disabled={loading}
                                        >
                                            {loading ? <Icon type="loading" /> : null}
                                        Salvar
                                        </button>
                                        <button className="botao-secundario" onClick={redirectUser}>
                                            Cancelar
                                        </button>
                                    </>
                                }
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const FormDadosGerais = Form.create({ name: "form-dados-gerais" })(DadosGerais);

export default FormDadosGerais;