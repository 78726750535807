import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, notification, Icon, Form, Dropdown, Switch, Modal, Tooltip, Select, Collapse } from "antd";
import Header from "../../User/Header";
import { FaClone } from "react-icons/fa";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import DrawerDiario from "./_Aux/DrawerDiario";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import {ArrowCircleLeft} from "iconsax-react";

const { Panel } = Collapse;
const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Diario extends Component {
    static contextType = GlobalContext;
    state = {
        escolaridades: [],
        //FILTROS
        pesquisa: "",
        ativo: true,
        //DRAWER
        escolaridadeId: 0,
        visible: false,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        }
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.total();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.total();
            }
        );
    };

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listar();
            }
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.total();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.total();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA EDIÇÃO NA TABELA

    montarMenu = escolaridade => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-enviar"
                        title="Duplicar"
                        onClick={event => {
                            event.stopPropagation();
                            this.duplicar(escolaridade.id);
                        }}
                    >
                        <FaClone />
                    </Link>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativar(escolaridade.id, escolaridade.ativo);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativar(escolaridade.id, escolaridade.ativo);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluir(escolaridade.id);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    //CARREGA O TOTAL DE FASES ESCOLARES
    total = () => {
        axios({
            method: "get",
            url: "/api/gestao-diario/total",
            params: {
                pesquisa: this.state.pesquisa,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;
                this.setState(
                    {
                        pagination: _pagination
                    },
                    () => this.listar()
                );
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA TODAS AS FASES ESCOLARES DA ENTIDADE
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-diario/listar",
                    params: {
                        pesquisa: this.state.pesquisa,
                        ativo: this.state.ativo,
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current
                    }
                })
                    .then(response => {
                        let escolaridades = [];

                        response.data.map((escolaridade, index) => {
                            //let avaliacoes = [];
                            //let avaliacao = "";

                            //if (escolaridade.formula === 0)
                            //    avaliacoes = escolaridade.avaliacoes.map(x => {
                            //        return x.nome;
                            //    });
                            //else
                            //    avaliacao =
                            //        escolaridade.formula === 1 ? (
                            //            <div>
                            //                <span style={{ marginRight: 5 }}>Fórmula Média Simples</span>
                            //                <Tooltip
                            //                    className="info-icon info-icon2"
                            //                    title="Calculo da média final utilizando a média aritmética simples"
                            //                >
                            //                    <Icon type="question-circle-o" />
                            //                </Tooltip>
                            //            </div>
                            //        ) : (
                            //            <div>
                            //                <span style={{ marginRight: 5 }}>Fórmula Média Ponderada</span>
                            //                <Tooltip
                            //                    className="info-icon info-icon2"
                            //                    title="Calculo da média final utilizando a média ponderada"
                            //                >
                            //                    <Icon type="question-circle-o" />
                            //                </Tooltip>
                            //            </div>
                            //        );

                            escolaridades.push({
                                key: index,
                                id: escolaridade.id,
                                escolaridade: `${escolaridade.nome}${escolaridade.bncc ? " (BNCC)" : ""}`,
                                periodo: escolaridade.periodo_letivo,
                                tipo: escolaridade.tipo,
                                listaAvaliacoes: escolaridade.avaliacoes,

                                opcoes: this.montarMenu(escolaridade)
                            });
                        });

                        this.setState({
                            escolaridades,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    duplicar = parametroId => {
        confirm({
            title: "Pretende duplicar esta estrutura de avaliação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "post",
                    url: "/api/gestao-diario/duplicar",
                    params: {
                        parametroId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Estrutura de avaliação duplicada!");
                        this.total();
                        this.listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível dupicar a estrutura de avaliação");
                    });
            }
        });
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    carregarDetalhe = escolaridade => {
        axios({
            method: "get",
            url: "/api/gestao-diario/detalhe",
            params: {
                id: escolaridade.id
            }
        })
            .then(response => {
                this.setState({
                    estado: "EDITAR",
                    escolaridade: response.data,
                    visible: true
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    carregarDetalheBncc = escolaridade => {
        axios({
            method: "get",
            url: "/api/gestao-diario/detalhe-bncc",
            params: {
                id: escolaridade.id
            }
        })
            .then(response => {
                this.setState({
                    estado: "EDITAR",
                    escolaridade: response.data.fase,
                    escolaridadeBncc: response.data.bncc,
                    visible: true
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //ATIVA E INATIVA A EDIÇÃO
    ativar = (id, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta estrutura de avaliação?" : "Pretende ativar esta estrutura de avaliação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-diario/ativar",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Estrutura de avaliação inativada!" : "Estrutura de avaliação ativada!");
                        this.total();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a estrutura de avaliação!" : "Não foi possível ativar a estrutura de avaliação!"
                        );
                    });
            }
        });
    };

    excluir = id => {
        confirm({
            title: "Pretende excluir esta estrutura de avaliação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-diario/excluir",
                    params: {
                        id
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Estrutura de avaliação excluida");
                        this.total();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir esta estrutura de avaliação!");
                    });
            }
        });
    };

    atualizarRegistos = () => {
        this.total();
        this.setState({
            estado: "",
            visible: false
        });
    };

    //ABRE O DRAWER VAZIO PARA CRIAR NOVO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            escolaridadeId: 0,
            visible: true
        });
    };

    //FECHA O DRAWER SEM GUARDAR A EDIÇÃO
    onClose = () => {
        this.setState({
            estado: "",
            visible: false
        });
    };

    onRowClick = escolaridadeId => {
        this.setState({
            escolaridadeId,
            visible: true
        });
    };

    render() {
        const {
            escolaridades,
            //DRAWER
            escolaridadeId,
            visible,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //PAGINATION
            pagination
        } = this.state;

        const columns = [
            {
                title: "Estrutura",
                dataIndex: "escolaridade"
            },
            {
                title: "Período letivo",
                dataIndex: "periodo"
            },
            {
                title: "Tipo",
                dataIndex: "tipo"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const columnsMobile = [
            {
                title: "Estrutura",
                dataIndex: "escolaridade",
                render: (text, record) => (
                    <div>
                        <div>{record.escolaridade}</div>
                        <div> 
                            <span>Tipo: </span>
                            <span style={{fontWeight:'bold'}}>{record.tipo}</span>
                        </div>
                        {/*<div>{record.turno}</div>*/}
                    </div>

                )
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Estruturas da avaliação" />
                <div className="container container-body">
                    <div className="bloco-cursos">
                        <div className="bloco-tabela">
                            <div className="filtros filtros-desktop">
                                <div className="bloco-pesquisa">
                                    <button
                                        style={{ paddingLeft: 11, paddingRight: 11 }}
                                        className="botao-principal  botao-principal-redondo"
                                        title="Adicionar"
                                        onClick={this.showDrawer}
                                        disabled={!this.state.ativo}
                                    >
                                        +
                                    </button>
                                    <BotaoProcurar
                                        pesquisa={this.state.pesquisa}
                                        tem_placeholder
                                        placeholder="Procurar por estrutura"
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                </div>
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />

                                <Link className='botao-voltar2'
                                      to='/utilizador'
                                      onClick={()=>{
                                          this.context.atualizarState({
                                              mostrarOptConfiguracoes: true
                                          })
                                      }}
                                >
                                    <ArrowCircleLeft size={34} />
                                </Link>
                            </div>

                            <div className="filtros filtros-mobile">
                                <div className="bloco-esquerda bloco-esquerda-cursos">
                                    <div
                                        className="controlos"
                                        style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}
                                    >
                                        <button
                                            style={{ paddingLeft: 11, paddingRight: 11 }}
                                            className="botao-principal  botao-principal-redondo"
                                            title="Adicionar"
                                            onClick={this.showDrawer}
                                        >
                                            +
                                        </button>
                                        <div className="filtros" style={{ paddingTop: 8 }}>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                                <Switch
                                                    checkedChildren="Ativos"
                                                    unCheckedChildren="Inativos"
                                                    defaultChecked
                                                    onChange={this.handleChangeSwitch}
                                                />
                                                <Link className='botao-voltar2'
                                                      to='/utilizador'
                                                      onClick={()=>{
                                                          this.context.atualizarState({
                                                              mostrarOptConfiguracoes: true
                                                          })
                                                      }}
                                                >
                                                    <ArrowCircleLeft size={34} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bloco-filtros">
                                        <BotaoProcurar
                                            pesquisa={this.state.pesquisa}
                                            tem_placeholder
                                            placeholder="Procurar por nome"
                                            HandleKeyPress={this.HandleKeyPress}
                                            resetCaixaProcura={this.resetCaixaProcura}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Table
                                id="tabela_categorias"
                                className="tabela-fase-escolar-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={escolaridades}
                                pagination={pagination}
                                onChange={this.handleChangeTable}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />

                            <Table
                                id="tabela_categorias"
                                className="tabela-fase-escolar-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={escolaridades}
                                pagination={pagination}
                                onChange={this.handleChangeTable}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerDiario
                    ativo={this.state.ativo}
                    atualizarRegistos={this.atualizarRegistos}
                    onClose={this.onClose}
                    visible={visible}
                    escolaridadeId={escolaridadeId}
                />
            </div>
        );
    }
}
const FormDiario = Form.create({ name: "form-diario" })(Diario);

export default FormDiario;
