import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table } from 'antd';
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";

const Tabela = ({ match }) => {
    const globalContext = useContext(GlobalContext);
    const [disciplinas, setDisciplinas] = useState([])
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [loading, setLoading] = useState(false);

    const cols = [
        {
            title: "Turma",
            dataIndex: "turma"
        },
        {
            title: "Disciplina",
            dataIndex: "disciplina"
        },
        {
            title: "Aulas Dadas",
            dataIndex: "aulas"
        },
        {
            title: "Assiduidade",
            dataIndex: "assiduidade"
        },
        {
            title: "Aproveitamento",
            dataIndex: "aproveitamento"
        },
        {
            title: "Resultado",
            dataIndex: "resultado"
        }
    ];

    const colsMobile = [
        {
            title: "Turma",
            dataIndex: "turma"
        },
        {
            title: "Disciplina",
            dataIndex: "disciplina"
        },
       
    ];

    const colsSubMobile = [
        {
            title: "",
            dataIndex: "assiduidade",
            render: (index,record) => (
                <div style={{display:"flex", flexDirection:'column'}}>
                    <span> Assiduidade: {record.assiduidade}</span>
                    <span> Aproveitamento: {record.aproveitamento}</span>
                    <span> Resultado: {record.resultado}</span>
                </div>
            ),
        },
    ];


    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-disciplinas",
            params: {
                code: match.params.code,
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`turma${match.params.code}`]?.escola.id
            }
        })
            .then(response => {
                var lista = response.data.map(disciplina => {
                    return {
                        key: disciplina.id,
                        turma: disciplina.turma,
                        disciplina: disciplina.nome,//`${disciplina.codigo} - ${disciplina.nome}`,
                        nome: disciplina.nome,
                        aulas: disciplina.aulas,
                        assiduidade: disciplina.assiduidade,
                        aproveitamento: "-",
                        resultado: "-",
                    }
                })
                setDisciplinas(lista)
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    const onRowClick = (cursoNome, cursoId) => {
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem("opcao_voltar", JSON.stringify({
            link_voltar: `/gestao-utilizadores-detalhe/${match.params.code}`
        }))

        const nomeCurso = cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase();
        setRedirectLink(`/gerir-detalhe-turma/${nomeCurso}/visao-geral-disciplina`);
        setRedirect(true);
    }

    useEffect(() => {
        if (globalContext[`edicao${match.params.code}`] || globalContext[`escola${match.params.code}`]) {
            carregar();
        }
    }, [
        globalContext[`edicao${match.params.code}`],
        globalContext[`escola${match.params.code}`]
    ])

    if (redirect)
        return <Redirect to={redirectLink} />;

    return (
        <>
            <div className='desktop-block'>
                <Table
                    className="tabela-user-disciplinas"
                    columns={cols}
                    pagination={false}
                    dataSource={disciplinas}
                    rowClassName="clickeble-row"
                    onRow={(record, index) => {
                        return {
                            onClick: () => onRowClick(record.nome, record.key)
                        };
                    }}
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading"/>
                            </div>
                        )
                    }}
                />
            </div>

            <div className="mobile">
                <Table
                    className="tabela-user-disciplinas"
                    columns={colsMobile}
                    pagination={false}
                    dataSource={disciplinas}
                    rowClassName="clickeble-row"
                    onRow={(record, index) => {
                        return {
                            onClick: () => onRowClick(record.nome, record.key),
                        };
                    }}
                    expandedRowRender={(record) => (
                        <Table
                            columns={colsSubMobile}
                            dataSource={[record]} // Passar apenas o registro da linha expandida
                            pagination={false}
                            showHeader={false} // Ocultar cabeçalho da subtabela
                            locale={{emptyText: "Não existem dados!"}}
                        />
                    )}
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading"/>
                            </div>
                        ),
                    }}
                />
            </div>

        </>

    )
        ;
}

export default withRouter(Tabela);