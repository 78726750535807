import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Drawer, Form, Modal, Upload, notification, Radio, DatePicker, Tooltip, Icon, Checkbox} from "antd";
import axios from "axios";
import moment from "moment";

import ModalLoading from "../../../../Componentes/Modals/ModalLoading";
import ModalViewer from "../../../../Componentes/Modals/ModalViewer";

const confirm = Modal.confirm;
const Dragger = Upload.Dragger;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerAta = ({ visible, form, onClose, onConfirm, turmasMultisseriado }) => {
    const [dataEmissao, setDataEmissao] = useState(moment());
    //const [turmasMultisseriado, setTurmasMultisseriado] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([Number(localStorage.getItem("codigo_curso"))]);
    const [checkAll, setCheckAll] = useState(false)


    const { getFieldDecorator } = form;

    const handleChangeDataEmissao = (date) => {
        setDataEmissao(date);
    };
    
    const onCheckAllChange = () => {
        const newCheckAll = !checkAll; // Inverte o estado atual
        setCheckAll(newCheckAll); // Atualiza o estado de checkAll

        if (newCheckAll) {
            // Seleciona todas as opções
            const opt = turmasMultisseriado.map(opcao => opcao.id); // Extrai os IDs
            setSelectedOptions(opt); // Atualiza o estado das opções selecionadas
            localStorage.setItem('selectedOptions', JSON.stringify(opt)); // Salva no localStorage
        } else {
            // Limpa todas as seleções
            setSelectedOptions([]);
            localStorage.setItem('selectedOptions', JSON.stringify([]));
        }
    }

    const afterVisibleChange = visible => {
        if (!visible) {
            form.resetFields();
            setDataEmissao(moment());
            setSelectedOptions(undefined);
        }
        else {
            setSelectedOptions([Number(localStorage.getItem("codigo_curso"))]);
            localStorage.setItem('selectedOptions', JSON.stringify([Number(localStorage.getItem("codigo_curso"))]));
        }
    }

    return (
        <>
            <Drawer
                className="drawer-inscrever"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <Form className="form-emitir-declaracao">
                        <Form.Item label="Data de emissão">
                            {getFieldDecorator("dataEmissao", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: dataEmissao
                            })(
                                <DatePicker
                                    name="dataEmissao"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={handleChangeDataEmissao}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </div>

                <Checkbox
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    style={{margin:'10px 0'}}
                >
                    Selecionar Todos
                </Checkbox>
                
                <Checkbox.Group
                    options={turmasMultisseriado}
                    value={selectedOptions} // Estado que controla as seleções
                    onChange={checkedValues => {
                        setSelectedOptions(checkedValues);
                        localStorage.setItem('selectedOptions', JSON.stringify(checkedValues)); 
                    }}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                </Checkbox.Group>
                
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    <button className="botao-principal" disabled={!dataEmissao} type="primary" onClick={() => onConfirm(dataEmissao)}>
                        Visualizar
                    </button>
                </div>
            </Drawer>
        </>
    );
}

const FormDrawerAta = Form.create({ name: "drawer-ata" })(DrawerAta);

export default FormDrawerAta;
