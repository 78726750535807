export const validarExistemNotas = (avaliacoes, entidadeId) => {
    let validacao = true;
    let count = 0
    
    if(entidadeId != 3 ){
        console.log('teste')
        avaliacoes.forEach((avaliacao) => {
            if ((avaliacao.nota === '' || avaliacao.nota === null) && (avaliacao.observacoes !== '' && avaliacao.observacoes !== null)) {
                validacao = false;
            }
        });
    }
    
    avaliacoes.forEach((avaliacao) => {
        if ((avaliacao.nota === '' || avaliacao.nota === null) && (avaliacao.observacoes === '' || avaliacao.observacoes == null)) {
            count += 1
        }
    });

    if(count == avaliacoes.length){
        validacao = false;
    }
    return validacao;
};



export const validarExistemObs = (avaliacoes) => avaliacoes.some(avaliacao => avaliacao.observacoes?.trim());