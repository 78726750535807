import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Switch, Menu, Modal, Tooltip } from "antd";
import Header from "../../User/Header";
import noimage from "../../images/noimage.png";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import { GlobalContext } from "../../GlobalState";
import Search from "../../Geral/_Aux/BotaoProcurar";
import DrawerCalendarioEscolar from "./_Aux/DrawerCalendarioEscolar";
import moment from "moment";

import ModalLoading from "../../Componentes/Modals/ModalLoading";
import ModalViewer from "../../Componentes/Modals/ModalViewer";
import {ArrowCircleLeft} from "iconsax-react";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};


class CalendarioEscolar extends Component {
    static contextType = GlobalContext;
    state = {
        calendarios: [],
        //FILTROS
        pesquisa: "",
        ativo: true,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER
        calendarioId: 0,

        visible: false,
        //MODAL LOADING
        visibleLoading: false,
        //MODAL VIEWER
        visibleViewer: false,
        docs: []
    };
   
    
    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }
    
    

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa
                },
                () => {
                    this.listar();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA CATEGORIA NA TABELA
    montarMenuDesktop = (calendarioId, code, ativo) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-detalhe"
                        title="Visualizar"
                        onClick={event => {
                            event.stopPropagation();
                            this.gerarDoc(calendarioId);
                        }}
                    >
                        <Icon type="eye" />
                    </Link>
                    <Link
                        to={`/gestao-calendarios-escolares-detalhe/${code}`}
                        className="botao-icon-enviar"
                        title="Detalhe"
                        onClick={event => event.stopPropagation()}
                    >
                        <Icon type="eye" />
                    </Link>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativar(calendarioId, ativo);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativar(calendarioId, ativo);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluir(calendarioId);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA CATEGORIA NA TABELA
    montarMenuMobile = (calendarioId, code, ativo) => {
        if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item key="1" >
                        <Link
                            to="#"
                            title="Visualizar"
                            onClick={event => {
                                event.stopPropagation();
                                this.gerarDoc(calendarioId);
                            }}
                        >
                             Visualizar
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2" >
                        <Link
                            to={`/gestao-calendarios-escolares-detalhe/${code}`}
                            title="Detalhe"
                            onClick={event => {
                                event.stopPropagation();
                                // event.preventDefault();
                            }}
                        >
                             Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Link
                            to="#"
                            // className="botao-icon-inativar"
                            title="Inativar"
                            onClick={event => {
                                event.stopPropagation();
                                // event.preventDefault();
                                this.ativar(calendarioId, ativo);
                            }}
                        >
                             Inativar
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }

        
        return (
            <Menu >
                <Menu.Item key="1" >
                    <Link
                        to="#"
                        // className="botao-icon-ativar"
                        title="Ativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativar(calendarioId, ativo);
                        }}
                    >
                         Ativar
                    </Link>
                </Menu.Item>
                <Menu.Item key="2" >
                    <Link
                        to="#"
                        className="botao-icon-excluir"
                        title="Excluir"
                        onClick={event => {
                            event.stopPropagation();
                            this.excluir(calendarioId);
                        }}
                    >
                         Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };


    //CARREGA TODAS AS CATEGORIA DA ENTIDADE PARA A TABELA
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-calendario",
                    params: {
                        pesquisa: this.state.pesquisa,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var calendarios = [];

                        response.data.map((calendario, index) => {
                            
                            calendarios.push({
                                key: index,
                                id: calendario.id,
                                estabelecimentos: calendario.estabelecimentos,
                                edicao: calendario.edicao,
                                nome: calendario.nome,
                                tipo: calendario.tipo,
                                datas: `${moment(calendario.inicio).format("DD/MM/YYYY")} - ${moment(calendario.termino).format(
                                    "DD/MM/YYYY"
                                )}`,
                                opcoes:
                                <>
                                    <div className="tabela-calendario-escolar-desktop">
                                        {this.montarMenuDesktop(calendario.id, calendario.code, calendario.ativo)}
                                    </div>
                                    <div className='tabela-calendario-escolar-mobile'>
                                        <Dropdown
                                            overlay={this.montarMenuMobile(calendario.id, calendario.code, calendario.ativo)}
                                            trigger={['click']}
                                            placement="bottomLeft"
                                        >
                                            <Link
                                                to="#"
                                                className="botao-icon-configs"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                }}
                                            >
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </div>
                                </>

                        });
                        });




                        this.setState({
                            calendarios,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    excluir = calendarioId => {
        confirm({
            title: "Pretende excluir este calendário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-calendario/excluir",
                    params: {
                        calendarioId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Calendário excluido!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                    });
            }
        });
    };

    ativar = (calendarioId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este calendário?" : "Pretende ativar este calendário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-calendario/ativar",
                    params: {
                        calendarioId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Calendário inativado!" : "Calendário ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o calendário!" : "Não foi possível ativar o calendário!"
                        );
                    });
            }
        });
    };

    showDrawer = () => {
        this.setState({
            calendarioId: 0,
            visible: true
        });
    };

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    gerarDoc = calendarioId => {
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pdf/calendario-escolar",
                    params: {
                        calendarioId
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(error => {
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    };

    onRowClick = calendarioId => {
        this.setState({
            calendarioId,
            visible: true
        });
    };

    render() {
        const {
            calendarios,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER
            calendarioId,

            visible,
            //MODAL LOADING
            visibleLoading,
            //MODAL VIEWER
            visibleViewer,
            docs
        } = this.state;

        const columns = [
            {
                title: "Edição",
                dataIndex: "edicao"
            },
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "Periodos letivos",
                dataIndex: "tipo"
            },
            {
                title: "Inicio / Término",
                dataIndex: "datas"
            },
            {
                title: "Estabelecimentos",
                dataIndex: "estabelecimentos"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const columnsMobile = [
            // {
            //     title: "Edição",
            //     dataIndex: "edicao"
            // },
            {
                title: "Nome",
                dataIndex: "nome"
            },
           
            {
                title: "Inicio / Término",
                dataIndex: "datas"
            },
           
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

       
        return (
            <div>
                <Header titulo="Calendários" />
                <div className="container container-body">
                    <div className="bloco-cursos">
                        <div className="bloco-tabela">
                            <div className="filtros filtros-desktop" style={{ justifyContent: "space-between" }}>
                                <div className="bloco-pesquisa">
                                    <button
                                        style={{ paddingRight: 11, paddingLeft: 11 }}
                                        className="botao-principal botao-principal-redondo"
                                        title="Adicionar"
                                        onClick={this.showDrawer}
                                        disabled={!this.state.ativo}
                                    >
                                        +
                                    </button>
                                    <Search
                                        tem_placeholder
                                        placeholder="Procurar por nome"
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                </div>
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                                <Link className='botao-voltar2'
                                      to='/utilizador'
                                      onClick={()=>{
                                          this.context.atualizarState({
                                              mostrarOptConfiguracoes: true
                                          })
                                      }}
                                >
                                    <ArrowCircleLeft size={34}/>
                                </Link>
                            </div>

                            <div className="filtros filtros-mobile">
                                <div className="bloco-esquerda bloco-esquerda-cursos">
                                    <div
                                        className="controlos"
                                        style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}
                                    >
                                        <button
                                            style={{ paddingRight: 11, paddingLeft: 11 }}
                                            className="botao-principal botao-principal-redondo"
                                            title="Adicionar"
                                            onClick={this.showDrawer}
                                        >
                                            +
                                        </button>
                                        <div className="filtros" style={{ paddingTop: 8 }}>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                                <Switch
                                                    checkedChildren="Ativos"
                                                    unCheckedChildren="Inativos"
                                                    defaultChecked
                                                    onChange={this.handleChangeSwitch}
                                                />
                                                <Link className='botao-voltar2'
                                                      to='/utilizador'
                                                      onClick={()=>{
                                                          this.context.atualizarState({
                                                              mostrarOptConfiguracoes: true
                                                          })
                                                      }}
                                                >
                                                    <ArrowCircleLeft size={34}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bloco-filtros">
                                        <Search
                                            tem_placeholder
                                            placeholder="Procurar por nome"
                                            HandleKeyPress={this.HandleKeyPress}
                                            resetCaixaProcura={this.resetCaixaProcura}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <Table
                                id="tabela_calendarios"
                                className='tabela-calendario-escolar-desktop'
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={calendarios}
                                pagination={false}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                            
                            <Table
                                id="tabela_calendarios"
                                className='tabela-calendario-escolar-mobile'
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={calendarios}
                                pagination={false}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                
                <DrawerCalendarioEscolar
                    ativo={this.state.ativo}
                    calendarioId={calendarioId}
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    atualizar={() => {
                        this.setState({ visible: false });
                        this.listar();
                    }}
                />
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => this.setState({ visibleViewer: false })} />
            </div>
        );
    }
}
export default CalendarioEscolar;
