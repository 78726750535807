import React, { useState } from "react";
import { Drawer, Form, notification, DatePicker, Icon } from "antd";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const EditarHistorico = ({ visible, registoId, dataRegisto, form, onClose, atualizar }) => {
    const [data, setData] = useState(null);
    const [iconLoading, setIconLoading] = useState(false);

    const { getFieldDecorator } = form;

    const guardar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);
                var item = new FormData();
                item.append("id", registoId);
                item.append("data", moment(data).format("YYYY-MM-DD"));

                axios({
                    method: "post",
                    url: "/api/gestao-turma-matriculas/atualizar-registo-historico",
                    data: item
                })
                    .then(response => {
                        atualizar();
                        setIconLoading(false);
                        openNotificationWithIcon("success", "Sucesso", response.data);
                    })
                    .catch(error => {
                        setIconLoading(false);
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        })
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible) {
            setData(moment(dataRegisto));
        }
        else {
            setData(null);
        }
    }

    return (
        <Drawer
            className="drawer-inscrever drawer-editar-matricula"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-drawer-associar">
                <Form className="form-emitir-declaracao">
                    <div className="bloco">
                        <Form.Item label="Data">
                            {getFieldDecorator("data", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: data
                            })(
                                <DatePicker
                                    name="data"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={(data) => setData(data)}
                                />
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" disabled={iconLoading} type="primary" onClick={guardar}>
                    {iconLoading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
        </Drawer>
    );
}

const FormEditarHistorico = Form.create({ name: "drawer-editar-historico" })(EditarHistorico);

export default FormEditarHistorico;
