import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Switch, notification } from 'antd';
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";

import UserInfo from "../UserInfo/UserInfo";
import Tabela from "./Tabela";
import ModalGerarDocumento from "../../../Componentes/Modals/GerarDocumento";
import ModalLoading from "../../../Componentes/Modals/ModalLoading";
import ModalViewer from "../../../Componentes/Modals/ModalViewer";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Assiduidade = ({ menuOptionSelected, match }) => {
    const globalContext = useContext(GlobalContext);
    const [vista, setVista] = useState(true)
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [docs, setDocs] = useState([]);

    const gerarDoc = () => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/dados-gerais-assiduidade",
            params: {
                code: match.params.code,
                turmaId: globalContext[`turma${match.params.code}`]?.id,
                escolaId: globalContext[`turma${match.params.code}`]?.escola.id
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true)
                setDocs([{ uri: response.data }])
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                setVisibleLoading(false);
            });
    }

    const exportarExcel = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/excel/faltas-alunos",
            params: {
                code: match.params.code
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }

    return (
        <>
            <div className="page-container">
                <UserInfo
                    gestao
                />
                <div className="user-info-detail hidden">
                    <div className='mobile'>
                        <div className="page-info">
                        <span>
                            <h1 className="page-title" style={{margin: "20px 20px 10px 20px"}}>Assiduidade</h1>
                        </span>
                        <span className="page-controls">
                            <span><span style={{marginRight: 10}}>Vista Horas</span><Switch checked={vista}
                                                                                            onChange={vista => setVista(vista)}/></span>
                            <div className='opcoes'>
                                <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar"
                                      onClick={gerarDoc}>
                                <Icon type="eye"/>
                                </Link>
                                <Link className="botao-icon-download botao-maior" to="#" title="Descarregar"
                                      onClick={exportarExcel}>
                                    <Icon type="download"/>
                                </Link>
                            </div>
                            
                        </span>
                        </div>
                    </div>
                    <div className='desktop'>
                        <div className="page-info">
                        <span>
                            <h1 className="page-title" style={{margin: "20px 20px 10px 20px"}}>Assiduidade</h1>
                        </span>
                            <span className="page-controls">
                            <span><span style={{marginRight: 10}}>Vista Horas</span><Switch checked={vista}
                                                                                            onChange={vista => setVista(vista)}/></span>
                           
                                <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar"
                                      onClick={gerarDoc}>
                                <Icon type="eye"/>
                                </Link>
                                <Link className="botao-icon-download botao-maior" to="#" title="Descarregar"
                                      onClick={exportarExcel}>
                                    <Icon type="download"/>
                                </Link>
                            
                            
                        </span>
                        </div>
                    </div>
                    <div className="block-table hidden">
                        <Tabela vista={vista}/>
                    </div>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExport}
                    ficheiroExportar={exportFile}
                    btnDescarregar={buttonDownload}
                    onClose={() => setVisibleExport(false)}
                />
                <ModalLoading
                    visible={visibleLoading}
                    text="A gerar documento..."
                />
                <ModalViewer
                    visible={visibleViewer}
                    docs={docs}
                    onCancel={() => setVisibleViewer(false)}
                />
            </div>
        </>
    );
}

export default withRouter(Assiduidade);