import React, {useContext, useEffect, useState} from "react";
import DadosUtilizador from "./Componentes/DadosUtilizador";
import BotaoOpcao from './Componentes/BotaoOpcao';
import BotaoOpcaoPrev from './Componentes/BotaoOpcaoPrev';
import BotaoOpcaoIcon from './Componentes/BotaoOpcaoIcon';
import BotaoOpcaoIconPP from "./Componentes/BotaoOpcaoIconPP";
import BotaoTurma from './Componentes/BotaoTurma';
import {
    ArchiveTick, Bill,
    Chart, Chart1,
    Check, Clipboard, Clock,
    DocumentText,
    DocumentText1,
    Gallery, MenuBoard,
    Message,
    Messages,
    Profile2User, Setting3,
    Video, VideoTime, Watch, Calendar, ClipboardText, StatusUp, Element4, ArrowCircleLeft
} from 'iconsax-react';

//CONTEXT
import { GlobalContext } from "../GlobalState";

import DropdownEstabelecimentos from "./Componentes/DropdownEstabelecimentos";
import DropdownPerfis from "./Componentes/DropdownPerfis";
import axios from "axios";
import NomeNumeroUsuario from "./Componentes/NomeNumeroUsuario";
import Mensagem from "../Tickets/Detalhe/Mensagem";

import noImage from '../images/noimage.png'
import config from '../images/icons/config.png'
import turmas from '../images/icons/turma.png'
import dadosGerais from '../images/icons/gerais.png'
import drive from '../images/icons/drive.png'
import play from '../images/icons/play.png'
import ProfileIcon from "./Componentes/IconProfile";


export default function UserInfo (){
    const globalContext = useContext(GlobalContext);
    
    const [isLoading, setIsLoading] = useState(true);
    const [perfis, setPerfis] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [acessos, setAcessos] = useState([]);
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [code, setCode] = useState(null);
    const [redirectLink, setRedirectLink] = useState("");

    const nomeProf = globalContext.nome_utilizador;
    const numero = globalContext.numero_utilizador;
    
    const onChangeEstabelecimento = (estabelecimento) => {
        localStorage.setItem("escola_selecionada", JSON.stringify(estabelecimento));
        globalContext.atualizarState({
            estabelecimento,
            filtroEstabelecimentosUtilizado: true
        });
        carregarPerfis(estabelecimento.id);
    };

    const onChangePerfil = (perfil) => {
        localStorage.setItem("perfil_selecionado", JSON.stringify(perfil));
        globalContext.atualizarState({ perfil });
        carregarAcessos(perfil.id);
        
        globalContext.atualizarState({
            mostrarOptConfiguracoes:false,
            mostrarOptPaginasPublicas:false
        })
    };

    const carregarPerfis = (entidadeId) => {
        axios
            .get("/api/navbar/listar-perfis-utilizador", { params: { entidadeId } })
            .then((response) => {
                setPerfis(response.data);
                
                const perfil = localStorage.getItem("perfil_selecionado")
                    ? JSON.parse(localStorage.getItem("perfil_selecionado"))
                    : response.data.find((x) => x.principal) || response.data[0];

                localStorage.setItem("perfil_selecionado", JSON.stringify(perfil));

                globalContext.atualizarState({ perfil });
                globalContext.atualizarState({perfis:response.data})
                carregarAcessos(perfil.id);
                
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    setRedirect(true);
                }
            });
    };
    

    const carregarAcessos = (perfilId) => {
        axios
            .get("/api/permissoes/carregar-acessos", { params: { perfilId } })
            .then((response) => {
                setAcessos(response.data);
                globalContext.atualizarState({ 
                    acessos: response.data,
                    utilizadorCarregado:true
                });
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    setRedirect(true);
                }
            });
    };

    const carregarUtilizadorInfo = () => {
        axios({
            method: "get",
            url: "/api/navbar/dados-utilizador",
            timeout: 60 * 10 * 1000 // 10 minutos
        })
            .then(response => {
                const dados = response.data;

                globalContext.atualizarState({
                    id_utilizador: dados.id,
                    foto_utilizador: dados.foto,
                    avatar_utilizador: dados.avatar,
                    nome_utilizador: dados.nome_completo,
                    numero_utilizador: dados.numero,
                    sexo_utilizador: dados.sexo,
                    estabelecimento: dados.estabelecimento,
                    perfil: dados.perfil
                });

                carregarEstabelecimentos();

                // Define o código no estado local
                setCode(dados.code);
            })
            .catch(error => {
                // Verifica se error.response está definido antes de acessar o status
                if (error.response && error.response.status === 401) {
                    setRedirect(true);
                    setRedirectLink("/login");
                } else {
                    console.error("Erro ao carregar dados do utilizador:", error);
                }
            });
    };
    
    const carregarEstabelecimentos = () => {
        axios
            .get("/api/navbar/listar-organismos-utilizador")
            .then((response) => {

                const dadosEstabelecimentos = response.data;
                setEstabelecimentos(dadosEstabelecimentos);
                globalContext.atualizarState({ estabelecimentos: dadosEstabelecimentos });

                const estabelecimentoSelecionado = localStorage.getItem("escola_selecionada")
                    ? JSON.parse(localStorage.getItem("escola_selecionada"))
                    : dadosEstabelecimentos.find((x) => x.principal) || dadosEstabelecimentos[0];

                localStorage.setItem("escola_selecionada", JSON.stringify(estabelecimentoSelecionado));

                globalContext.atualizarState({ estabelecimento: estabelecimentoSelecionado });

                carregarPerfis(estabelecimentoSelecionado.id);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    setRedirect(true);
                }
            });
    };

    useEffect(() => {
        
            if(!globalContext.utilizadorCarregado){
                carregarUtilizadorInfo()
            }else{
                setEstabelecimentos(globalContext.estabelecimentos)
                setPerfis(globalContext.perfis)
            }
            
            
    }, []);
    

    const buildMenu = () => {
        if (globalContext.perfil?.nome === "Professor" ) {
            return (
                <>
                    <BotaoOpcao
                        link="utilizador-turmas"
                        imagem={turmas}
                        nome="Todas as Turmas"
                        estilo="icons-opcoes turmas"
                    />
                    <BotaoOpcao
                        link="utilizador-dados-gerais"
                        imagem={dadosGerais}
                        nome="Dados Gerais"
                        estilo="icons-opcoes dados-gerais"
                    />
                    <BotaoOpcao
                        imagem={drive}
                        nome="Drive"
                        estilo="icons-opcoes drive"
                        link="utilizador-drive"
                    />
                    <BotaoOpcao
                        imagem={play}
                        nome="Stepmeet"
                        estilo="icons-opcoes stepmeet"
                        link="/gestao-videoconferencias"
                    />
                    <BotaoOpcao
                        imagem={config}
                        nome="Configurações"
                        estilo="icons-opcoes configuracoes"
                        link="/user-configuracoes"
                    />
                </>
            );
        }

        if (globalContext.perfil?.nome === "Coordenador") {
            return (
                <>
                    <BotaoOpcao
                        link="utilizador-turmas"
                        imagem={turmas}
                        nome="Todas as Turmas"
                        estilo="icons-opcoes turmas"
                    />
                    <BotaoOpcao
                        link="utilizador-dados-gerais"
                        imagem={dadosGerais}
                        nome="Dados Gerais"
                        estilo="icons-opcoes dados-gerais"
                    />
                    <BotaoOpcao
                        imagem={drive}
                        nome="Drive"
                        estilo="icons-opcoes drive"
                        link="utilizador-drive"
                    />
                    <BotaoOpcao
                        imagem={play}
                        nome="Stepmeet"
                        estilo="icons-opcoes stepmeet"
                        link="/gestao-videoconferencias"
                    />
                    <BotaoOpcao
                        imagem={config}
                        nome="Configurações"
                        estilo="icons-opcoes configuracoes"
                        link="/user-configuracoes"
                    />
                    <BotaoOpcaoIcon
                        link="#"
                        icon={<Element4 size='34' color='#51A100'/>}
                        nome="Página Pública"
                        estilo="icons-opcoes paginas-publicas"
                        onClick={()=> globalContext.atualizarState({mostrarOptPaginasPublicas:true})}
                    />
                    <BotaoOpcaoIcon
                        link="gestao-utilizadores-todos"
                        icon={<Profile2User size="32" color="#2121F5"/>}
                        nome="Usuários"
                        estilo="icons-opcoes usuarios"
                        onClick={() =>
                            globalContext.atualizarState({
                                pesquisaInfo: null
                            })
                        }
                    />


                </>
            );
        }
        
        if( globalContext.perfil?.nome ==='Administrativo' || globalContext.perfil?.nome === 'Diretor' ){
            return(
                <>
                    <BotaoOpcao
                        link="gestao-turmas"
                        imagem={turmas}
                        nome="Todas as Turmas"
                        estilo="icons-opcoes turmas"
                        onClick={() => {
                            localStorage.removeItem("opcao_voltar");
                            localStorage.removeItem("temp_opcao_voltar");
                            localStorage.removeItem("filtroEdicao");
                            localStorage.removeItem("filtroCategoria");
                        }}
                    />

                    <BotaoOpcao
                        imagem={play}
                        nome="Stepmeet"
                        estilo="icons-opcoes stepmeet"
                        link="/gestao-videoconferencias"
                    />

                    <BotaoOpcaoIcon
                        link="#"
                        icon={<Element4 size='34' color='#51A100'/>}
                        nome="Página Pública"
                        estilo="icons-opcoes paginas-publicas"
                        onClick={()=> globalContext.atualizarState({mostrarOptPaginasPublicas:true})}
                    />

                    <BotaoOpcaoIcon
                        link="gestao-utilizadores-todos"
                        icon={<Profile2User size="32" color="#2121F5"/>}
                        nome="Usuários"
                        estilo="icons-opcoes usuarios"
                        onClick={() =>
                            globalContext.atualizarState({
                                pesquisaInfo: null
                            })
                        }
                    />

                </>
                
            )
        }

        
        if (globalContext.perfil?.nome === "Suporte") {
            
            return (
                <>
                    <BotaoOpcaoPrev
                        link="gestao-turmas"
                        imagem={turmas}
                        nome="Todas as Turmas"
                        estilo="icons-opcoes turmas"
                        onClick={() => {
                            localStorage.removeItem("opcao_voltar");
                            localStorage.removeItem("temp_opcao_voltar");
                            localStorage.removeItem("filtroEdicao");
                            localStorage.removeItem("filtroCategoria");
                        }}
                    />
                    <BotaoOpcaoIcon
                        link="#"
                        icon={<Element4 size='34' color='#51A100'/>}
                        nome="Página Pública"
                        estilo="icons-opcoes paginas-publicas"
                        onClick={()=> globalContext.atualizarState({mostrarOptPaginasPublicas:true})}
                    />
                    <BotaoOpcao
                        imagem={play}
                        nome="Stepmeet"
                        estilo="icons-opcoes stepmeet"
                        link="/gestao-videoconferencias"
                    />
                    <BotaoOpcaoIcon
                        icon={<StatusUp size='34' color='#F13F07'/>}
                        nome="Dashboard"
                        estilo="icons-opcoes dashboard-icon"
                        link="/gestao-dashboard"
                    />
                    <BotaoOpcao
                        link="#"
                        imagem={config}
                        nome="Configurações"
                        estilo="icons-opcoes configuracoes"
                        onClick={()=> globalContext.atualizarState({mostrarOptConfiguracoes:true})}
                    />

                    <BotaoOpcaoIcon
                        link="gestao-utilizadores-todos"
                        icon={<Profile2User size="32" color="#2121F5"/>}
                        nome="Usuários"
                        estilo="icons-opcoes usuarios"
                        onClick={() =>
                            globalContext.atualizarState({
                                pesquisaInfo: null
                            })
                        }
                    />
                </>
            );
        }

        if (globalContext.perfil?.nome === "Gestor") {

            return (
                <>
                    <BotaoOpcaoPrev
                        link="gestao-turmas"
                        imagem={turmas}
                        nome="Todas as Turmas"
                        estilo="icons-opcoes turmas"
                        onClick={() => {
                            localStorage.removeItem("opcao_voltar");
                            localStorage.removeItem("temp_opcao_voltar");
                            localStorage.removeItem("filtroEdicao");
                            localStorage.removeItem("filtroCategoria");
                        }}
                    />
                    <BotaoOpcaoIcon
                        link="#"
                        icon={<Element4 size='34' color='#51A100'/>}
                        nome="Página Pública"
                        estilo="icons-opcoes paginas-publicas"
                        onClick={()=> globalContext.atualizarState({mostrarOptPaginasPublicas:true})}
                    />
                    <BotaoOpcao
                        imagem={play}
                        nome="Stepmeet"
                        estilo="icons-opcoes stepmeet"
                        link="/gestao-videoconferencias"
                    />
                    <BotaoOpcaoIcon
                        icon={<StatusUp size='34' color='#F13F07'/>}
                        nome="Dashboard"
                        estilo="icons-opcoes dashboard-icon"
                        link="/gestao-dashboard"
                    />

                    <BotaoOpcaoIcon
                        link="gestao-utilizadores-todos"
                        icon={<Profile2User size="32" color="#2121F5"/>}
                        nome="Usuários"
                        estilo="icons-opcoes usuarios"
                        onClick={() =>
                            globalContext.atualizarState({
                                pesquisaInfo: null
                            })
                        }
                    />
                    
                </>
            );
        }
    };

    const menuPrincipalComTurmas = (
        <div className='grid-container-opcoes'>
            

            {((JSON.parse(localStorage.getItem('ultimas_turmas') && globalContext.perfil?.id === 6)) ) &&
                (
                    <>
                        <div></div>
                        <div className='turmas-acessadas'>
                            Turmas mais acessadas:
                        </div>
                    </>

                )
            }

            <div className='opcoes-user'>
                {/*<div></div>*/}
                {/*<div></div>*/}

                {buildMenu()}
            </div>
            <div className="grid-container-opt-turmas">
                {((localStorage.getItem('ultimas_turmas') && globalContext.perfil?.id === 6)  && 
                    JSON.parse(localStorage.getItem('ultimas_turmas')).map((turma) => (
                        <BotaoTurma key={turma.id} turma={turma}/>
                    ))
                )}
            </div>
            

        </div>
    )

    const menuPaginasPublicas = () => {

        if (globalContext.perfil?.nome === "Suporte") {
            return (
                <div className='grid-conteiner-opcoes-menu'>
                    {/*<BotaoOpcaoIconPP*/}
                    {/*    link="gestao-utilizadores-todos"*/}
                    {/*    icon={<Profile2User size="32" color="#51A100"/>}*/}
                    {/*    nome="Usuários"*/}
                    {/*    estilo="icons-opcoes pag-pub-mobile paginas-publicas"*/}
                    {/*    onClick={() =>*/}
                    {/*        globalContext.atualizarState({*/}
                    {/*            pesquisaInfo: null*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*/>*/}
                    <BotaoOpcaoIconPP
                        icon={<Chart size="32" color="#51A100"/>}
                        nome="Categorias Notícias"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-categoria-noticias"
                    />
                    <BotaoOpcaoIconPP
                        icon={<Check size="32" color="#51A100"/>}
                        nome="Carrossel"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-carrossel"
                    />
                    <BotaoOpcaoIconPP
                        icon={<Gallery size="32" color="#51A100"/>}
                        nome="Galeria de fotos"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-fotos"
                    />

                    <BotaoOpcaoIconPP
                        icon={<DocumentText size="32" color="#51A100"/>}
                        nome="Notícias"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-noticias"
                    />

                    <BotaoOpcaoIconPP
                        icon={<DocumentText1 size="32" color="#51A100"/>}
                        nome="Páginas"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-paginas"
                    />

                    <BotaoOpcaoIconPP
                        icon={<Message size="32" color="#51A100"/>}
                        nome="Comentários"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-comentario"
                    />
                    <BotaoOpcaoIconPP
                        icon={<Video size="32" color="#51A100"/>}
                        nome="Vídeos"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-videos"
                    />
                    <BotaoOpcaoIconPP
                        icon={<Setting3 size="32" color="#51A100"/>}
                        nome="Configurações"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-conta"
                    />
                    <BotaoOpcaoIconPP
                        icon={<Messages size="32" color="#51A100"/>}
                        nome="FAQ"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-faq"
                    />
                    <BotaoOpcaoIconPP
                        icon={<Clipboard size="32" color="#51A100"/>}
                        nome="Estabelecimentos"
                        estilo="icons-opcoes pag-pub-mobile paginas-publicas"
                        link="/gestao-unidades-organizacionais"
                    />

                </div>
            );
        }
    
        if (globalContext.perfil?.nome === "Gestor") {
            return (
                <div className='grid-conteiner-opcoes-menu'>
                    <BotaoOpcaoIcon
                        link="gestao-utilizadores-todos"
                        icon={<Profile2User size="32" color="#2121F5"/>}
                        nome="Usuários"
                        estilo="icons-opcoes usuarios"
                        onClick={() =>
                            globalContext.atualizarState({
                                pesquisaInfo: null
                            })
                        }
                    />
                    <BotaoOpcaoIcon
                        icon={<Chart size="32" color="#51A100"/>}
                        nome="Categorias Notícias"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-categoria-noticias"
                    />
                    <BotaoOpcaoIcon
                        icon={<Check size="32" color="#51A100"/>}
                        nome="Carrosel"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-carrossel"
                    />
                    <BotaoOpcaoIcon
                        icon={<Gallery size="32" color="#51A100"/>}
                        nome="Galeria de fotos"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-fotos"
                    />

                    <BotaoOpcaoIcon
                        icon={<DocumentText size="32" color="#51A100"/>}
                        nome="Notícias"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-noticias"
                    />

                    <BotaoOpcaoIcon
                        icon={<DocumentText1 size="32" color="#51A100"/>}
                        nome="Páginas"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-paginas"
                    />

                    <BotaoOpcaoIcon
                        icon={<Message size="32" color="#51A100"/>}
                        nome="Comentários"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-comentario"
                    />
                    <BotaoOpcaoIcon
                        icon={<Video size="32" color="#51A100"/>}
                        nome="Vídeos"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-videos"
                    />
                    <BotaoOpcaoIcon
                        icon={<Setting3 size="32" color="#51A100"/>}
                        nome="Configurações"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-conta"
                    />
                    <BotaoOpcaoIcon
                        icon={<Messages size="32" color="#51A100"/>}
                        nome="FAQ"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-faq"
                    />
                    <BotaoOpcaoIcon
                        icon={<Clipboard size="32" color="#51A100"/>}
                        nome="Estabelecimentos"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-unidades-organizacionais"
                    />

                </div>
            )
        }

        if (globalContext.perfil?.nome ==='Administrativo' || globalContext.perfil?.nome === 'Diretor') {
            return (
                <div className='grid-conteiner-opcoes-menu'>
                    <BotaoOpcaoIcon
                        link="gestao-utilizadores-todos"
                        icon={<Profile2User size="32" color="#2121F5"/>}
                        nome="Usuários"
                        estilo="icons-opcoes usuarios"
                        onClick={() =>
                            globalContext.atualizarState({
                                pesquisaInfo: null
                            })
                        }
                    />
                    <BotaoOpcaoIcon
                        icon={<Chart size="32" color="#51A100"/>}
                        nome="Categorias Notícias"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-categoria-noticias"
                    />
                    
                    <BotaoOpcaoIcon
                        icon={<DocumentText size="32" color="#51A100"/>}
                        nome="Notícias"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-noticias"
                    />
                </div>
            )
        }

        if (globalContext.perfil?.nome ==='Coordenador' ) {
            return (
                <div className='grid-conteiner-opcoes-menu'>
                    <BotaoOpcaoIcon
                        link="gestao-utilizadores-todos"
                        icon={<Profile2User size="32" color="#2121F5"/>}
                        nome="Usuários"
                        estilo="icons-opcoes usuarios"
                        onClick={() =>
                            globalContext.atualizarState({
                                pesquisaInfo: null
                            })
                        }
                    />
                    <BotaoOpcaoIcon
                        icon={<Chart size="32" color="#51A100"/>}
                        nome="Categorias Notícias"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-categoria-noticias"
                    />

                    <BotaoOpcaoIcon
                        icon={<DocumentText size="32" color="#51A100"/>}
                        nome="Notícias"
                        estilo="icons-opcoes paginas-publicas"
                        link="/gestao-noticias"
                    />
                </div>
            )
        }
    };
    


    const menuConfiguracoes = (
        <div className='grid-conteiner-opcoes-menu'>
            <BotaoOpcaoIcon
                link="/gestao-templates-turmas"
                icon={<Bill size="32" color="#000000"/>}
                nome="Planos de Estudos"
                estilo="icons-opcoes menu-conf"
            />
            <BotaoOpcaoIcon
                icon={<ClipboardText size="32" color="#000000"/>}
                nome="Fichas"
                estilo="icons-opcoes menu-conf"
                link="/gestao-fichas"
            />
            <BotaoOpcaoIcon
                icon={<Chart1 size="32" color="#000000"/>}
                nome="Níveis de ensino"
                estilo="icons-opcoes menu-conf"
                link="/gestao-turmas-categorias"
            />
            <BotaoOpcaoIcon
                icon={<ArchiveTick size="32" color="#000000"/>}
                nome="Avaliação"
                estilo="icons-opcoes menu-conf"
                link="/gestao-diario"
            />

            <BotaoOpcaoIcon
                icon={<Calendar size="32" color="#000000"/>}
                nome="Calendários"
                estilo="icons-opcoes menu-conf"
                link="/gestao-calendarios-escolares"
            />

            <BotaoOpcaoIcon
                icon={<VideoTime size="32" color="#000000"/>}
                nome="Edições"
                estilo="icons-opcoes menu-conf"
                link="/gestao-turmas-edicoes"
            />

            <BotaoOpcaoIcon
                icon={<MenuBoard size="32" color="#000000"/>}
                nome="Anos Letivos"
                estilo="icons-opcoes menu-conf"
                link="/gestao-turmas-anos-letivos"
            />
            <BotaoOpcaoIcon
                icon={<Watch size="32" color="#000000"/>}
                nome="Turnos"
                estilo="icons-opcoes menu-conf"
                link="/gestao-turmas-turnos"
            />
            <BotaoOpcaoIcon
                icon={<Clock size="32" color="#000000"/>}
                nome="Tipos de horas"
                estilo="icons-opcoes menu-conf"
                link="/gestao-turmas-tipo-horas"
            />


        </div>

    )
    const shouldAddMargin = () => {

        if (globalContext.perfil?.id !== 6) return true

        if (globalContext.perfil?.id === 6 && localStorage.getItem('ultimas_turmas') === null) return true

        return false

    };

    const builPage = () => {
       
        if (globalContext.mostrarOptPaginasPublicas) {
            return menuPaginasPublicas();
        }

        if (globalContext.mostrarOptConfiguracoes) {
            return menuConfiguracoes;
        }
        // Caso nenhuma condição seja verdadeira, retornar menuPrincipalComTurmas
        return menuPrincipalComTurmas;
    };
    
  
    
    return(
        <div className='details-page-prof-cord esconder-drop'>
            <div className='hidden-mobile'>

                <div className={`info-user `}>

                    <div className='dropdown-grupo'>
                        <DropdownPerfis
                            perfis={perfis}
                            onPerfilChange={onChangePerfil}
                        >
                        </DropdownPerfis>

                        <DropdownEstabelecimentos
                            onEstabelecimentoChange={onChangeEstabelecimento}
                            estabelecimentos={estabelecimentos}
                        >
                        </DropdownEstabelecimentos>

                    </div>
                </div>

            </div>

            <div className='hidden-mobile'>
                <div className="voltar-mobile">
                    {(globalContext.mostrarOptConfiguracoes || globalContext.mostrarOptPaginasPublicas) &&
                        <button className='botao-voltar2'
                                onClick={() => globalContext.atualizarState({
                                    mostrarOptConfiguracoes: false,
                                    mostrarOptPaginasPublicas: false
                                })}
                        >
                            <ArrowCircleLeft size='34'/>
                        </button>
                    }
                </div>
            </div>

            <div
                className={`imagem-perfil `}
            >
               
                {globalContext.foto_utilizador ? (
                    <img src={globalContext.foto_utilizador}/>
                ):(
                    nomeProf !== '' && (
                        <ProfileIcon name={nomeProf}/>
                    )
                )}

            </div>

            <div className='corpo'>

                <div className='hidden-desktop'>
                    <div className={`info-user ${shouldAddMargin() ? "margin50" : ""}`}
                    >
                        <NomeNumeroUsuario nome={nomeProf}
                                           numero={numero}
                                           mostraBotao={globalContext.mostrarOptPaginasPublicas || globalContext.mostrarOptConfiguracoes}
                                           onClick={() => {

                                               globalContext.atualizarState({
                                                   mostrarOptConfiguracoes: false,
                                                   mostrarOptPaginasPublicas: false
                                               })

                                           }}
                        ></NomeNumeroUsuario>
                        <div className='dropdown-grupo'>

                            <DropdownEstabelecimentos
                                onEstabelecimentoChange={onChangeEstabelecimento}
                                estabelecimentos={estabelecimentos}
                            >
                            </DropdownEstabelecimentos>
                            <DropdownPerfis
                                perfis={perfis}
                                onPerfilChange={onChangePerfil}
                            >
                            </DropdownPerfis>

                        </div>
                    </div>
                </div>

                <div className='hidden-mobile'>
                    <div className='info-user'>
                        <NomeNumeroUsuario
                            nome={nomeProf}
                            numero={numero}
                        >

                        </NomeNumeroUsuario>
                    </div>
                </div>

                {builPage()}

            </div>

        </div>
    )
}