import React, {useState, useEffect, useContext} from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../../User/Header";
import { Table, Modal, notification, Switch, Icon } from "antd";
import DrawerCarrosel from "./Drawer/DrawerCarrosel";
import axios from "axios";
import {ArrowCircleLeft} from "iconsax-react";
import { GlobalContext } from "../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Carrossel = () => {
    const [ativo, setAtivo] = useState(true);
    const [imagens, setImagens] = useState([]);
    const [imagemId, setImagemId] = useState(0);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const globalContext = useContext(GlobalContext);

    const listar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/carrocel/listar",
            params: {
                ativo
            }
        })
            .then(response => {
                const lista = response.data.map(img => {
                    return {
                        key: img.id,
                        id: img.id,
                        nome: img.nome,
                        estabelecimentos: img.estabelecimentos.join(", "),
                        opcoes: montarMenu(img.id)
                    }
                });

                setImagens(lista);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    };

    const montarMenu = id => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            ativar(id);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        ativar(id);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        excluir(id);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    const excluir = id => {
        confirm({
            title: "Pretende excluir esta imagem?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "patch",
                    url: "/api/carrocel/excluir",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Imagem excluida!");
                        listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a imagem!");
                    });
            }
        });
    };

    const ativar = id => {
        confirm({
            title: ativo ? "Pretende inativar esta imagem?" : "Pretende ativar esta imagem?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "patch",
                    url: "/api/carrocel/toggle-ativo",
                    params: {
                        id,
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Imagem inativada!" : "Imagem ativada!");
                        listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a imagem!" : "Não foi possível ativar a imagem!"
                        );
                    });
            }
        });
    };

    const onRowClick = id => {
        setVisible(true);
        setImagemId(id);
    };

    useEffect(() => {
        listar(true);
    }, [ativo]);

    const columns = [
        {
            title: "Nome",
            dataIndex: "nome"
        },
        {
            title: "Estabelecimentos",
            dataIndex: "estabelecimentos"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const columnsMobile = [
        {
            title: "Nome",
            dataIndex: "nome"
        },
        {
            title: "Estabelecimentos",
            dataIndex: "estabelecimentos"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    return (
        <>
            <Header titulo="Carrossel" />
            <div className="administracao-empresa">
                <div className="container container-body">
                    <div style={{ minHeight: 600, background: "white" }}>
                        <div className="bloco-minha-conta">
                            <div className="bloco-gestor-paginas">
                                <div className="noticias container-body">
                                    <div className="controlos" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <button
                                            className="botao-principal botao-principal-redondo"
                                            title="Adicionar"
                                            style={{ marginTop: 0 }}
                                            onClick={() => {
                                                setVisible(true);
                                                setImagemId(0);
                                            }}
                                            disabled={!ativo}
                                        >
                                            +
                                        </button>
                                        <div className="filtro">
                                            <Switch
                                                checkedChildren="Ativos"
                                                unCheckedChildren="Inativos"
                                                defaultChecked
                                                onChange={ativo => setAtivo(ativo)}
                                            />
                                            
                                            <Link className='botao-voltar2'
                                                  to='/utilizador'
                                                  onClick={()=>{
                                                      globalContext.atualizarState({
                                                          mostrarOptPaginasPublicas: true
                                                      })
                                                  }}
                                            >
                                                <ArrowCircleLeft size={34}/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="bloco-tabela">
                                        <Table
                                            id="tabela_categorias"
                                            className="tabela-paginas-desktop"
                                            loading={{
                                                spinning: loading,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columns}
                                            dataSource={imagens}
                                            pagination={false}
                                            rowClassName="clickeble-row"
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => onRowClick(record.id)
                                                };
                                            }}
                                            locale={{ emptyText: "Não existem dados!" }}
                                        />
                                        <Table
                                            id="tabela_categorias"
                                            className="tabela-paginas-mobile"
                                            loading={{
                                                spinning: loading,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columnsMobile}
                                            dataSource={imagens}
                                            pagination={false}
                                            rowClassName="clickeble-row"
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => onRowClick(record.id)
                                                };
                                            }}
                                            locale={{ emptyText: "Não existem dados!" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DrawerCarrosel
                ativo={ativo}
                id={imagemId}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => {
                    setVisible(false);
                    listar();
                }}
            />
        </>
    );
};

export default Carrossel;
