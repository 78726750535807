import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification } from 'antd';
import UserInfo from "../UserInfo/UserInfo";
import Tabela from "./Tabela";
import ModalLoading from "../../../Componentes/Modals/ModalLoading";
import ModalViewer from "../../../Componentes/Modals/ModalViewer";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Educacao = ({ menuOptionSelected, match }) => {
    const globalContext = useContext(GlobalContext);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [docs, setDocs] = useState([]);

    const gerarDoc = () => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/dados-gerais-educacao",
            params: {
                code: match.params.code,
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`turma${match.params.code}`]?.escola.id
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true)
                setDocs([{ uri: response.data }])
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                setVisibleLoading(false);
            });
    }

    return (
        <>
            <div className="page-container">
                <UserInfo
                    gestao
                />
                <div className="user-info-detail hidden">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{ margin: "20px 20px 10px 20px" }}>Disciplinas</h1>
                        </div>
                    </div>
                    <div className="block-table">
                        <Tabela />
                    </div>
                </div>
                <ModalLoading
                    visible={visibleLoading}
                    text="A gerar documento..."
                />
                <ModalViewer
                    visible={visibleViewer}
                    docs={docs}
                    onCancel={() => setVisibleViewer(false)}
                />
            </div>
        </>
    );
}

export default withRouter(Educacao);