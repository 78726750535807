import React, { Component } from "react";
import { Button, Icon, notification, Modal, Select, Table } from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AntdTableScrollXaxisTop from "antd-table-scroll-xaxis-top";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ExcelTabelaConteudos extends Component {
    state = {
        disciplinaId: localStorage.getItem("vista") === "TURMA" ? undefined : localStorage.getItem("codigo_curso"),
        //FILTROS
        disciplinas: [],
        disciplinasId: [],
        disciplinaNome: "",
        isSelected: false,
        participantes: [],
        opcao: "",
        num: 1,
        total: 0,
        semPeriodos: false,
        periodos: [],
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        mes: "",
        ano: "",
        titulo: "",
        aulas: [],
        //LOADING
        iconLoading: false,
        //TITULOS
        tituloConteudos: "",
        tituloHabilidades: "",
        tituloMetodologia: "",
        columns: [
            {
                title: "Ordem numérica",
                dataIndex: "ordem",
                key: "ordem",
                align: "center"
            },
            {
                title: "Nomes dos alunos",
                dataIndex: "nome",
                key: "nome",
                align: "center"
            }
        ]
    };

    componentDidMount() {
        if (localStorage.getItem("vista") === "TURMA") this.listarDisciplinas();

        this.total();
        this.titulosDetalhe();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState(
                {
                    disciplinaId: localStorage.getItem("vista") === "TURMA" ? undefined : localStorage.getItem("codigo_curso")
                },
                () => {
                    if (localStorage.getItem("vista") === "TURMA") this.listarDisciplinas(true);

                    this.total();
                    this.titulosDetalhe();
                    this.listar();
                }
            );
        }
    }

    selectDiscipline = opcao => {
        this.setState({ isSelected: true });
        this.handleSelect(opcao);
    };

    handleSelect = opcao => {
        let disciplinaId = opcao;

        if (disciplinaId) {
            this.props.setDisciplinaId(disciplinaId);
            this.setState(
                {
                    disciplinaId,
                    disciplinaNome: this.state.disciplinas.find(f => f.id === disciplinaId)?.nome
                },
                () => this.listar(true)
            );
        } else {
            this.props.setDisciplinaId(undefined);
            this.setState({
                participantes: [],
                columns: [
                    {
                        title: "Ordem numérica",
                        dataIndex: "ordem",
                        key: "ordem",
                        align: "center"
                    },
                    {
                        title: "Nomes dos alunos",
                        dataIndex: "nome",
                        key: "nome",
                        align: "center"
                    }
                ]
            });
        }
    };

    total = () => {
        axios({
            method: "get",
            url: "/api/aulas/total-periodos-conteudos-alunos",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState(
                        {
                            total: response.data.length,
                            periodos: response.data,
                            titulo: response.data[0].titulo,
                            dt_inicio: response.data[0].dt_inicio,
                            dt_fim: response.data[0].dt_fim
                        },
                        () => {
                            if (!localStorage.getItem("vista")) this.listar();

                            this.props.carregarPeriodoId(response.data[0].id);
                        }
                    );
                } else {
                    this.setState({
                        semPeriodos: true
                    });
                    openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                }
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    listar = () => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/participantes-alunos",
                    timeout: 60 * 10 * 1000,
                    params: {
                        cursoid: this.state.disciplinaId == "Todos" ? localStorage.getItem("codigo_curso") : this.state.disciplinaId, //localStorage.getItem("codigo_curso"),
                        dt_inicio: this.state.dt_inicio,
                        dt_fim: this.state.dt_fim
                    }
                })
                    .then(response => {
                        const disciplinas = response.data[0]?.disciplinas || [];

                        const baseColumns = [
                            {
                                title: "Ordem numérica",
                                dataIndex: "ordem",
                                key: "ordem",
                                align: "center"
                            },
                            {
                                title: "Nomes dos alunos",
                                dataIndex: "nome",
                                key: "nome",
                                align: "center"
                            }
                        ];

                        const dynamicColumns = window.innerWidth > 500 ? baseColumns.map(col => ({ ...col, fixed: "left" })) : baseColumns;

                        disciplinas.forEach(disciplina => {
                            disciplina.avaliacoes.forEach((avaliacao, index) => {
                                dynamicColumns.push({
                                    title: `${avaliacao.nome}`,
                                    dataIndex: `${disciplina.nome}_${avaliacao.nome}_${index}`,
                                    key: `${disciplina.nome}_${avaliacao.nome}_${index}`,
                                    align: "center"
                                });
                            });
                        });

                        dynamicColumns.push({
                            title: "Estado",
                            dataIndex: "estado",
                            key: "estado",
                            align: "center"
                        });

                        const participantes = response.data.map((participante, index) => {
                            const row = {
                                key: participante.id,
                                nome: participante.nome,
                                ordem: ("0" + (index + 1)).slice(-2)
                            };

                            let lastNota = null;
                            participante.disciplinas.forEach(disciplina => {
                                disciplina.avaliacoes.forEach((avaliacao, index) => {
                                    row[`${disciplina.nome}_${avaliacao.nome}_${index}`] = (
                                        <div
                                            className={
                                                avaliacao.nome === "Média Bimestral" || avaliacao.nome === "Média Anual"
                                                    ? avaliacao.nota.replace(",", ".") >= 6
                                                        ? "marca-aluno-aprovado"
                                                        : "marca-aluno-reprovado"
                                                    : ""
                                            }
                                        >
                                            {avaliacao.nota === "" ? "-" : avaliacao.nota}
                                        </div>
                                    );
                                    if (avaliacao.nota) {
                                        lastNota = avaliacao.nota;
                                    }
                                });
                            });

                            // Assigning the last evaluation note to the "Estado" column
                            row.estado =
                                lastNota.replace(",", ".") >= 6 ? (
                                    <div className="marca-aluno-aprovado">Aprovado</div>
                                ) : (
                                    <div className="marca-aluno-reprovado">Reprovado</div>
                                );

                            return row;
                        });

                        this.setState({
                            participantes,
                            columns: dynamicColumns,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                        }
                    });
            }
        );
    };

    listarDisciplinas = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-turma-disciplinas",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                let disciplinaId = undefined;

                if (this.state.disciplinaNome) {
                    const disciplinaNomeFormatado = this.state.disciplinaNome.toLowerCase().trim();
                    disciplinaId = response.data.find(disc => disc.nome.toLowerCase().trim() === disciplinaNomeFormatado)?.id;
                }

                this.setState(
                    {
                        disciplinaId,
                        disciplinas: response.data,
                        iconLoading: false,
                        disciplinasId: response.data.map(e => e.id)
                    },
                    () => {
                        if (this.state.disciplinaId !== undefined) {
                            this.listar(true);
                            this.props.setDisciplinaId(this.state.disciplinaId);
                        }
                    }
                );
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as disciplinas!");
            });
    };

    titulosDetalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/titulos-conteudo",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    tituloConteudos: response.data.conteudos,
                    tituloHabilidades: response.data.habilidades,
                    tituloMetodologia: response.data.metodologias
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    anteriorProximo = num => {
        let periodo = this.state.periodos[num - 1];

        this.setState(
            {
                num,
                titulo: periodo.titulo,
                dt_inicio: periodo.dt_inicio,
                dt_fim: periodo.dt_fim
            },
            () => {
                this.listar();
                this.props.carregarPeriodoId(periodo.id);
            }
        );
    };

    proximaDisciplina = disciplinaId => {
        const disciplinas = this.state.disciplinas.map(e => e.id);

        let nextValue = disciplinas[disciplinas.indexOf(disciplinaId) + 1];

        this.handleSelect(nextValue);
        this.anteriorProximo(1);
    };

    anteriorDisciplina = disciplinaId => {
        const disciplinas = this.state.disciplinas.map(e => e.id);

        let periodosLength = this.state.periodos.length;

        let previousValue = disciplinas[disciplinas.indexOf(disciplinaId) - 1];

        this.handleSelect(previousValue);
        this.anteriorProximo(periodosLength);
    };

    render() {
        const { disciplinaId, disciplinas, isSelected, num, semPeriodos, participantes, periodos, titulo, iconLoading, columns } =
            this.state;

        const totalColumns = columns.length;
        const newColumns = columns.map((col, index) => {
            const isExcluded = index < 2 || index >= totalColumns - 2;
            return {
                ...col,
                className: !isExcluded ? (index % 2 === 0 ? "odd-column" : "even-column") : ""
            };
        });

        return (
            <>
                <div>
                    <div className="bloco-controlos-frequencia">
                        <div className="filters">
                            <div>
                                {localStorage.getItem("vista") === "TURMA" && (
                                    <Select
                                        className="filter"
                                        placeholder="Selecionar Disciplina"
                                        name="disciplinas"
                                        allowClear={true}
                                        onChange={this.selectDiscipline}
                                        disabled={disciplinas.length === 0}
                                    >
                                        {disciplinas.map(disciplina => (
                                            <Option key={disciplina.id} value={disciplina.id}>
                                                {disciplina.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </div>
                            {!semPeriodos && (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {isSelected === false && typeof disciplinaId === "number" ? (
                                        <>
                                            <Button
                                                className="botao-periodo botao-periodo-anterior"
                                                disabled={num === 1}
                                                style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                                onClick={() => {
                                                    this.anteriorProximo(num - 1);
                                                }}
                                            >
                                                <Icon type="left" />
                                            </Button>
                                            <span>{titulo}</span>
                                            <Button
                                                className="botao-periodo botao-periodo-proximo"
                                                disabled={
                                                    num === periodos.length || (localStorage.getItem("vista") === "TURMA" && !disciplinaId)
                                                }
                                                style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                                onClick={() => {
                                                    this.anteriorProximo(num + 1);
                                                }}
                                            >
                                                <Icon type="right" />
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                className="botao-periodo botao-periodo-anterior"
                                                disabled={num === 1}
                                                style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                                onClick={() => this.anteriorProximo(num - 1)}
                                            >
                                                <Icon type="left" />
                                            </Button>
                                            <span>{titulo}</span>
                                            <Button
                                                className="botao-periodo botao-periodo-proximo"
                                                disabled={
                                                    num === periodos.length || (localStorage.getItem("vista") === "TURMA" && !disciplinaId)
                                                }
                                                style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                                onClick={() => this.anteriorProximo(num + 1)}
                                            >
                                                <Icon type="right" />
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="bloco-diario-observacoes">
                        <AntdTableScrollXaxisTop>
                            <Table
                                className="tabela-notas-diario"
                                columns={newColumns}
                                dataSource={participantes}
                                bordered
                                pagination={false}
                                scroll={{ x: "max-content" }}
                            />
                        </AntdTableScrollXaxisTop>
                    </div>
                </div>
                <Modal visible={iconLoading} maskClosable={false} className="modal-loading" footer={null} closable={false}>
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(ExcelTabelaConteudos);
