export const cancelLink = (perfilId) => {
    let link = "";

    if (perfilId == 1) //SUPORTE
        link = "/utilizador";
    else if (link == 2) //GESTOR
        link = "/utilizador";
    else if (perfilId == 3) //ADMINISTRATIVO
        link = "/utilizador";
    else if (perfilId == 4) //DIRETOR
        link = "/utilizador";
    else //ALUNO, PROFESSOR E COORDENADOR
        link = "/utilizador";

    return link;
}