import React, { Component } from "react";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import { Dropdown, notification, Icon, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import {ArrowCircleLeft} from "iconsax-react";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class HeaderColaboradores extends Component {
    static contextType = GlobalContext;
    state = {
        perfis: [],
        colaboradores: [],
        anos: [],
        ano: "",
        listaAssociados: [],
        iconLoading: false
    };

    reenviarTodos = () => {
        this.setState({ iconLoading: true });

        axios({
            method: "put",
            url: "/api/colaboradores/reenviar-todos"
        })
            .then(() => {
                this.props.obterDados();
                openNotificationWithIcon("success", "Sucesso", "Pedidos de registo reenviados!");
                this.setState({ iconLoading: false });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível reenviar pedidos de registo!");
                this.setState({ iconLoading: false });
            });
    };

    render() {
        const { iconLoading } = this.state;
        const {
            pesquisa,
            perfilId,
            estabelecimentoId,
            acesso,
            escolas,
        } = this.props;

        const funcionalidadesPerfis = [120, 121, 122, 123, 124, 125, 126];
        const acessoPerfis = this.context.acessos?.filter(x => funcionalidadesPerfis.includes(x.func))?.map(x => x.func);
        let perfis = [
            {
                value: 1,
                nome: "Suporte",
                func: 120
            },
            {
                value: 2,
                nome: "Gestor",
                func: 121
            },
            {
                value: 3,
                nome: "Administrativo",
                func: 122
            },
            {
                value: 4,
                nome: "Diretor",
                func: 123
            },
            {
                value: 5,
                nome: "Coordenador",
                func: 124
            },
            {
                value: 6,
                nome: "Professor",
                func: 125
            },
            {
                value: 7,
                nome: "Aluno",
                func: 126
            }
        ]

        if (acessoPerfis)
            perfis = perfis.filter(x => acessoPerfis.includes(x.func))?.map(x => x);

        return (
            <div className="header-colaboradores "
            >
                {this.props.filtros && (
                    <div className="filtros-topo">
                        <div className='esconder '>
                            <div className='hidden-mobile'>
                                <Link className='botao-voltar2'
                                      to='/utilizador'
                                      onClick={() => {
                                          
                                          this.context.atualizarState({
                                              mostrarOptPaginasPublicas: true
                                          })
                                      }}
                                >
                                    <ArrowCircleLeft size={34}/>
                                </Link>
                            </div>

                        </div>
                        <div className="botao-procurar">
                            <BotaoProcurar
                                pesquisa={pesquisa}
                                HandleKeyPress={this.props.HandleKeyPress}
                                resetCaixaProcura={this.props.resetCaixaProcura}
                            />
                        </div>
                        <div className="select-filtro">
                            <Select
                                placeholder="Perfis"
                                value={perfilId}
                                disabled={!this.props.tem_filtro}
                                name="filtroPerfis"
                                allowClear={true}
                                onChange={this.props.filtrar}
                            >
                                {perfis.map(perfil => (
                                    <Option key={perfil.value} value={perfil.value}>{perfil.nome}</Option>
                                ))}
                            </Select>
                        </div>
                        {escolas && escolas.length > 1 && (
                            <div className="select-filtro">
                                <Select
                                    placeholder="Estabelecimentos"
                                    value={estabelecimentoId}
                                    name="filtroEscolas"
                                    allowClear={true}
                                    onChange={this.props.filtrarOrganismo}
                                >
                                    {escolas.map(escola => (
                                        <Option key={escola.id} value={escola.id}>
                                            {escola.abreviatura}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                        <Switch
                            checkedChildren={!this.props.switch_valido ? "Ativos" : "Válidos"}
                            unCheckedChildren={!this.props.switch_valido ? "Inativos" : "Inválidos"}
                            defaultChecked
                            disabled={!this.props.tem_switch}
                            onChange={this.props.handleChangeSwitch}
                        />
                    </div>
                )}
                {this.props.botoesMensagens && (
                    <>
                        {this.props.headerTodos ? (
                            <div className='botoes'>
                                <div className="botao-enviar-mensagens" style={{padding: "0 16px"}}>
                                    <Dropdown
                                        overlay={this.props.menu}
                                        placement="topCenter"
                                        className="botao-icon-configs"
                                        trigger={["click"]}
                                    >
                                        <i className={this.props.pendente ? "fas fa-cog pendente" : "fas fa-cog"}/>
                                    </Dropdown>


                                </div>

                                <div className='esconder '>
                                    <div className='hidden-desktop'>
                                        <Link className='botao-voltar2'
                                              to='/utilizador'
                                        >
                                            <ArrowCircleLeft size={34}/>
                                        </Link>
                                    </div>

                                </div>

                            </div>
                        ) : null}
                        {(this.props.headerPendentes && acesso?.update) && (
                            <div className="botao-enviar-mensagens">
                                <Link
                                    to="#"
                                    className="botao-principal"
                                    onClick={this.reenviarTodos}
                                    disabled={iconLoading || this.props.nr_colaboradores <= 0}
                                    title="Reenviar mensagem de registo para todos"
                                >
                                    {iconLoading ? <Icon type="loading"/> : null}
                                    Reenviar email de registo para todos
                                </Link>
                            </div>
                        )}
                    </>
                )}


            </div>
        );
    }
}

export default HeaderColaboradores;
