import React, {useContext, useEffect, useState} from "react";
import { Dropdown, Menu, Icon } from "antd";
import axios from "axios";
import {Link} from "react-router-dom";
import {GlobalContext} from "../../GlobalState";

import perfil from "../../images/icons/perfil.png"

export default function DropdownEstabelecimentos({perfis,onPerfilChange}) {
    const globalContext = useContext(GlobalContext);

    function onChangePerfil (perfil) {
        onPerfilChange(perfil)
    };

    const montarLogoLink = (perfilId) => {
        switch (perfilId) {
            case 1:
                return "/utilizador";
            case 2:
                return "/utilizador";
            case 3:
                return "/utilizador";
            case 4:
                return "/utilizador";
            default:
                return "/utilizador";
        }
    };
    
    const menuPerfis = (
        <Menu>
            {perfis.length > 1 && 
                perfis.map(perfil => (
                        <Menu.Item key={perfil.id} onClick={() => onChangePerfil(perfil)}>
                            <Link to={montarLogoLink(perfil.id)}>{perfil.nome}</Link>
                        </Menu.Item>
                    ))
            }
            
        </Menu>
    );
    
    return (
        <div className="dropdown-escolas">
            {perfis.length > 1 ? (
                <Dropdown overlay={menuPerfis} trigger={["click"]}>
                    <span
                        className="drop-escola"
                        onClick={(e) => e.preventDefault()}
                    >
                        <div className="icon-perfil">
                            <img src={perfil} alt="perfil" />
                        </div>
                        <span className="escola">Perfil:</span>
                        <span className="estabelecimento">
                            {globalContext.perfil?.nome}
                        </span>
                        <Icon type="down" style={{ color: "#2D8D87" }} />
                    </span>
                </Dropdown>
            ) : (
                <span onClick={(e) => e.preventDefault()} className="drop-escola not-allow">
                    <div className="icon-perfil">
                        <img src={perfil} alt="perfil" />
                    </div>
                    <span className="escola">Perfil:</span>
                    <span className="estabelecimento">
                        {globalContext.perfil?.nome}
                    </span>
                    <Icon type="down" style={{ color: "#2D8D87" }} />
                </span>
            )}
        </div>
    );
}
