import React, {useEffect, useRef} from "react";
// import Alerta from "./Components/Alerta"
// import CarrocelAlertas from "./Components/CarrocelAlertas";
import Tabela from "./Components/Tabela"
import GraficoCircular from "./Components/GraficoCircular";
import GraficoBarras from "./Components/GraficoBarras";
import GraficoBarrasGradiente from "./Components/GraficoBarrasGradiente";
import AlunosPropEvasao from "./Components/AlunosPropEvasao";
import AlunosPioresNotas from "./Components/AlunosPioresNotas";
import ProfessorDiariosAtrasados from "./Components/ProfessorDiariosAtrasados";
import TurmasPioreNotas from "./Components/TurmasPioreNotas";
import CoordenadoresAtrasoValidacao from "./Components/CoordenadoresAtrasoValidacao";
import TurmasProbabilidadesEvasao from "./Components/TurmasProbabilidadeEvasao";
import {useMediaQuery} from "react-responsive";
import {DataProvider} from "./Contexts/DataProvider"
import {Link} from "react-router-dom";
import {ArrowCircleLeft} from "iconsax-react";

export default function Dashboard() {
    const alertas = [
        {
            mensagem: "Novo aviso importante!",
            professor: "João Silva",
            disciplina: "Matemática",
            escola: "Escola Secundária ABC"
        },
        {
            mensagem: "Reunião com os pais",
            professor: "Maria Oliveira",
            disciplina: "Português",
            escola: "Escola Primária XYZ"
        },
        {
            mensagem: "Prova de Ciências",
            professor: "Carlos Pereira",
            disciplina: "Ciências",
            escola: "Escola Secundária DEF"
        },
        {
            mensagem: "Novo aviso importante!",
            professor: "João Silva",
            disciplina: "Matemática",
            escola: "Escola Secundária ABC"
        },
        {
            mensagem: "Reunião com os pais",
            professor: "Maria Oliveira",
            disciplina: "Português",
            escola: "Escola Primária XYZ"
        },
        {
            mensagem: "Prova de Ciências",
            professor: "Carlos Pereira",
            disciplina: "Ciências",
            escola: "Escola Secundária DEF"
        },
        {
            mensagem: "Novo aviso importante!",
            professor: "João Silva",
            disciplina: "Matemática",
            escola: "Escola Secundária ABC"
        },
        {
            mensagem: "Reunião com os pais",
            professor: "Maria Oliveira",
            disciplina: "Português",
            escola: "Escola Primária XYZ"
        },
        {
            mensagem: "Prova de Ciências",
            professor: "Carlos Pereira",
            disciplina: "Ciências",
            escola: "Escola Secundária DEF"
        }
    ];
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 1224px)"});
    const divRef = useRef(null);

    // useEffect(() => {
    //     console.log(divRef.current)
    // }, [divRef]);
    return (
        <DataProvider>
            <div className="dashboard2">
                <div className='titulo-dash'>
                    <span className="titulo">Meu Dashboard</span>
                    <div>
                        <Link to='/utilizador'
                              className='botao-voltar2'
                        >
                            <ArrowCircleLeft size='32'/>
                        </Link>
                    </div>
                </div>


                {isTabletOrMobile ? (
                    <>
                        {/*<div className="conteiner-mobile graficos-pequenos">*/}
                        {/*    <CarrocelAlertas*/}
                        {/*        slides={isTabletOrMobile ? 1 : 6}*/}
                        {/*        Card={Alerta}*/}
                        {/*        cards={alertas}*/}
                        {/*    ></CarrocelAlertas>*/}
                        {/*</div>*/}

                        <div className="conteiner-mobile graficos-pequenos">
                            <GraficoBarrasGradiente/>
                            <GraficoCircular/>
                        </div>

                        <div className="conteiner-mobile tabelas">
                            <ProfessorDiariosAtrasados/>
                            <CoordenadoresAtrasoValidacao/>
                            <AlunosPropEvasao/>
                            <AlunosPioresNotas/>
                            <TurmasPioreNotas/>
                            <TurmasProbabilidadesEvasao/>
                        </div>
                    </>
                ) : (
                    <>
                        {/*<div>*/}
                        {/*    <CarrocelAlertas*/}
                        {/*        slides={isTabletOrMobile ? 1 : 6}*/}
                        {/*        Card={Alerta}*/}
                        {/*        cards={alertas}*/}
                        {/*    ></CarrocelAlertas>*/}
                        {/*</div>*/}

                        <div className="grid-container">
                            <div ref={divRef} className="item1"><AlunosPropEvasao/></div> 
                            <div className="item3"><GraficoBarrasGradiente/></div>
                            <div className="item2"><GraficoCircular/></div>
                            <div className="item4"><AlunosPioresNotas/></div>
                            <div className="item42"><ProfessorDiariosAtrasados/></div>
                            <div className="item5"><TurmasPioreNotas/></div>
                            <div className="item6"><CoordenadoresAtrasoValidacao/></div>
                            <div className="item12"><TurmasProbabilidadesEvasao/></div>
                        </div>

                    </>
                )}
            </div>
        </DataProvider>
    )


}