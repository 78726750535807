import React from "react";
import {Link} from "react-router-dom";

export default function BotaoTurma({turma}){


    return (
        <Link
            to={`/gerir-detalhe-turma/${turma.nomeCurso}/visao-geral`}
            className='botao-turma'
            onClick={() => {
                localStorage.setItem('codigo_curso', `${turma.cursoId}`);
                localStorage.setItem(
                    "opcao_voltar",
                    JSON.stringify({ link_voltar: "/utilizador" })
                );
            }}
        >
        <span className='escola'>
            {turma.estabelecimento}
        </span>

            <span className='turma'>
            {turma.cursoNome}
        </span>
        </Link>
    );

}