import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Row, Col, Table, Icon, notification, Carousel } from "antd";
import { GeralContext } from "../ContextProvider";
import { GlobalContext } from "../../GlobalState";
import Card from "../Participantes/Participante";
import axios from "axios";
import noimage from "../../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const VisaoGeral = ({ match }) => {
    const [planoEstudo, setPlanoEstudo] = useState("");
    const [professores, setProfessores] = useState([]);
    const [disciplinas, setDisciplinas] = useState([]);
    const [disciplinasMobile, setDisciplinasMobile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const context = useContext(GeralContext);
    const globalContext = useContext(GlobalContext);

    const listarProfessores = () => {
        
        setLoading(true);
        axios({
            method: "get",
            url: "/api/turma/lista-professores",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setProfessores(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const listarDisciplinas = () => {
        setLoadingTable(true);
        axios({
            method: "get",
            url: "/api/turma/listar-disciplinas",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                var disciplinas = [];
                var disciplinaMobile = [];

                response.data.map(disciplina => {
                    disciplinas.push({
                        key: disciplina.id,
                        id: disciplina.id,
                        disciplina: !disciplina.agrupado ? `${disciplina.codigo} - ${disciplina.nome}` : disciplina.nome,
                        nome: disciplina.nome,
                        professores: disciplina.professores,
                        acesso: disciplina.acesso,
                        //children: [
                        //    {
                        //        professores: disciplina.professores
                        //    }
                        //]
                    });
                });

                response.data.map(disciplina => {
                    disciplinaMobile.push({
                        key: disciplina.id,
                        id: disciplina.id,
                        disciplina: !disciplina.agrupado ? `${disciplina.codigo} - ${disciplina.nome}` : disciplina.nome,
                        nome: disciplina.nome,
                        professores: disciplina.professores,
                        acesso: disciplina.acesso
                    });
                });

                setLoadingTable(false);
                setDisciplinas(disciplinas);
                setDisciplinasMobile(disciplinaMobile);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoadingTable(false);
            });
    };

    const carregarPlanoEstudo = () => {
        axios({
            method: "get",
            url: "/api/turma/carregar-plano-estudo",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setPlanoEstudo(response.data);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const download = () => {
        axios({
            method: "post",
            url: "/api/turma/download-plano-estudo",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                const link = document.createElement("a");
                link.href = response.data.url;
                link.setAttribute("download", response.data.nome);

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    };

    const montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (
                <Link
                    to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                    className="botao-secundario botao-secundario-redondo"
                    onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                    style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <Icon type="arrow-left" />
                </Link>
            );
        }
    };

    const onRowClick = (nome, id) => {
        var turmaId = localStorage.getItem("codigo_curso");
        localStorage.setItem("codigo_curso", id);
        localStorage.setItem("temp_opcao_voltar", localStorage.getItem("opcao_voltar"));
        localStorage.setItem(
            "opcao_voltar",
            JSON.stringify({
                link_voltar_curso: `/gerir-detalhe-turma/${match.params.turma
                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                    .toLowerCase()}/visao-geral`,
                id_curso: turmaId
            })
        );

        globalContext.atualizarState({
            lista_modulos: []
        });

        setRedirectLink(`/gerir-detalhe-turma/${nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/horario-disciplina`);
        setRedirect(true);
    };

    //const validarPerfil = () => {

    //    return globalContext.perfil?.nome === "Suporte" || globalContext.perfil?.nome === "Gestor" || globalContext.perfil?.nome === "Administrativo" || globalContext.perfil?.nome === "Diretor"
    //}

    useEffect(() => {
        carregarPlanoEstudo();
        listarProfessores();
        listarDisciplinas();
    }, []);

    const columns = [
        {
            title: "Disciplina",
            dataIndex: "disciplina",
            className: "td-curso",
            width: "60%"
        },
        {
            title: "Coordenadores / Professores",
            dataIndex: "professores",
            className: "td-300",
            align: "center"
        }
    ];

    if (redirect) return <Redirect to={redirectLink} />;

    const acessoPermitido =
        globalContext.perfil?.nome === "Suporte" ||
        globalContext.perfil?.nome === "Gestor" ||
        globalContext.perfil?.nome === "Administrativo" ||
        globalContext.perfil?.nome === "Diretor";

    return (
        <div className="container container-body">
            {loading ? (
                <div className="loading-data">
                    <div className="loading" />
                </div>
            ) : (
                <div className="turma-visao-geral">
                    <div className="bloco-curso-diario-opcoes" style={{ justifyContent: "flex-end" }}>
                        {/*<div className="bloco-botoes-curso-diario bloco-botoes-curso-diario-desktop">{montarBotaoVoltar()}</div>*/}
                        <div className="bloco-botoes-esquerdo-curso-diario" style={{ gap: 10 }}>
                            {planoEstudo && (
                                <Link className="botao-icon-pdf botao-maior" to="#" title="Descarregar PDF" onClick={download}>
                                    <Icon type="file-pdf" />
                                </Link>
                            )}
                            {montarBotaoVoltar()}
                        </div>
                    </div>
                    <div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                                <div className="bloco-v2">
                                    <div className="item">
                                        <div className="lista-professores">
                                            <Carousel autoplay>
                                                {professores.map(professor => (
                                                    <Card
                                                        {...professor}
                                                        key={professor.id}
                                                        dt_fechado={context.dt_fechado}
                                                        dt_inicio={context.dt_inicio}
                                                        dt_fim={context.dt_fim}
                                                        fechado={context.fechado}
                                                        perfil={professor.coordenador ? "Coordenador" : "Professor"}
                                                    />
                                                ))}
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {window.innerWidth > 1025 ? (
                                <Col xs={24} sm={24} md={12} lg={16} xl={18}>
                                    <div className="bloco-v2 bloco-cursos">
                                        <Table
                                            id="disciplinas"
                                            loading={{
                                                spinning: loadingTable,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columns}
                                            dataSource={disciplinas}
                                            pagination={false}
                                            locale={{ emptyText: "Não existem dados!" }}
                                            rowClassName={(record, index) =>
                                                record.acesso || acessoPermitido ? "clickeble-row" : "disabled-row"
                                            }
                                            onRow={(record, index) => {
                                                return {
                                                    onClick:
                                                        record.acesso || acessoPermitido
                                                            ? () => onRowClick(record.nome, record.id)
                                                            : () => {}
                                                };
                                            }}
                                        />
                                    </div>
                                </Col>
                            ) : (
                                <Col xs={24} sm={24} md={12} lg={16} xl={18} style={{ width: "100%" }}>
                                    <div className="bloco-v2 bloco-cursos">
                                        <Table
                                            id="disciplinas"
                                            loading={{
                                                spinning: loadingTable,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columns}
                                            dataSource={disciplinasMobile}
                                            pagination={false}
                                            locale={{ emptyText: "Não existem dados!" }}
                                            rowClassName={(record, index) =>
                                                record.acesso || acessoPermitido ? "clickeble-row" : "disabled-row"
                                            }
                                            onRow={(record, index) => {
                                                return {
                                                    onClick:
                                                        record.acesso || acessoPermitido
                                                            ? () => onRowClick(record.nome, record.id)
                                                            : () => {}
                                                };
                                            }}
                                        />
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>
            )}
        </div>
    );
};
export default VisaoGeral;
