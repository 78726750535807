import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Switch, Menu, Modal, Tooltip } from "antd";
import Header from "../../../User/Header";
import noimage from "../../../images/noimage.png";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import { GlobalContext } from "../../../GlobalState";
import {ArrowCircleLeft} from "iconsax-react";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Categorias extends Component {
    static contextType = GlobalContext;
    state = {
        categorias: [],
        ativo: true,
        //DRAWER
        categoriaid: 0,
        icon: "",
        preview: null,
        nome: "",
        abreviatura: "",
        descricao: "",
        visible: false,
        iconLoading: false,
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //ATUALIZA A IMAGEM
    uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024)
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    icon: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //REMOVE A IMAGEM
    removerImagem = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            icon: "",
            preview: ""
        });
    };

    montarMenu = (categoriaid, ativo) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativar(categoriaid, ativo);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativar(categoriaid, ativo);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluir(categoriaid);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    //CARREGA TODAS AS CATEGORIA DA ENTIDADE PARA A TABELA
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-turmas-categorias",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var categorias = [];

                        response.data.map((categoria, index) => {
                            categorias.push({
                                key: index,
                                id: categoria.id,
                                categoria: (
                                    <div className="bloco-categoria">
                                        <div className="icon-categoria">
                                            <img src={categoria.icon ? categoria.icon : noimage} />
                                        </div>
                                        <div className="nome-categoria">
                                            <span className="titulo-curso">{categoria.nome}</span>
                                        </div>
                                    </div>
                                ),
                                descricao: categoria.descricao ? (
                                    <LinesEllipsis text={categoria.descricao} maxLine="1" ellipsis="..." trimRight basedOn="letters" />
                                ) : null,
                                opcoes: this.montarMenu(categoria.id, categoria.ativo)
                            });
                        });

                        this.setState({
                            categorias,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregarCategoria = categoriaid => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-turmas-categorias/carregar-categoria",
            params: {
                categoriaid: categoriaid
            }
        })
            .then(response => {
                const categoria = response.data;

                this.setState({
                    categoriaid: categoria.id,
                    icon: categoria.icon,
                    preview: categoria.icon,
                    nome: categoria.nome,
                    abreviatura: categoria.abreviatura,
                    descricao: categoria.descricao ? categoria.descricao : "",
                    ativo: categoria.ativo,
                    visible: true
                });
            })
            .catch(() => {});
    };

    //CRIA UMA NOVA CATEGORIA
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("icon", this.state.icon);
                item.append("nome", this.state.nome);
                item.append("abreviatura", this.state.abreviatura);
                item.append("descricao", this.state.descricao);

                axios({
                    method: "post",
                    url: "/api/gestao-turmas-categorias/add-categoria",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nível adicionado!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A CATEGORIA
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.categoriaid);
                item.append("icon", this.state.icon);
                item.append("nome", this.state.nome);
                item.append("abreviatura", this.state.abreviatura);
                item.append("descricao", this.state.descricao);

                axios({
                    method: "put",
                    url: "/api/gestao-turmas-categorias/alterar-categoria",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nível alterada!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //EXCLUI A CATEGORIA
    excluir = categoriaid => {
        confirm({
            title: "Pretende excluir este nivel?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-turmas-categorias/excluir-categoria",
                    params: {
                        categoriaid: categoriaid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nível excluido!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                    });
            }
        });
    };

    //ATIVA E INATIVA A CATEGORIA
    ativar = (categoriaid, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este nível?" : "Pretende ativar este nível?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turmas-categorias/ativar-categoria",
                    params: {
                        categoriaid: categoriaid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Nível inativado!" : "Nível ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o nível!" : "Não foi possível ativar o nível!"
                        );
                    });
            }
        });
    };

    //ABRE O DRAWER VAZIO PARA CRIAR UMA NOVA CATEGORIA
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            categoriaid: 0,
            icon: "",
            preview: null,
            nome: "",
            abreviatura: "",
            descricao: "",
            visible: true
        });
    };

    //FECHA O DRAWER SEM GUARDAR A CATEGORIA
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            if (document.getElementById("input-foto")) document.getElementById("input-foto").value = "";
        }
    };

    onRowClick = categoriaid => {
        this.carregarCategoria(categoriaid);
    };

    render() {
        const {
            ativo,
            preview,
            categoriaid,
            nome,
            abreviatura,
            descricao,
            visible,
            iconLoading,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const columns = [
            {
                title: "Nível",
                dataIndex: "categoria"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Níveis de Ensino" />
                <div className="container container-body">
                    <div className="bloco-categorias">
                        <div className="bloco-tabela">
                            <div className="controlos" style={{ display: "flex", justifyContent: "space-between" }}>
                                <button
                                    className="botao-principal botao-principal-redondo"
                                    title="Adicionar"
                                    style={{ marginTop: 0 }}
                                    onClick={this.showDrawer}
                                    disabled={!this.state.ativo}
                                >
                                    +
                                </button>
                                <div className="filtros" style={{ paddingTop: 8 }}>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <Switch
                                            checkedChildren="Ativos"
                                            unCheckedChildren="Inativos"
                                            defaultChecked
                                            onChange={this.handleChangeSwitch}
                                        />
    
                                        <Link className='botao-voltar2'
                                              to='/utilizador'
                                              onClick={()=>{
                                                  this.context.atualizarState({
                                                      mostrarOptConfiguracoes: true
                                                  })
                                              }}
                                        >
                                            <ArrowCircleLeft size='32'/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={this.state.categorias}
                                pagination={false}
                                rowClassName={this.state.ativo ? "clickeble-row" : ""}
                                onRow={(record, index) => {
                                    return {
                                        onClick: this.state.ativo ? () => this.onRowClick(record.id) : () => {}
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    //title=""
                    width={720}
                    onClose={this.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-img">
                        <Form>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <img src={preview ? preview : noimage} alt="Foto de Utilizador" />
                                </div>
                                {ativo && (
                                    <>
                                        <input
                                            type="file"
                                            id="input-foto"
                                            accept="image/*"
                                            onChange={this.uploadFoto}
                                            style={{ display: "none" }}
                                        />
                                        {!preview ? (
                                            <>
                                                <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                                    <span>Adicionar</span>
                                                </Link>{" "}
                                                <Tooltip
                                                    className="info-icon"
                                                    title={
                                                        <div>
                                                            <p>Tamanho máximo: 100KB</p>
                                                            <p>Tamanho recomendado: 200x200px</p>
                                                            <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                        </div>
                                                    }
                                                >
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <>
                                                <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                                    <span>Alterar</span>
                                                </Link>
                                                <Tooltip
                                                    className="info-icon"
                                                    title={
                                                        <div>
                                                            <p>Tamanho máximo: 100KB</p>
                                                            <p>Tamanho recomendado: 200x200px</p>
                                                            <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                        </div>
                                                    }
                                                >
                                                    <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                                </Tooltip>
                                                <Link to="#" className="link-principal-border" onClick={this.removerImagem}>
                                                    <span>Remover</span>
                                                </Link>
                                            </>
                                        )}
                                    </>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" disabled={!ativo} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        Abreviatura BNCC
                                        <Tooltip
                                            className="info-icon info-icon2"
                                            title="A abreviatura BNCC é utilizada para facilitar a filtragem dos campos que aparecem no sumário das aulas. Caso não seja preenchida, todos os campos BNCC serão listados."
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                }
                            >
                                {getFieldDecorator("abreviatura", {
                                    //rules: [
                                    //    {
                                    //        required: true,
                                    //        message: "Campo obrigatório"
                                    //    }
                                    //],
                                    initialValue: abreviatura
                                })(<Input name="abreviatura" disabled={!ativo} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    initialValue: descricao
                                })(
                                    <TextArea
                                        name="descricao"
                                        disabled={!ativo}
                                        onChange={this.handleChange}
                                        rows={10}
                                        style={{ height: "auto" }}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {ativo && (
                            <button
                                className="botao-principal"
                                onClick={categoriaid ? this.alterar : this.criar}
                                disabled={iconLoading}
                                type="primary"
                            >
                                {iconLoading ? <Icon type="loading" /> : null}
                                Salvar
                            </button>
                        )}
                    </div>
                </Drawer>
            </div>
        );
    }
}
const FormCategorias = Form.create({ name: "form-categorias" })(Categorias);

export default FormCategorias;
