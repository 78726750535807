import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification } from 'antd';
import UserInfo from "../../UserInfo/UserInfo";
import Tabela from "./Tabela";
import ModalLoading from "../../../../Componentes/Modals/ModalLoading";
import ModalViewer from "../../../../Componentes/Modals/ModalViewer";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Matriculas = ({ menuOptionSelected, match }) => {
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [docs, setDocs] = useState([]);

    const gerarDoc = () => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/dados-gerais-historico-matriculas",
            params: {
                code: match.params.code
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true)
                setDocs([{ uri: response.data }])
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                setVisibleLoading(false);
            });
    }

    return (
        <>
            <div className="page-container">
                <UserInfo />
                <div className="user-info-detail hidden">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{ margin: "20px 20px 10px 20px" }}>Histórico - Matrículas</h1>
                        </div>
                        <span className="page-controls">
                            <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar" onClick={gerarDoc}>
                                <Icon type="eye" />
                            </Link>
                        </span>
                    </div>
                    <div className="block-table hidden">
                        <Tabela />
                    </div>
                </div>
                <ModalLoading
                    visible={visibleLoading}
                    text="A gerar documento..."
                />
                <ModalViewer
                    visible={visibleViewer}
                    docs={docs}
                    onCancel={() => setVisibleViewer(false)}
                />
            </div>
        </>
    );
}

export default withRouter(Matriculas);