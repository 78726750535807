import React, { Component } from "react";
import {Link, Redirect} from "react-router-dom";
import Header from "../../User/Header";
import { Form } from "antd";
import Home from "./Home/Home";
import Estabelecimento from "./Estabelecimento/Estabelecimento";
import Paginas from "./Paginas/Paginas";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import {ArrowCircleLeft} from "iconsax-react";


class Main extends Component {
    static contextType = GlobalContext;
    state = {
        menu: [{ nome: "Outras Páginas", abreviatura: "Outras Páginas" }],
        estabelecimentos: [],
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //GRID
        filtroAtivo: { nome: "Outras Páginas", abreviatura: "Outras Páginas" },
        escolasCarregadas: false
    };

    componentWillMount() {
        this.listarEstabelecimentos();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    listarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/carregar-canal-estabelecimentos"
        })
            .then(response => {
                const estabelecimentos = response.data.map(x => { return { nome: x.nome, abreviatura: x.abreviatura } })
                this.setState({
                    menu: [...estabelecimentos, { nome: "Outras Páginas", abreviatura: "Outras Páginas" }],
                    filtroAtivo: estabelecimentos[0],
                    estabelecimentos: response.data
                });
            })
            .catch(error => {

            });
    };

    ativarFiltro = (filtroAtivo) => {
        this.setState({ filtroAtivo });
    }

    render() {
        const {
            menu,
            estabelecimentos,
            //REDIRECT
            redirect,
            redirectLink,
            filtroAtivo
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo={filtroAtivo?.nome} />
                <div className="administracao-empresa">

                    <div className="container container-body">
                        <div className='esconder '>
                            <div className='hidden-desktop'
                                style={{justifyContent:'end'}}
                            >
                                <div style={{display: "flex", justifyContent: "end", paddingBottom:"5px"}}>

                                    <Link className='botao-voltar2'
                                          to='/utilizador'
                                          onClick={() => {
                                              this.context.atualizarState({
                                                  mostrarOptPaginasPublicas: true
                                              })
                                          }}
                                    >
                                        <ArrowCircleLeft size={34}/>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className="bloco-grid" style={{minHeight: 600}}>
                            <div className="filtros">
                                <ul>
                                    {menu.map((item, index) => (
                                        <li
                                            key={index}
                                            className={filtroAtivo.abreviatura === item.abreviatura ? "ativo" : ""}
                                            onClick={() => this.ativarFiltro(item)}
                                            title={item.nome}
                                        >
                                            {item.abreviatura}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="bloco-cursos-estatisticas">
                                <div className='esconder '>
                                    <div className='hidden-mobile'>
                                        <div style={{display: "flex", justifyContent: "end", padding: '10px 20px'}}>

                                            <Link className='botao-voltar2'
                                                  to='/utilizador'
                                                  onClick={() => {
                                                      this.context.atualizarState({
                                                          mostrarOptPaginasPublicas: true
                                                      })
                                                  }}
                                            >
                                                <ArrowCircleLeft size={34}/>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                                {filtroAtivo.abreviatura !== "Outras Páginas" && <Estabelecimento
                                    estabelecimento={estabelecimentos.find(x => x.abreviatura === filtroAtivo.abreviatura)}/>}
                                {filtroAtivo.abreviatura === "Outras Páginas" && <Paginas/>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormMain = Form.create({name: "form-minha-conta"})(Main);

export default FormMain;
