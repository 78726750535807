import React, { useState, useEffect } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon } from 'antd';
import axios from "axios";
import moment from "moment";
import TabelaDetalhe from "../../Assiduidade/TabelaDetalhe/TabelaDetalhe";

const Tabela = ({ match }) => {
    const [matriculas, setMatriculas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const cols = [
        {
            title: "Data",
            dataIndex: "data"
        },
        {
            title: "Edição",
            dataIndex: "edicao"
        },
        {
            title: "Turma",
            dataIndex: "turma"
        },
        {
            title: "Nível",
            dataIndex: "nivel"
        },
        {
            title: "Modalidade",
            dataIndex: "modalidade"
        },
        {
            title: "Turno",
            dataIndex: "turno"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const colsMobile = [
        {
            title: "Data",
            dataIndex: "data"
        },
        {
            title: "Edição",
            dataIndex: "edicao"
        },
        {
            title: "Turma",
            dataIndex: "turma"
        },
        // {
        //     title: "Nível",
        //     dataIndex: "nivel"
        // },
        // {
        //     title: "Modalidade",
        //     dataIndex: "modalidade"
        // },
        // {
        //     title: "Turno",
        //     dataIndex: "turno"
        // },
        // {
        //     title: "",
        //     dataIndex: "opcoes",
        //     width: 10
        // }
    ];

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-matriculas",
            params: {
                code: match.params.code
            }
        })
            .then(response => {
                const matriculas = response.data.map(item => {
                    return {
                        key: item.id,
                        data: moment(item.data).format("DD/MM/YYYY"),
                        edicao: item.edicao,
                        turma: item.turma,
                        nivel: item.nivel,
                        modalidade: item.modalidade,
                        turno: item.turno,
                        opcoes: item.ficheiro ? <div className="documentos-opcoes">
                            <a href={item.ficheiro.link} download={item.ficheiro.nome} title="Descarregar" className="botao-icon-download">
                                <Icon type="download" />
                            </a>
                        </div> : null
                    }
                })

                setMatriculas(matriculas);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    useEffect(() => {
        carregar();
    }, [])

    if (redirect) return <Redirect to={redirectLink} />;

    const data = [
        {
            data: "12/01/2022",
            edicao: "2022 / 2023",
            turma: "8º A",
            nivel: "Fundamental II",
            modalidade: "Regular",
            turno: "Vespertino",
            opcoes: <div className="documentos-opcoes">
                <Link to="#" title="Descarregar" className="botao-icon-download">
                    <Icon type="download" />
                </Link>
            </div>
        },
        {
            data: "12/01/2021",
            edicao: "2021 / 2022",
            turma: "7º A",
            nivel: "Fundamental II",
            modalidade: "Regular",
            turno: "Vespertino",
            opcoes: <div className="documentos-opcoes">
                <Link to="#" title="Descarregar" className="botao-icon-download">
                    <Icon type="download" />
                </Link>
            </div>
        },
        {
            data: "12/01/2020",
            edicao: "2020 / 2021",
            turma: "6º A",
            nivel: "Fundamental II",
            modalidade: "Regular",
            turno: "Vespertino",
            opcoes: <div className="documentos-opcoes">
                <Link to="#" title="Descarregar" className="botao-icon-download">
                    <Icon type="download" />
                </Link>
            </div>
        },
        {
            data: "12/01/2019",
            edicao: "2019 / 2020",
            turma: "5º A",
            nivel: "Fundamental I",
            modalidade: "Regular",
            turno: "Vespertino",
            opcoes: <div className="documentos-opcoes">
                <Link to="#" title="Descarregar" className="botao-icon-download">
                    <Icon type="download" />
                </Link>
            </div>
        },
    ]

    return (
        <>
            <div className='desktop-block'>
                <Table
                    className="tabela-documentos tabela-aproveitamento"
                    columns={cols}
                    pagination={false}
                    dataSource={matriculas}
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
            </div>
            
            <div className='mobile'>
                <Table
                    className="tabela-documentos tabela-aproveitamento"
                    columns={colsMobile}
                    pagination={false}
                    dataSource={matriculas}
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                    expandedRowRender={record =>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <span> Nível: {record.nivel}</span>
                            <span> Turno: {record.turno}</span>
                            <span> Modalidade: {record.modalidade}</span>
                        </div>
                    }
                />
            </div>
        </>
       
    );
}

export default withRouter(Tabela);